import { IconButton,} from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { Card, Carousel, Col, Row } from 'react-bootstrap'
import { useLocation, Link } from 'react-router-dom';
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Favorite from "@material-ui/icons/Favorite";
import axios from "axios";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Myfavorite() {
    return (
        <div>
            <Row>
            <Col md={4}>
        <ProSidebar>
  <Menu iconShape="square">
    <MenuItem >My Account
    <Link to="/profile" />
    </MenuItem>
    <MenuItem >Your ShortLists
    <Link to="/saved properties" />
    </MenuItem>
  </Menu>
</ProSidebar>;
        </Col>
            </Row>   
        </div>
    )
}

export default Myfavorite
