import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Propertymanage() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [apartmentType, setapartmentType] = React.useState("");
  const [apartmentName, setapartmentName] = React.useState("");
  const [bhkType, setbhkType] = React.useState("");
  const [floor, setfloor] = React.useState("");
  const [totalFloor, settotalFloor] = React.useState("");
  const [propertyAge, setpropertyAge] = React.useState("");
  const [facing, setfacing] = React.useState("");
  const [propertySize, setpropertySize] = React.useState("");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");

  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createPropertyDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user.id,
        propertyId: propertyId,
        apartmentType: apartmentType,
        apartmentName: apartmentName,
        bhkType: bhkType,
        floor: floor,
        totalFloor: totalFloor,
        propertyAge: propertyAge,
        facing: facing,
        propertySize: propertySize,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/localitydetails");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  

  return (
    <div className="mb-5">
      <Row className="me-0 ms-0 page-view mb-3">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Property Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">Sale Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={9}>
          <Card
            className="mt-5 "
            style={{
              width: "100%",
              height: "900px",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Property Detail
            </Card>{" "}
            <Row>
              <Form validated  className="mt-4 ms-4">
                <Row>
                  <Col>
                    <Form.Label style={{ fontWeight: "600", }}>
                    Property Type<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "250px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setapartmentType(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Apartment">Apartment</option>
                        <option value="Independent House/Villa">
                          Independent House/Villa
                        </option>
                        <option value="Gated Community Villa">
                          Gated Community Villa
                        </option>
                      </select>
                    </Form.Group>
                  </Col>
                  {apartmentType == "Apartment" ||
                  apartmentType == "Gated Community Villa" ? (
                    <Col>
                      <Form.Label className="apartname-mob" style={{ fontWeight: "600" }}>
                        Apartment Name<span style={{ color: "#dc3545" }}>*</span>
                      </Form.Label>
                      <Form.Group as={Col}>
                        <Form.Control
                          type="text"
                          placeholder="Apartment Name"
                          style={{
                            width: "250px",
                            color: "GrayText",
                          }}
                          onChange={(e) => setapartmentName(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Form>
            </Row>
           
            <Row>
            
              <Col className="mt-4 ms-4">
              <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    BHK Type<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setbhkType(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="1 RK">1 RK</option>
                      <option value="1 BHK">1 BHK</option>
                      <option value="2 BHK">2 BHK</option>
                      <option value="3 BHK">3 BHK</option>
                      <option value="4 BHK">4 BHK</option>
                      <option value="4 BHK+">4 BHK+</option>
                    </select>
                  </Form.Group>
                </Form>
                
              </Col>
              <Col  className="mt-4 ms-4">
              <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>Floor<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                  <Form.Group as={Col} controlId="formGridState" style={{ width:"100%" }}>
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setfloor(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Ground">Ground</option>
                      <option value="1">1 </option>
                      <option value="2">2 </option>
                      <option value="3">3 </option>
                      <option value="4">4 </option>
                      <option value="5">5 </option>
                      <option value="6">6 </option>
                      <option value="7">7 </option>
                      <option value="8">8 </option>
                      <option value="9">9 </option>
                      <option value="10">10 </option>
                      <option value="11">11 </option>
                      <option value="12">12 </option>
                      <option value="13">13 </option>
                      <option value="14">14 </option>
                      <option value="15">15 </option>
                      <option value="16">16 </option>
                      <option value="17">17 </option>
                      <option value="18">18 </option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21 </option>
                      <option value="22">22 </option>
                      <option value="23">23 </option>
                      <option value="24">24 </option>
                      <option value="25">25 </option>
                      <option value="26">26 </option>
                      <option value="27">27</option>
                      <option value="28">28 </option>
                      <option value="29">29 </option>
                      <option value="30">30 </option>
                      <option value="31">31 </option>
                      <option value="32">32 </option>
                      <option value="33">33 </option>
                      <option value="34">34 </option>
                      <option value="35">35 </option>
                      <option value="36">36 </option>
                      <option value="37">37</option>
                      <option value="38">38 </option>
                      <option value="39">39 </option>
                      <option value="40">40 </option>
                      <option value="41">41 </option>
                      <option value="42">42 </option>
                      <option value="43">43 </option>
                      <option value="44">44 </option>
                      <option value="45">45 </option>
                      <option value="46">46 </option>
                      <option value="47">47</option>
                      <option value="48">48 </option>
                      <option value="49">49 </option>
                      <option value="50">50 </option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>            
            <Col className="mt-4 ms-4">
              {" "}
              <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>
                  Property Age<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>
                <Form.Group as={Col} controlId="formGridState">
                  <select
                    className="form-control "
                    style={{
                      width: "250px",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                    onChange={(e) => setpropertyAge(e.target.value)}
                    required
                  >
                    <option value="">Select</option>                   
                    <option value="Less than a year">Less than a year</option>
                    <option value="1 to 3 years">1 to 3 years</option>
                    <option value="3 to 5 years">3 to 5 years</option>
                    <option value="5 to 10 years">5 to 10 years</option>
                    <option value="More than 10 years">
                      More than 10 years
                    </option>
                  </select>
                </Form.Group>
              </Form>
            </Col>
            <Col  className="mt-4 ms-4">
              <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>
                  Total Floor<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>
                <Form.Group as={Col} controlId="formGridState">
                  <select
                    className="form-control "
                    style={{
                      width: "250px",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                    onChange={(e) => settotalFloor(e.target.value)}
                    required
                  >
                    <option value="">Select</option>
                    <option value="Ground">Ground</option>
                      <option value="1">1 </option>
                      <option value="2">2 </option>
                      <option value="3">3 </option>
                      <option value="4">4 </option>
                      <option value="5">5 </option>
                      <option value="6">6 </option>
                      <option value="7">7 </option>
                      <option value="8">8 </option>
                      <option value="9">9 </option>
                      <option value="10">10 </option>
                      <option value="11">11 </option>
                      <option value="12">12 </option>
                      <option value="13">13 </option>
                      <option value="14">14 </option>
                      <option value="15">15 </option>
                      <option value="16">16 </option>
                      <option value="17">17 </option>
                      <option value="18">18 </option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21 </option>
                      <option value="22">22 </option>
                      <option value="23">23 </option>
                      <option value="24">24 </option>
                      <option value="25">25 </option>
                      <option value="26">26 </option>
                      <option value="27">27</option>
                      <option value="28">28 </option>
                      <option value="29">29 </option>
                      <option value="30">30 </option>
                      <option value="31">31 </option>
                      <option value="32">32 </option>
                      <option value="33">33 </option>
                      <option value="34">34 </option>
                      <option value="35">35 </option>
                      <option value="36">36 </option>
                      <option value="37">37</option>
                      <option value="38">38 </option>
                      <option value="39">39 </option>
                      <option value="40">40 </option>
                      <option value="41">41 </option>
                      <option value="42">42 </option>
                      <option value="43">43 </option>
                      <option value="44">44 </option>
                      <option value="45">45 </option>
                      <option value="46">46 </option>
                      <option value="47">47</option>
                      <option value="48">48 </option>
                      <option value="49">49 </option>
                      <option value="50">50 </option>
                  </select>
                </Form.Group>
              </Form>
            </Col>
          </Row>
            <Row>
            <Col className="mt-4 ms-4 ">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Facing<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setfacing(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="North">North</option>
                      <option value="South">South </option>
                      <option value="East">East</option>
                      <option value="West">West</option>
                      <option value="North-East">North-East</option>
                      <option value="South-East">South-East</option>
                      <option value="North-West">North-West</option>
                      <option value="South-West">South-West</option>
                      <option value="Don't Know">Don't Know</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-4 ms-4">
              <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>
                  Property Size<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>
                <Form.Group as={Col}>
                  <Form.Control
                    type="text"
                    placeholder="sq.ft"
                    style={{
                      width: "250px",
                      color: "GrayText",
                    }}
                    onChange={(e) => setpropertySize(e.target.value)}
                    required
                  />
                </Form.Group>
              </Form>
              </Col>
            </Row>
          </Card>{" "}
        </Col>
        {/* <Col md={3}>
          {" "}
          <Card
            className="mt-5 ms-5 me-3 "
            style={{
              width: "250px",
              height: "500px",
            }}
          >
            <div
              className="ms-1 mt-1"
              style={{
                color: "#425D73",
                fontWeight: "400",
                fontSize: "17px",
              }}
            >
              Get Tenants Faster
            </div>{" "}
            <div className="ms-3 mt-1 mb-2" style={{ fontSize: "13px" }}>
              {" "}
              Subscribe to our owner plans and find Tenants quickly and with
              ease
            </div>
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <img
                  className=" mt-1"
                  src="https://assets.nobroker.in/nb-new/public/Pyp-Form/privacyLogo.svg"
                  style={{
                    width: "30px",
                    height: "40px",
                  }}
                ></img>
                <div
                  className=" mt-1"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Privacy
                </div>
              </Col>
              <Col md={4}></Col>
            </Row>
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <img
                  className=" mt-1"
                  src="https://assets.nobroker.in/nb-new/public/Pyp-Form/promotedListing.svg"
                  style={{
                    width: "30px",
                    height: "40px",
                  }}
                ></img>
                <div className=" mt-1" style={{ fontSize: "14px" }}>
                  Promoted Listing
                </div>
              </Col>
              <Col md={4}></Col>
            </Row>
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <img
                  className=" mt-1"
                  src="https://assets.nobroker.in/nb-new/public/Pyp-Form/facebookLogo.svg"
                  style={{
                    width: "30px",
                    height: "40px",
                  }}
                ></img>
                <div className=" mt-1" style={{ fontSize: "14px" }}>
                  Social Marketing
                </div>
              </Col>
              <Col md={4}></Col>
            </Row>
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <img
                  src="https://assets.nobroker.in/nb-new/public/Pyp-Form/rentConsultation.svg"
                  style={{
                    width: "30px",
                    height: "40px",
                  }}
                ></img>
                <div className=" mt-1" style={{ fontSize: "14px" }}>
                  Price Consultation
                </div>
              </Col>
              <Col md={4}></Col>
            </Row>
            <Row>
              <Col md={2}></Col>
              <Col md={6}>
                <Button
                  className="mt-3"
                  style={{ backgroundColor: "#009587", fontSize: "13px" }}
                >
                  Show Interest
                </Button>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Card>
        </Col> */}
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Propertymanage;
