import React from "react";
import { Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
// import PermIdentityIcon from '@mui/icons-material/PermIdentity';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

// function Profile() {
//   return (
//     <div>
//       <Row className="me-0">
//         <Col md={2}></Col>
//         <Col md={8}>
//           {" "}
//           <Card
//             className="mt-2 mb-3 ms-5"
//             style={{
//               width: "700px",
//               height: "500px",
//               backgroundColor: "#f3f3f3",
//             }}
//           >
//             <div
//               className="mt-5"
//               style={{
//                 fontSize: "27px",
//                 color: "GrayText",
//                 textAlign: "center",
//                 fontWeight: "unset",
//               }}
//             >
//               {" "}
//               About Me
//             </div>{" "}
//             <Form className=" ps-5 mt-5 ms-5">
//               {/* <Form.Group
//                 className="mb-3 ms-3 d-flex"
//                 controlId="formGroupEmail"
//               >
//                 <Form.Label>Full Name:</Form.Label>
//                 <Form.Control
//                   style={{ color: "GrayText" }}
//                   plaintext
//                   readOnly
//                   defaultValue="email@example.com"
//                 />
//                 {/* <Form.Control
//                   type="text"
//                   placeholder="Enter Full Name"
//                   //   onChange={(e) => setfullname(e.target.value)}
//                 /> */}
//               {/* </Form.Group>  */}

//               <Form.Group
//                 as={Row}
//                 className="mb-3"
//                 controlId="formHorizontalEmail"
//               >
//                 <Form.Label column sm={3}>
//                   Full Name
//                 </Form.Label>
//                 <Col sm={6}>
//                   <Form.Control
//                     style={{ color: "GrayText" }}
//                     plaintext
//                     readOnly
//                     defaultValue="jimmy mani"
//                   />
//                 </Col>
//               </Form.Group>

//               <Form.Group
//                 as={Row}
//                 className="mb-3"
//                 controlId="formHorizontalEmail"
//               >
//                 <Form.Label column sm={3}>
//                   Email Id
//                 </Form.Label>
//                 <Col sm={8}>
//                   <Form.Control
//                     style={{ color: "GrayText" }}
//                     plaintext
//                     readOnly
//                     defaultValue="jimmymani@gmail.com"
//                   />
//                 </Col>
//               </Form.Group>

//               <Form.Group
//                 as={Row}
//                 className="mb-3"
//                 controlId="formHorizontalEmail"
//               >
//                 <Form.Label column sm={3}>
//                   Mobile Number
//                 </Form.Label>
//                 <Col sm={8}>
//                   <Form.Control
//                     style={{ color: "GrayText" }}
//                     plaintext
//                     readOnly
//                     defaultValue="98765443267"
//                   />
//                 </Col>
//               </Form.Group>

//               <Form.Group
//                 as={Row}
//                 className="mb-3"
//                 controlId="formHorizontalEmail"
//               >
//                 <Form.Label column sm={3}>
//                   Account Created
//                 </Form.Label>
//                 <Col sm={8}>
//                   <Form.Control
//                     style={{ color: "GrayText" }}
//                     plaintext
//                     readOnly
//                     defaultValue="05/02/2021"
//                   />
//                 </Col>
//               </Form.Group>

//               <Form.Group
//                 as={Row}
//                 className="mb-3"
//                 controlId="formHorizontalEmail"
//               >
//                 <Form.Label column sm={3}>
//                   Status
//                 </Form.Label>
//                 <Col sm={8}>
//                   <Form.Control
//                     style={{ color: "GrayText" }}
//                     plaintext
//                     readOnly
//                     defaultValue="Active"
//                   />
//                 </Col>
//               </Form.Group>

//               <Form.Group
//                 as={Row}
//                 className="mb-3"
//                 controlId="formHorizontalEmail"
//               >
//                 <Form.Label column sm={3}>
//                   Posted Properties
//                 </Form.Label>
//                 <Col sm={8}>
//                   <Form.Control
//                     style={{ color: "GrayText" }}
//                     plaintext
//                     readOnly
//                     defaultValue="3"
//                   />
//                 </Col>
//               </Form.Group>
//             </Form>
//           </Card>{" "}
//         </Col>
//         <Col md={2}></Col>
//       </Row>
//     </div>
//   );
// }

// export default Profile;


function Profile() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  return (
    <div style={{ background: "#f3f3f3" }}>
      <Row>
        <Col md={4}>
        <ProSidebar>
  <Menu iconShape="square">
    <MenuItem active style={{backgroundColor:"#dc3545", fontSize:"18px",color:"white"}}>My Account
    <Link to="/profile" />
    </MenuItem>
    <MenuItem >Posted Properties
    <Link to="/myproperties" />
    </MenuItem>
    <MenuItem >Change Password
    <Link to="/change_pwd" />
    </MenuItem>
    
    {/* <SubMenu title="Your ShortLists">
      <MenuItem>Component 1</MenuItem>
      <MenuItem>Component 2</MenuItem>
    </SubMenu> */}
  </Menu>
</ProSidebar>
        </Col>
        <Col md={8} >
        <form style={{marginLeft:"15px"}}>
              <h1 className="" style={{ padding: "20px 20px 50px" }}>
                About Me{" "}
              </h1>

              <div className="col-md-12">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label
                    column
                    sm={4}
                    style={{ fontWeight: "500", fontSize: "18px" }}
                  >
                    Full Name
                  </Form.Label>
                  <Col sm={8}>
                    <div style={{ color: "GrayText", fontSize: "16px" }}>
                      {user ? user.name : ""}
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label
                    column
                    sm={4}
                    style={{ fontWeight: "500", fontSize: "18px" }}
                  >
                    Email Id
                  </Form.Label>
                  <Col sm={8}>
                    <div style={{ color: "GrayText", fontSize: "16px" }}>
                      {user ? user.email : ""}
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label
                    column
                    sm={4}
                    style={{ fontWeight: "500", fontSize: "18px" }}
                  >
                    Mobile Number
                  </Form.Label>
                  <Col sm={8}>
                    <div style={{ color: "GrayText", fontSize: "16px" }}>
                      {user ? user.phonenumber : ""}
                    </div>
                  </Col>
                </Form.Group>
              </div>
            </form>
        </Col>
      </Row>
     

      {/* <div className="container pt-2">
        <div className="row" style={{ padding: " 50px 30px" }}>
          <div className="col-md-6">
            <form>
              <h1 className="" style={{ padding: "20px 20px 50px" }}>
                About Me{" "}
              </h1>

              <div className="col-md-12">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label
                    column
                    sm={4}
                    style={{ fontWeight: "500", fontSize: "18px" }}
                  >
                    Full Name
                  </Form.Label>
                  <Col sm={8}>
                    <div style={{ color: "GrayText", fontSize: "16px" }}>
                      {user ? user.name : ""}
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label
                    column
                    sm={4}
                    style={{ fontWeight: "500", fontSize: "18px" }}
                  >
                    Email Id
                  </Form.Label>
                  <Col sm={8}>
                    <div style={{ color: "GrayText", fontSize: "16px" }}>
                      {user ? user.email : ""}
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label
                    column
                    sm={4}
                    style={{ fontWeight: "500", fontSize: "18px" }}
                  >
                    Mobile Number
                  </Form.Label>
                  <Col sm={8}>
                    <div style={{ color: "GrayText", fontSize: "16px" }}>
                      {user ? user.phonenumber : ""}
                    </div>
                  </Col>
                </Form.Group>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Profile;
