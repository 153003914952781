import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Com_sale_property() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [propertyType, setpropertyType] = React.useState("");
  const [buildingType, setbuildingType] = React.useState("");
  const [floor, setfloor] = React.useState("");
  const [totalFloor, settotalFloor] = React.useState("");
  const [propertyAge, setpropertyAge] = React.useState("");
  const [furnishing, setfurnishing] = React.useState("");
  const [builtUpArea, setbuiltUpArea] = React.useState("");
  const [carpetArea, setcarpetArea] = React.useState("");

  const [onmainRoad, setonmainRoad] = React.useState(false);
  console.log(onmainRoad);

  const [cornerProperty, setcornerProperty] = React.useState(false);
  console.log(cornerProperty);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");

  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createCommercialSellPropertyDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user.id,
        propertyId: propertyId,
        propertyType: propertyType,
        buildingType: buildingType,
        floor: floor,
        totalFloor: totalFloor,
        propertyAge: propertyAge,
        furnishing: furnishing,
        builtUpArea: builtUpArea,
        carpetArea: carpetArea,
      },
      otherFeatures: {
        onmainRoad: onmainRoad,
        cornerProperty: cornerProperty,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/commercial_sale_location");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Property Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Second">Location Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">Sale Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Additional Information</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 "
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Property Detail
            </Card>{" "}
            <Row>
              <Col className="mt-4 ms-4">
                <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>
                  Property Type<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>
                <Form.Group as={Col} controlId="formGridState">
                  <select
                    className="form-control "
                    style={{
                      width: "250px",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                    onChange={(e) => {
                      setpropertyType(e.target.value);
                      setbuildingType(false);
                    }}
                    required
                  >
                    <option value="">Select</option>
                    <option value="Showroom">Showroom</option>
                    <option value="Office Space">Office Space</option>
                    <option value="Co-Working">Co-Working</option>
                    <option value="Shop">Shop</option>
                    <option value="Godown/Warehouse">Godown/Warehouse</option>
                    <option value="Industrial Shed">Industrial Shed</option>
                    <option value="Industrial Building">
                      Industrial Building
                    </option>
                    <option value="Other Business">Other Business</option>
                    <option value="Restaurant">Restaurant</option>
                  </select>
                </Form.Group>
                </Form>
              </Col>

              <Col className="mt-4 ms-4">
              <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>
                  Building Type<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>
                <Form.Group as={Col} controlId="formGridState">
                  <select
                    className="form-control "
                    style={{
                      width: "250px",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                    onChange={(e) => setbuildingType(e.target.value)}
                    required
                  >
                    <option value="">Select</option>
                    <option value="Independent House">Independent House</option>
                    <option value="Bussiness Park">Business Park</option>
                    <option value="Mall">Mall</option>
                    <option value="Independent Shop">Independent Shop</option>

                    {propertyType == "Godown/Warehouse" ? (
                      <option value="Standalone Building">
                        Standalone Building
                      </option>
                    ) : (
                      ""
                    )}
                  </select>
                </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 ms-4">
                {" "}
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Age of Property<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setpropertyAge(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Less than a year">Less than a year</option>
                      <option value="1 to 5 years">1 to 5 years</option>
                      <option value="5 to 10 years">5 to 10 years</option>
                      <option value="More than 10 years">
                        More than 10 years
                      </option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={1}></Col>

              <Col className="mt-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>Floor<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "125px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setfloor(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Lower Basement">Lower Basement</option>
                      <option value="Upper Basement">Upper Basement </option>
                      <option value="Ground">Ground </option>
                      <option value="Full Building">Full Building </option>
                      <option value="1">1 </option>
                      <option value="2">2 </option>
                      <option value="3">3 </option>
                      <option value="4">4 </option>
                      <option value="5">5 </option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Total Floor<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "125px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => settotalFloor(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Ground">Ground</option>
                      <option value="1">1 </option>
                      <option value="2">2 </option>
                      <option value="3">3 </option>
                      <option value="4">4 </option>
                      <option value="5">5 </option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Super Built Up Area<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col}>
                    <Form.Control
                      type="text"
                      placeholder="sq.ft"
                      style={{
                        width: "250px",
                        color: "GrayText",
                      }}
                      onChange={(e) => setbuiltUpArea(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-4 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Carpet Area<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col}>
                    <Form.Control
                      type="text"
                      placeholder="Carpet Area in sq.ft"
                      style={{
                        width: "250px",
                        color: "GrayText",
                      }}
                      onChange={(e) => setcarpetArea(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Furnishing<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setfurnishing(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Fully Furnished">Fully Furnished</option>
                      <option value="Semi Furnished">Semi furnished </option>
                      <option value="Unfurnished">Unfurnished</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row className="d-flex mt-4 ms-2">
              <Form >
                <Form.Label style={{ fontWeight: "600" }}>
                  Other Features
                </Form.Label>
              </Form>{" "}
            </Row>
            <Row className="md-6 ms-2">
              <Col className="ms-3">
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="On Main Road"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={onmainRoad}
                      onChange={() => setonmainRoad(!onmainRoad)}
                    />
                  </div>
                ))}{" "}
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Corner Property"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={cornerProperty}
                      onChange={() => setcornerProperty(!cornerProperty)}
                    />
                  </div>
                ))}{" "}
              </Col>
            </Row>
          </Card>{" "}
        </Col>
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Com_sale_property;
