import React from "react";
import Adminfooter from "./Adminfooter";
import Adminheader from "./Adminheader";
import Adminsidebar from "./Adminsidebar";

function Adminlayout({ childern }) {
  return (
    <div>
      <Adminheader />
      <div className="row me-0">
        <div className="col-md-2">
          {" "}
          <Adminsidebar />
        </div>
        <div className="col-md-10 mt-3 " style={{ background: "#1b2435" }}>
          {childern}
        </div>
      </div>
      <Adminfooter />
    </div>
  );
}

export default Adminlayout;
