import React from "react";
import { Navbar, Form, Nav, Button, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton } from "@material-ui/core";

// import logo from "./logo-phyrum.jpg";

function Adminheader() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const profile = JSON.parse(sessionStorage.getItem("propertyId"));
  const apiURL = process.env.REACT_APP_API_URL;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const signout = () => {
    try {
      axios
        .get(`https://houseliv.in/api/users/signout/` + userdata._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("propertyId");
            alert("Signout Successfully");

            //    props.dispatch({type:"emptyBasket"});
            //    props.dispatch({type:"removeUser"});
            //  sessionStorage.removeItem("user");
            //  localStorage.removeItem("token");
            window.location.href = "/";
            return;
          } else {
            alert("Signout Unsuccessfully");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Signout Unsuccessfully");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Signout Unsuccessfully");
      // setdatacondition(true);
      return error;
    }
  };

  const profiledetails = () => {
    window.location.assign("/doctor/profile");
  };
  return (
    <Navbar
      dark
      expand="md"
      style={{
        backgroundColor: "#1b2435",
        borderBottom: "1px solid hsla(0,0%,100%,.5)!important",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      <Navbar.Brand href="/admin/dashboard">
        {/* <img
          src={logo}
          height="50px"
          width="200px"
          style={{ borderRadius: "8px" }}
        /> */}
        <img
          className="me-1 ms-5"
          src="/img/HouseLiv1234.png"
          alt="announcement"
          width="70px"
          height="50px"
        ></img>{" "}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          {/* <Nav.Link href="/category/grocery" style={{color:"white"}}>Grocery</Nav.Link>                  
          <Nav.Link href="/category/grains" style={{color:"white"}}>Grains</Nav.Link> */}
          {/* {userdata!==null? <span> <Nav.Link href="/profile" style={{color:"white"}}>Profile</Nav.Link> </span>:<span></span>}  */}
          {/* {userdata!==null ? userdata.role !== 0?  <span> <Nav.Link href="/admin/dashboard" style={{color:"white",fontWeight:"700"}}>Admin Page</Nav.Link></span>:<span> <Nav.Link className="pr-3" href="/profile" style={{color:"white"}}><span class="material-icons" style={{fontSize:"32px"}}>account_circle</span></Nav.Link></span>:<span></span>}            */}
        </Nav>
        {/* {userdata !== null ? (
          <span> */}
        <h6 className="me-2" style={{ color: "whitesmoke", fontSize: "18px" }}>
          Admin
        </h6>
        <IconButton
          className="me-2"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ color: "whitesmoke", fontSize: "18px" }}
        >
          <Avatar
            className="me-5"
            src={
              profile
                ? "http://tomcat.parnetslink.com/doctor/profile/" +
                  profile.profileimage
                : null
            }
            style={{ height: "30px", width: "30px" }}
            alt={profile ? profile.name : " "}
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={profiledetails}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem> */}

          <MenuItem onClick={signout}>Logout</MenuItem>
        </Menu>

        {/* <NavDropdown className="mr-5" title={<span><Avatar style={{height:"30px", width:"30px"}}/></span>} id="basic-nav-dropdown" >
          <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
          <NavDropdown.Item href="/changepassword">Change Password</NavDropdown.Item>
          <NavDropdown.Item href="/orders">Orders</NavDropdown.Item>
          <NavDropdown.Item href="/subscription">Subscripation</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={signout}>Sign Out</NavDropdown.Item>
        </NavDropdown> */}
        {/* </span>
        ) : (
          <span> */}
        {/* <Link to="/signin">         
            <Button  className="ml-2" variant="info">SIGN IN</Button>
            </Link>
            <Link to="/signup">
            <Button  className="ml-2" variant="info">SIGN UP</Button>
            </Link> */}
        {/* </span>
        )} */}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Adminheader;
