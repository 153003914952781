import { Link } from "@material-ui/core";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Com_rent_rental() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [expectedRent, setexpectedRent] = React.useState("");
  const [expectedDeposit, setexpectedDeposit] = React.useState("");
  const [negotiable, setnegotiable] = React.useState("");
  const [maintenanceExtra, setmaintenanceExtra] = React.useState("false");
  const [maintenanceAmount, setmaintenanceAmount] = React.useState("");
  const [avaliableFrom, setavaliableFrom] = React.useState(new Date());
  const [leaseDuration, setleaseDuration] = React.useState("");
  const [lockinPeriod, setlockinPeriod] = React.useState("");

  const [bank, setbank] = React.useState(false);
  console.log(bank);

  const [serviceCenter, setserviceCenter] = React.useState(false);
  console.log(serviceCenter);

  const [showRoom, setshowRoom] = React.useState(false);
  console.log(showRoom);

  const [atm, setatm] = React.useState(false);
  console.log(atm);

  const [retail, setretail] = React.useState(false);
  console.log(retail);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createCommercialRentalDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        // propertyType: propertyType,
        expectedRent: expectedRent,
        expectedDeposit: expectedDeposit,
        negotiable: negotiable,
        maintenanceExtra: maintenanceExtra,
        maintenanceAmount: maintenanceAmount,
        avaliableFrom: avaliableFrom,
        leaseDuration: leaseDuration,
        lockinPeriod: lockinPeriod,
      },
      ideal: {
        bank: bank,
        serviceCenter: serviceCenter,
        showRoom: showRoom,
        atm: atm,
        retail: retail,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/commercial_rent_amenities");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Third">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Property Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">Sale Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Additional Information</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 "
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Provide Sale Details about your property
            </Card>{" "}
            <Row>
              <Row className="mt-5 ms-2">
                <Col md={6}>
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Amount<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-1">
                      <InputGroup.Text>₹</InputGroup.Text>
                      <Form.Control
                        id="inlineFormInputGroup"
                        type="text"
                        placeholder="Enter Amount"
                        style={{
                          width: "200px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setexpectedRent(e.target.value)}
                        required
                      />
                    </InputGroup>
                  </Form>
                </Col>

                <Col className="ms-3 ">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Expected Deposit<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>

                  <Form.Control
                    id="inlineFormInputGroup"
                    type="text"
                    placeholder="Enter Amount"
                    style={{
                      width: "250px",
                      color: "GrayText",
                    }}
                    onChange={(e) => setexpectedDeposit(e.target.value)}
                    required
                  />
                  </Form>
                </Col>
              </Row>
              <Row className="ms-4">
                <Col>
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Rent Negotiable"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={negotiable}
                        onChange={() => setnegotiable(!negotiable)}
                      />
                    </div>
                  ))}
                </Col>
                <Col>
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Deposit Negotiable"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={negotiable}
                        onChange={() => setnegotiable(!negotiable)}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
            </Row>
            <Row className="mt-2 ms-2">
              <Col>
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Maintenance Extra"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={maintenanceExtra}
                      onChange={() => setmaintenanceExtra(!maintenanceExtra)}
                    />
                  </div>
                ))}
              </Col>

              {maintenanceExtra == "" ? (
                <Col>
                  <Form.Control
                    id="inlineFormInputGroup"
                    type="text"
                    placeholder="Enter Amount"
                    style={{
                      width: "250px",
                      color: "GrayText",
                    }}
                    onChange={(e) => setmaintenanceAmount(e.target.value)}
                    required
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col className="mt-3 ms-4">
                {" "}
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Available From<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col}>
                    <DatePicker
                      placeholder="yyyy/mm/dd"
                      format="YYYY/MM/DD"
                      style={{
                        width: "250px",
                        height: "38px",
                      }}
                      onChange={(date) =>
                        setavaliableFrom(date.format("YYYY/MM/DD"))
                      }
                    />
                  </Form.Group>
                </Form>
              </Col>

              <Col className=" ms-3 mt-3">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Lease Duration (Years)
                  </Form.Label>
                  <Form.Group>
                    <select
                      className="form-control "
                      style={{
                        width: "125px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setleaseDuration(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="1">1 </option>
                      <option value="2">2 </option>
                      <option value="3">3 </option>
                      <option value="4">4 </option>
                      <option value="5">5 </option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-3">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Lockin Period (Years)
                  </Form.Label>
                  <Form.Group>
                    <select
                      className="form-control "
                      style={{
                        width: "125px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setlockinPeriod(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="1">1 </option>
                      <option value="2">2 </option>
                      <option value="3">3 </option>
                      <option value="4">4 </option>
                      <option value="5">5 </option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row className="ms-2 mt-4">
              {" "}
              <Form>
                <Form.Label style={{ fontWeight: "600" }}>Ideal For</Form.Label>
              </Form>
            </Row>
            <Row className="ms-4">
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Bank"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    style={{ backgroundcolor: "#009587" }}
                    value={bank}
                    onChange={() => setbank(!bank)}
                  />
                </div>
              ))}

              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Service Center"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    style={{ backgroundcolor: "#009587" }}
                    value={serviceCenter}
                    onChange={() => setserviceCenter(!serviceCenter)}
                  />
                </div>
              ))}

              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Show Room"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    style={{ backgroundcolor: "#009587" }}
                    value={showRoom}
                    onChange={() => setshowRoom(!showRoom)}
                  />
                </div>
              ))}

              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="ATM"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    style={{ backgroundcolor: "#009587" }}
                    value={atm}
                    onChange={() => setatm(!atm)}
                  />
                </div>
              ))}

              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Retail"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    style={{ backgroundcolor: "#009587" }}
                    value={retail}
                    onChange={() => setretail(!retail)}
                  />
                </div>
              ))}
            </Row>
          </Card>{" "}
        </Col>
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Com_rent_rental;
