import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Tab,
  TabContainer,
} from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Resaleamenities() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [bathRooms, setbathRooms] = React.useState("");
  const [balcony, setbalcony] = React.useState("");
  const [waterSupply, setwaterSupply] = React.useState("");
  const [gym, setgym] = React.useState("");
  const [powerBackUp, setpowerBackUp] = React.useState("");
  const [gatedSecurity, setgatedSecurity] = React.useState("");
  const [showingHouse, setshowingHouse] = React.useState("");
  const [secondaryNumber, setsecondaryNumber] = React.useState("");

  const [lift, setlift] = React.useState(false);
  console.log(lift);

  const [internetservices, setinternetservices] = React.useState(false);
  console.log(internetservices);

  const [clubhouse, setclubhouse] = React.useState(false);
  console.log(clubhouse);

  const [intercom, setintercom] = React.useState(false);
  console.log(intercom);

  const [swimmingpool, setswimmingpool] = React.useState(false);
  console.log(swimmingpool);

  const [childrenplayarea, setchildrenplayarea] = React.useState(false);
  console.log(childrenplayarea);

  const [firesafety, setfiresafety] = React.useState(false);
  console.log(firesafety);

  const [shoppingcenter, setshoppingcenter] = React.useState(false);
  console.log(shoppingcenter);

  const [gaspipeline, setgaspipeline] = React.useState(false);
  console.log(gaspipeline);

  const [park, setpark] = React.useState(false);
  console.log(park);

  const [sewagetreatmentplant, setsewagetreatmentplant] = React.useState(false);
  console.log(sewagetreatmentplant);

  const [visitorparking, setvisitorparking] = React.useState(false);
  console.log(visitorparking);

  const [security, setsecurity] = React.useState(false);
  console.log(security);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createAmenitiesDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        bathRooms: bathRooms,
        balcony: balcony,
        waterSupply: waterSupply,
        gym: gym,
        powerBackUp: powerBackUp,
        gatedSecurity: gatedSecurity,
        showingHouse: showingHouse,
        secondaryNumber: secondaryNumber,
        availableAmenities: {
          lift: lift,
          internetservices: internetservices,
          childrenplayarea: childrenplayarea,
          clubhouse: clubhouse,
          intercom: intercom,
          swimmingpool: swimmingpool,
          shoppingcenter: shoppingcenter,
          gaspipeline: gaspipeline,
          sewagetreatmentplant: sewagetreatmentplant,
          park: park,
          visitorparking: visitorparking,
          security: security,
          firesafety: firesafety,
        },
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/resale_gallery");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Four">
              {/* <Row>
              <Col sm={3}> */}
              <Nav
                variant="pills"
                className="flex-column"
                // style={{ color: "#009587" }}
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Property Details</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">New sale Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Additional Information</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Seven">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 mb-3"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {/* <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">One</Tab.Pane>
              <Tab.Pane eventKey="second">Two</Tab.Pane>
            </Tab.Content>
          </Col> */}
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "18px",
              }}
            >
              Provide additional details about your property to get maximum
              visibilty
            </Card>{" "}
            <Row>
              <Row className="ms-4 mt-2">
                <Col md={4}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Bathroom(s)<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setbathRooms(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={4}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Balcony
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setbalcony(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={4}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Water Supply
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setwaterSupply(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Corporation">Corporation</option>
                        <option value="Borewell">Borewell</option>
                        <option value="Both">Both</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>{" "}
              </Row>
              <Row className="ms-4 mt-4">
                <Col md={4}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>Gym<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setgym(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={4}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Power Backup<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setpowerBackUp(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Full">Full</option>
                        <option value="Partial">Yes</option>
                        <option value="None">None</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={4}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Gated Security<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setgatedSecurity(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>{" "}
              </Row>
              <Row className="ms-4 mt-4">
                <Col md={4}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Who will show the house?<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setshowingHouse(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Need Help">Need Help</option>
                        <option value="I will show">I will show</option>
                        <option value="Neighbours">Neighbours</option>
                        <option value="Friends/Relatives">
                          Friends/Relatives
                        </option>
                        <option value="Security">Security</option>
                        <option value="Tenants">Tenants</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={4}>
                  {" "}
                  <Form>
                    <Form.Label style={{ fontWeight: "600" }}>
                    Secondary Number
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="text"
                        placeholder="Optional"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setsecondaryNumber(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Row className="  mt-5">
                  <hr />
                </Row>
                <Row
                  className=" mt-3"
                  style={{
                    fontWeight: "600",
                    color: "GrayText",
                    fontSize: "17px",
                  }}
                  // value={lift}
                  //         onChange={() => setlift(!lift)}
                >
                  Select the available amenities
                </Row>
                <Row className="mt-4 ms-2">
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Lift"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={lift}
                          onChange={() => setlift(!lift)}
                        />
                      </div>
                    ))}{" "}
                  </Col>

                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Internet Services"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={internetservices}
                          onChange={() =>
                            setinternetservices(!internetservices)
                          }
                        />
                      </div>
                    ))}{" "}
                  </Col>

                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Club House"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={clubhouse}
                          onChange={() => setclubhouse(!clubhouse)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Intercom"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={intercom}
                          onChange={() => setintercom(!intercom)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Swimming Pool"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={swimmingpool}
                          onChange={() => setswimmingpool(!swimmingpool)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Children Play Area"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={childrenplayarea}
                          onChange={() =>
                            setchildrenplayarea(!childrenplayarea)
                          }
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Fire Safety"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={firesafety}
                          onChange={() => setfiresafety(!firesafety)}
                        />
                      </div>
                    ))}{" "}
                  </Col>

                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Shopping Center"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={shoppingcenter}
                          onChange={() => setshoppingcenter(!shoppingcenter)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Gas Pipeline"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={gaspipeline}
                          onChange={() => setgaspipeline(!gaspipeline)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Park"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={park}
                          onChange={() => setpark(!park)}
                        />
                      </div>
                    ))}{" "}
                  </Col>

                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Sewage Treatment Plant"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={sewagetreatmentplant}
                          onChange={() =>
                            setsewagetreatmentplant(!sewagetreatmentplant)
                          }
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Visitor Parking"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={visitorparking}
                          onChange={() => setvisitorparking(!visitorparking)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Security"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={visitorparking}
                          onChange={() => setsecurity(!visitorparking)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                </Row>
              </Row>
            </Row>
          </Card>{" "}
        </Col>
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Resaleamenities;
