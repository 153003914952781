import React from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  CarouselItem,
  Col,
  Form,
  Modal,
  Nav,
  Navbar,
  Button,
  Row,
  Tab,
  Tabs,
  ModalBody,
} from "react-bootstrap";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { Link, useParams } from "react-router-dom";
import { AppBar, Container, Input, TextField } from "@material-ui/core";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import { faItalic } from "@fortawesome/free-solid-svg-icons";
import { InputAdornment } from "@mui/material";
import { useEffect } from "react";
import ReactWaterMark from "react-watermark-component";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const styles = {
  width: "980px",
  margin: "10px",
  titleTextColor: "green",
  rowTitleColor: "#009587",
};

const config = {};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
function Propertydetails() {
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const open = () => {
    if (user) {
    } else {
      alert("please login");
    }
  };

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [name, setname] = React.useState("");
  const [mobileNumber, setmobileNumber] = React.useState("");
  const [emailId, setemailId] = useState("");
  const [data, setdata] = useState({});

  // const location = useLocation();
  // const { data } = location.state;

  const { id } = useParams();
  console.log("id", id);

  const [image1, setimage1] = useState(null);
  const [image2, setimage2] = useState(null);
  const [image3, setimage3] = useState(null);
  const [image4, setimage4] = useState(null);
  const [image5, setimage5] = useState(null);
  const [image6, setimage6] = useState(null);
  const [image7, setimage7] = useState(null);
  const [image8, setimage8] = useState(null);
  const [image9, setimage9] = useState(null);
  const [image10, setimage10] = useState(null);

  const ariaLabel = { "aria-label": "description" };
  // console.log(data);
  const handleClose1 = () => setShow2(false);

  const handleShow2 = () => setShow2(true);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log(data);
  // console.log(data?.propertydetails[0]?.buildingType);
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const user = JSON.parse(sessionStorage.getItem("user"));

  const postownerdetails = async () => {
    try {
      let config = {
        url: "/users/getownerDetails",
        method: "post",
        baseURL: "https://houseliv.in/api",
        data: {
          propertyownerid: data.userId,
          fullName: user?.name,
          emailId: user?.email,
          mobilePhone: user?.phonenumber,
          buildingType: data?.propertydetails[0]?.buildingType,

          bhkType: data?.propertydetails[0]?.bhkType,
          apartmentName: data.propertydetails[0].apartmentName,
          id: `https://houseliv.com/propertydetails/` + data.propertyId,

          // userId,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res);
        // alert("Mail send");
      } else {
        console.log("error");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const [url, seturl] = useState("");
  useEffect(() => {
    getPropertyURL();
  }, []);

  const getPropertyURL = () => {
    try {
      axios
        .get("https://houseliv.in/api/propertyForOwner/SearchPropertyUrl/" + id)
        .then(function (response) {
          if (response.status === 200) {
            // console.log(response.data);
            setdata(response.data.propertyDetail[0]);
            setimage1(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[0]
            );
            setimage2(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[1]
            );
            setimage3(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[2]
            );
            setimage4(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[3]
            );
            setimage5(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[4]
            );
            setimage6(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[5]
            );
            setimage7(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[6]
            );
            setimage8(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[7]
            );
            setimage9(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[8]
            );
            setimage10(
              response.data.propertyDetail[0].propertydetails[0]
                .propertyimage[9]
            );
            return;
          } else {
            return;
          }
        });
    } catch (error) {
      console.log(error.message.data);
      alert("Can't able  to fetch");
      return;
    }
  };

  const text = `HOUSELIV`;
  // const beginAlarm = function() { console.log('start alarm'); };
  const options = {
    chunkWidth: 575,
    chunkHeight: 300,
    textAlign: "center",
    textBaseline: "bottom",
    globalAlpha: 1,
    font: "16px arial",
    rotateAngle: 0,
    fillStyle: "#f8f8f8",
    backgroundRepeat: "no-repeat",
  };

  console.log("data", data);
  return (
    <div className="container pt-3 pb-4">
      {Object.keys(data).length !== 0 ? (
        <div className="">
          <Row>
            <Row style={{ marginLeft: "0px", width: "1044px" }}>
              <Col
                className="propertyname"
                md={1}
                style={{ border: "1px solid #e2e2e2" }}
              >
                <img
                  alt=""
                  src="https://cdn-icons-png.flaticon.com/512/5158/5158962.png"
                  style={{
                    width: "50px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                />
                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  {data?.propertytypes[0]?.propertytype}
                </p>
              </Col>
              <Col md={5} style={{ border: "1px solid #e2e2e2" }}>
                <p
                  className="mt-4"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  {data?.propertydetails[0]?.bhkType}{" "}
                  {data?.propertydetails[0]?.buildingType}
                  {data?.propertydetails[0]?.apartmentType === "Apartment"
                    ? "Flat"
                    : data?.propertydetails[0]?.apartmentType}
                  {data?.propertydetails[0]?.propertytype !== "PG/Hostel" ? (
                    <> for</>
                  ) : (
                    ""
                  )}
                  {/* check 'for' */}
                  {data?.pgdetails[0]?.apartmentName}{" "}
                  {data?.propertytypes[0]?.propertytype} in {data.locality}
                  <p style={{ fontSize: "15px", color: "#fd4e86" }}>
                    {data?.streetArea}
                  </p>
                </p>
              </Col>
              <Col md={3} style={{ border: "1px solid #e2e2e2" }}>
                <Row>
                  <Col>
                    <div className="mt-3">
                      <span
                        style={{
                          color: "#fd4e86",
                          fontWeight: "600",
                          fontSize: "18px",
                          fontFamily: "inherit",
                          marginBottom: "17px",
                        }}
                      >
                        ₹ {data?.plotdetails[0]?.plotPrice}
                        {data?.rentaldetails[0]?.expectedRent}
                        {data?.resaledetails[0]?.expectedPrice}
                        {data?.propertytypes[0]?.propertytype === "PG/Hostel"
                          ? data?.propertydetails[0]?.fourRoom === "true"
                            ? data?.propertydetails[0]?.expectedRentfourroom +
                              "/Shared"
                            : data?.propertydetails[0]?.singleRoom === "true"
                            ? data?.propertydetails[0]?.expectedRentsingleroom +
                              "/Single"
                            : ""
                          : ""}
                      </span>
                      <div
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#464646",
                        }}
                      >
                        {data?.rentaldetails[0]?.negotiable === "0"
                          ? "Negotiable"
                          : data?.rentaldetails[0]?.negotiable === null
                          ? "Non-Negotiable"
                          : ""}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="mt-3">
                      {data?.propertytypes[0]?.propertytype !== "PG/Hostel" ? (
                        <span
                          className="ms-3 mt-4"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {data?.plotdetails[0]?.totalPlotSize}
                          {data?.propertydetails[0]?.propertySize}
                          {data?.propertydetails[0]?.builtUpArea} Sq-ft{" "}
                        </span>
                      ) : (
                        ""
                      )}
                      <span
                        className="ms-3"
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#464646",
                        }}
                      >
                        {data?.propertydetails[0]?.builtUpArea ||
                        data?.propertydetails[0]?.propertySize
                          ? "Builtup Area"
                          : ""}
                        {data?.plotdetails[0]?.totalPlotSize ? "Plot Size" : ""}
                      </span>
                    </div>
                  </Col>
                </Row>
                <div></div>
              </Col>
              <Col md={3} style={{ border: "1px solid #e2e2e2" }}>
                <Row>
                  <Col
                    style={{
                      color: "black",
                      fontSize: "17px",
                      fontFamily: "system-ui",
                      fontWeight: "600",
                    }}
                  >
                    <div className="mt-3">Property</div>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#464646",
                      }}
                    >
                      {data?.propertytypes[0]?.property}
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="mt-3"
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Available From
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#464646",
                      }}
                    >
                      {data?.resaledetails[0]?.avaliableFrom}
                      {data?.plotdetails[0]?.avaliableFrom}
                      {data?.rentaldetails[0]?.avaliableFrom}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col className="mt-3" lg={4} xs={12}>
              <div></div>
              <Carousel interval={null}>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[0] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image1}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[1] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image2}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[2] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image3}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[3] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image4}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[4] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image5}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[5] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image6}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[6] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image7}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[7] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image8}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[8] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image9}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                  <picture>
                    <img
                      className="d-block w-100"
                      src={
                        data.propertydetails[0].propertyimage[9] === null
                          ? "/img/noimgae.jpeg"
                          : `https://houseliv.in/property/${image10}`
                      }
                      width="500px"
                      height="500px"
                      alt=""
                    />
                  </picture>
                  {/* </ReactWaterMark> */}
                </Carousel.Item>
              </Carousel>
              <div>
                <FacebookShareButton
                  url={"https://houseliv.com/propertydetails/" + id}
                  style={{ margin: "5px" }}
                >
                  <i
                    class="fa fa-facebook-square"
                    aria-hidden="true"
                    style={{ color: "#0e8ef2", fontSize: "30px" }}
                  ></i>
                </FacebookShareButton>
                <LinkedinShareButton
                  url={"https://houseliv.com/propertydetails/" + id}
                >
                  <i
                    class="fa fa-linkedin-square"
                    aria-hidden="true"
                    style={{ color: "#0077b5", fontSize: "30px" }}
                  ></i>
                </LinkedinShareButton>
                <TwitterShareButton
                  url={"https://houseliv.com/propertydetails/" + id}
                  style={{ margin: "5px" }}
                >
                  <i
                    class="fa fa-twitter-square"
                    aria-hidden="true"
                    style={{ color: "#1da1f2", fontSize: "30px" }}
                  ></i>
                </TwitterShareButton>
                <WhatsappShareButton
                  url={"https://houseliv.com/propertydetails/" + id}
                >
                  <i
                    class="fa fa-whatsapp"
                    aria-hidden="true"
                    style={{ color: "#0aa425", fontSize: "30px" }}
                  ></i>
                </WhatsappShareButton>
              </div>
            </Col>
            <Col className="mt-3" lg={7} xs={12}>
              <div className="Img-box" style={{ fontSize: "35px" }}>
                {/*------> pg details <------ */}

                {data?.propertytypes[0]?.propertytype === "PG/Hostel" ? (
                  <>
                    <Row>
                      <div>
                        <span>PG Rules</span>
                      </div>
                    </Row>
                    <Row>
                      {data?.pgdetails[0]?.pgAndHostelRules?.noguardiansStay ===
                      "true" ? (
                        <Col
                          md={2}
                          xs={6}
                          className=" mt-2  "
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              src="/icon/reading-book.png"
                              style={{ width: "50px" }}
                              alt=""
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span style={{ fontSize: "14px" }}>
                              <b>No Gaurdians Stay</b>
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {data?.pgdetails[0]?.pgAndHostelRules?.noSmoking ===
                      "true" ? (
                        <Col
                          md={2}
                          xs={6}
                          className=""
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/cigarette.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span style={{ fontSize: "14px" }}>
                              <b>No Smoking</b>
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {data?.pgdetails[0]?.pgAndHostelRules?.noNonveg ===
                      "true" ? (
                        <Col
                          md={2}
                          xs={6}
                          className=""
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/thanksgiving.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span style={{ fontSize: "14px" }}>
                              <b>No Non-veg</b>
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {data?.pgdetails[0]?.pgAndHostelRule?.noboysEntry ===
                      "true" ? (
                        <Col
                          md={2}
                          xs={6}
                          className=""
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/delivery-boy.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span style={{ fontSize: "14px" }}>
                              <b>No Boy's Entry</b>
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {data?.pgdetails[0]?.pgAndHostelRules?.nogirlsEntry ===
                      "true" ? (
                        <Col
                          md={2}
                          xs={6}
                          className=" m-1"
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/woman.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text m-1">
                            <span style={{ fontSize: "14px" }}>
                              <b>No Girl's Entry</b>{" "}
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {data?.pgdetails[0]?.pgAndHostelRules[0]?.noDrinking ===
                      "true" ? (
                        <Col
                          md={2}
                          xs={6}
                          className=" m-1"
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/liquor.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text m-1">
                            <span style={{ fontSize: "14px" }}>
                              <b> No Drinking</b>
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {/* ------>residential New sale, rent, flatmates  common details <------ */}
                {(data?.propertytypes[0]?.propertytype === "New sale" &&
                  data?.propertytypes[0]?.property === "Residential") ||
                (data?.propertytypes[0]?.propertytype === "Rent" &&
                  data?.propertytypes[0]?.property === "Residential") ||
                (data?.propertytypes[0]?.propertytype === "Flatmates" &&
                  data?.propertytypes[0]?.property === "Residential") ? (
                  <>
                    <span style={{ fontSize: "23px" }}>Common Details</span>
                    <Row>
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/flat.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Property Type</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.propertydetails[0].apartmentType}</b>{" "}
                          </span>
                        </div>
                      </Col>
                      <Col
                        md={2}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/faucet.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Water Supply</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.amenitiesdetails[0].waterSupply}</b>{" "}
                          </span>
                        </div>
                      </Col>
                      <Col
                        md={2}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/parking.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Parking</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.rentaldetails[0].parking}</b>{" "}
                          </span>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/balcony.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Balcony</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.amenitiesdetails[0].balcony}</b>{" "}
                          </span>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/bedroom.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>BHK</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.propertydetails[0].bhkType} </b>{" "}
                          </span>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/policeman.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Gated Security</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.amenitiesdetails[0].gatedSecurity}</b>{" "}
                          </span>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/barbell.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Gym</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.amenitiesdetails[0].gym}</b>{" "}
                          </span>
                        </div>
                      </Col>
                      {data?.propertytypes[0].propertytype === "Rent" ? (
                        <>
                          <Col
                            md={2}
                            xs={6}
                            className="common"
                            style={{
                              textAlign: "center",
                              paddingBottom: "25px,",
                            }}
                          >
                            <div className="">
                              <img
                                alt=""
                                src="/icon/people.png"
                                style={{ width: "50px" }}
                              ></img>
                            </div>
                            <div className="Img-Text">
                              <span style={{ fontSize: "14px", color: "" }}>
                                <b>Preferred Tenant</b>
                              </span>
                            </div>
                            <div className="Img-Text ">
                              <span style={{ fontSize: "14px", color: "gray" }}>
                                <b>{data?.rentaldetails[0].preferredTenants}</b>{" "}
                              </span>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/skyscraper.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Property Age</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.propertydetails[0].propertyAge}</b>{" "}
                          </span>
                        </div>
                      </Col>
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/promotion.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Floor</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.propertydetails[0].floor}</b>{" "}
                          </span>
                        </div>
                      </Col>
                      {/* <Col
                            className="common"
                            md={2}
                            xs={6}
                            style={{ textAlign: "center" }}
                          >
                            <div className="">
                              <img
                                alt=""
                                src="/icon/real-estate.png"
                                style={{ width: "50px" }}
                              ></img>
                            </div>
                            <div className="Img-Text ">
                              <span style={{ fontSize: "14px", color: "" }}>
                                <b>Property Size</b>
                              </span>
                            </div>
                            <div className="Img-Text ">
                              <span style={{ fontSize: "14px", color: "gray" }}>
                                <b>{data?.propertydetails[0].floor}</b>{" "}
                              </span>
                            </div>
                          </Col> */}
                      {data?.propertytypes[0].propertytype === "Flatmates" ? (
                        <>
                          <Col
                            md={2}
                            xs={6}
                            className="common "
                            style={{
                              textAlign: "center",
                              paddingBottom: "25px,",
                            }}
                          >
                            <div className="">
                              <img
                                alt=""
                                src="/icon/people.png"
                                style={{ width: "50px" }}
                              ></img>
                            </div>
                            <div className="Img-Text">
                              <span style={{ fontSize: "14px", color: "" }}>
                                <b>Preferred Tenant</b>
                              </span>
                            </div>
                            <div className="Img-Text ">
                              <span style={{ fontSize: "14px", color: "gray" }}>
                                <b>{data?.propertydetails[0].tenantType}</b>{" "}
                              </span>
                            </div>
                          </Col>
                          <Col
                            md={2}
                            xs={6}
                            className=" common"
                            style={{
                              textAlign: "center",
                              paddingBottom: "25px,",
                            }}
                          >
                            <div className="">
                              <img
                                alt=""
                                src="/icon/bed.png"
                                style={{ width: "50px" }}
                              ></img>
                            </div>
                            <div className="Img-Text">
                              <span style={{ fontSize: "14px", color: "" }}>
                                <b>Room Type</b>
                              </span>
                            </div>
                            <div className="Img-Text ">
                              <span style={{ fontSize: "14px", color: "gray" }}>
                                <b>{data?.propertydetails[0].roomType}</b>{" "}
                              </span>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {data?.propertytypes[0].propertytype === "New sale" ? (
                        <Col
                          md={2}
                          xs={6}
                          className="common"
                          style={{
                            textAlign: "center",
                            paddingBottom: "25px,",
                          }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/generator.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text ">
                            <span style={{ fontSize: "14px", color: "" }}>
                              <b>Power Backup</b>
                            </span>
                          </div>
                          <div className="Img-Text ">
                            <span style={{ fontSize: "14px", color: "gray" }}>
                              <b>
                                {data?.amenitiesdetails[0].powerBackUp !==
                                undefined
                                  ? data?.amenitiesdetails[0].powerBackUp
                                  : "None"}{" "}
                              </b>{" "}
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      {data?.propertytypes[0].propertytype === "Rent" ? (
                        <Col
                          md={2}
                          xs={6}
                          className="common"
                          style={{
                            textAlign: "center",
                            paddingBottom: "25px,",
                          }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/chicken.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text ">
                            <span style={{ fontSize: "14px", color: "" }}>
                              <b>Non-Veg Allowed</b>
                            </span>
                          </div>
                          <div className="Img-Text ">
                            <span style={{ fontSize: "14px", color: "gray" }}>
                              <b>{data?.amenitiesdetails[0].nonVegAllowed} </b>{" "}
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {/* --------> commercial rent and sale common <----------- */}
                {(data?.propertytypes[0]?.propertytype === "Rent" &&
                  data?.propertytypes[0]?.property === "Commercial") ||
                (data?.propertytypes[0]?.propertytype === "Sale" &&
                  data?.propertytypes[0]?.property === "Commercial") ? (
                  <>
                    <span style={{ fontSize: "23px" }}>Common Details</span>
                    <Row>
                      <Col
                        md={2}
                        xs={6}
                        className="common "
                        style={{ textAlign: "center" }}
                      >
                        <div>
                          <img
                            alt=""
                            src="/icon/flat.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Property Type</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.propertydetails[0].propertyType}</b>{" "}
                          </span>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={6}
                        className="common "
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div>
                          <img
                            alt=""
                            src="/icon/parking.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Parking</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.amenitiesdetails[0].bathRooms}</b>{" "}
                          </span>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={6}
                        className=" common"
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/interior-design.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Furnishing</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.propertydetails[0].furnishing}</b>{" "}
                          </span>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={6}
                        className="common "
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/deadline.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Age of Property</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.propertydetails[0].propertyAge}</b>{" "}
                          </span>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        xs={6}
                        className=" common"
                        style={{ textAlign: "center", paddingBottom: "25px," }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/casual-exercise.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Floor</b>
                          </span>
                        </div>
                        <div className="Img-Text ">
                          <span style={{ fontSize: "14px", color: "gray" }}>
                            <b>{data?.propertydetails[0].floor} </b>{" "}
                          </span>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="">
                          <img
                            alt=""
                            src="/icon/building.png"
                            style={{ width: "50px" }}
                          ></img>
                          {""}
                          <span
                            className=" d-flex "
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Total Floor</b>
                            <p
                              className=" d-flex "
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>{data?.propertydetails[0].totalFloor}</b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {/* ------> commercial plots common details <------- */}
                {data?.propertytypes[0]?.propertytype === "Plots" &&
                data?.propertytypes[0]?.property === "Residential" ? (
                  <Row>
                    <Col
                      md={2}
                      xs={6}
                      className="common "
                      style={{ textAlign: "center", paddingBottom: "25px," }}
                    >
                      <div className="">
                        <img
                          alt=""
                          src="/icon/land.png"
                          style={{ width: "50px" }}
                        ></img>
                      </div>
                      <div className="Img-Text ">
                        <span style={{ fontSize: "14px", color: "" }}>
                          <b>Property Type</b>
                        </span>
                      </div>
                      <div className="Img-Text ">
                        <span style={{ fontSize: "14px", color: "gray" }}>
                          <b>{data?.plotdetails[0].salesType} </b>{" "}
                        </span>
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xs={6}
                      className="common "
                      style={{ textAlign: "center", paddingBottom: "25px," }}
                    >
                      <div className="">
                        <img
                          alt=""
                          src="/icon/deadline.png"
                          style={{ width: "50px" }}
                        ></img>
                      </div>
                      <div className="Img-Text mt-1">
                        <span style={{ fontSize: "14px", color: "" }}>
                          <b>Age of Property</b>
                        </span>
                      </div>
                      <div className="Img-Text ">
                        <span style={{ fontSize: "14px", color: "gray" }}>
                          <b>{data?.plotdetails[0].propertyAge} </b>{" "}
                        </span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
              <div className="Img-box" style={{ fontSize: "35px" }}>
                <Row>
                  <span style={{ fontSize: "23px" }}>Additional Details</span>

                  {/* -----> pg details<----- */}
                  {data?.propertytypes[0]?.propertytype === "PG/Hostel" ? (
                    <>
                      <Col
                        md={3}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/clock.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text mt-2">
                          <span style={{ fontSize: "14px" }}>
                            <b>Gate Closing Time</b>
                          </span>
                        </div>
                        <div style={{ fontSize: "14px", color: "gray" }}>
                          <b>{data?.pgdetails[0].gateClosingTime}</b>
                        </div>
                      </Col>
                      <Col
                        md={2}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/dish.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text mt-1">
                          <div style={{ fontSize: "14px" }}>
                            <b>Food Included</b>
                          </div>
                        </div>
                        <div style={{ fontSize: "14px", color: "gray" }}>
                          <b>{data?.pgdetails[0].foodInculded}</b>
                        </div>
                      </Col>
                      <Col
                        md={2}
                        xs={6}
                        className="common"
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/people.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text mt-1">
                          <span style={{ fontSize: "14px" }}>
                            <b>Available for</b>
                          </span>
                        </div>
                        <div style={{ fontSize: "14px", color: "gray" }}>
                          <b>{data?.pgdetails[0].genderType}</b>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  {/* -----> New sale details Additional<------ */}
                  {data?.propertytypes[0]?.propertytype === "New sale" ? (
                    <Row>
                      <Col xs={6}>
                        <div className="fontawesome-icon-list">
                          <img
                            alt=""
                            src="/icon/employment.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span
                            className=" d-flex"
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Occupancy Certificate</b>
                            <p
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>
                                {data?.propertydetails[0]
                                  .occupancyCertificate !== undefined
                                  ? data?.propertydetails[0]
                                      .occupancyCertificate
                                  : "None"}
                              </b>
                            </p>
                          </span>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="fontawesome-icon-list">
                          <img
                            alt=""
                            src="/icon/certificate.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span
                            className="ms-2 d-flex"
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Sales Deed Certificate</b>
                            <p
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>
                                {data?.propertydetails[0]
                                  .saleDeedCertificate !== undefined
                                  ? data?.propertydetails[0].saleDeedCertificate
                                  : "None"}
                              </b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="fontawesome-icon-list">
                          <img
                            alt=""
                            src="/icon/house.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span
                            className="ms-2 d-flex"
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Property Tax</b>
                            <p
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>
                                {data?.propertydetails[0].propertyTax !==
                                undefined
                                  ? data?.propertydetails[0].propertyTax
                                  : "None"}
                              </b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {/* ------> rent , flatmates details <------ */}
                  {(data?.propertytypes[0]?.propertytype === "Rent" &&
                    data?.propertytypes[0]?.property === "Residential") ||
                  (data?.propertytypes[0]?.propertytype === "Flatmates" &&
                    data?.propertytypes[0]?.property === "Residential") ? (
                    <Row>
                      <Col xs={6}>
                        <div className="">
                          <img
                            alt=""
                            src="/icon/compass.png"
                            style={{ width: "50px" }}
                          ></img>
                          {""}
                          <span
                            className=" d-flex "
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Facing</b>
                            <p
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>{data?.propertydetails[0].facing}</b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="">
                          <img
                            alt=""
                            src="/icon/building.png"
                            style={{ width: "50px" }}
                          ></img>
                          {""}
                          <span
                            className=" d-flex "
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Floor Number</b>
                            <p
                              className=" d-flex "
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>{data?.propertydetails[0].floor}</b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="">
                          <img
                            alt=""
                            src="/icon/building.png"
                            style={{ width: "50px" }}
                          ></img>
                          {""}
                          <span
                            className=" d-flex "
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Total Floor</b>
                            <p
                              className=" d-flex "
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>{data?.propertydetails[0].totalFloor}</b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="">
                          <img
                            alt=""
                            src="/icon/maintenance.png"
                            style={{ width: "50px" }}
                          ></img>
                          {""}
                          <span
                            className=" d-flex "
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Monthly Maintenance</b>
                            <p
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>
                                {" "}
                                {data?.rentaldetails[0].maintenanceAmount ? (
                                  <>
                                    ₹{data?.rentaldetails[0].maintenanceAmount}
                                  </>
                                ) : (
                                  "Maintenance Included"
                                )}
                              </b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {/* --------> commercial rent , sale additional details <--------  */}

                  {(data?.propertytypes[0]?.propertytype === "Rent" &&
                    data?.propertytypes[0]?.property === "Commercial") ||
                  (data?.propertytypes[0]?.propertytype === "Sale" &&
                    data?.propertytypes[0]?.property === "Commercial") ? (
                    <Row>
                      <Col xs={6}>
                        <div className="fontawesome-icon-list">
                          <img
                            alt=""
                            src="/icon/city-building.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span
                            className="ms-2 d-flex"
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Building Type</b>
                            <p
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>{data?.propertydetails[0].buildingType}</b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="fontawesome-icon-list">
                          <img
                            alt=""
                            src="/icon/building.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span
                            className="ms-2 d-flex"
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Lease Duration</b>
                            <p
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>
                                {data?.rentaldetails[0]?.leaseDuration ? (
                                  <>{data?.rentaldetails[0]?.leaseDuration}</>
                                ) : (
                                  "-"
                                )}
                              </b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="fontawesome-icon-list">
                          <img
                            alt=""
                            src="/icon/maintenance.png"
                            style={{ width: "50px" }}
                          ></img>
                          {""}
                          <span
                            className="ms-2 d-flex"
                            style={{ fontSize: "14px", color: "" }}
                          >
                            <b>Monthly Maintenance</b>
                            <p
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                marginLeft: "6px",
                              }}
                            >
                              <b>
                                {" "}
                                {data?.rentaldetails[0]?.maintenanceAmount ? (
                                  <>
                                    ₹{data?.rentaldetails[0]?.maintenanceAmount}
                                  </>
                                ) : (
                                  "Maintenance Included"
                                )}
                              </b>{" "}
                            </p>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {/* ------> commercial polt additional details */}

                  {data?.propertytypes[0]?.propertytype === "Plots" &&
                  data?.propertytypes[0]?.property === "Residential" ? (
                    <>
                      <Col xs={6}>
                        <div className="">
                          <img
                            alt=""
                            src="/icon/direction.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <span
                          className=" d-flex"
                          style={{ fontSize: "14px", color: "" }}
                        >
                          <b>Facing</b>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              marginLeft: "6px",
                            }}
                          >
                            <b>{data?.plotdetails[0].facing} </b>{" "}
                          </p>
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col className=" m-3 mt-4" style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      class="btn btn-grad "
                      style={{ color: "#fff" }}
                      onClick={() => {
                        handleShow();
                        open();
                        postownerdetails();
                      }} //handleShow2();
                    >
                      Contact {data?.propertytypes[0]?.propertyowner}
                    </button>
                  </Col>
                  {userdata !== null ? (
                    <>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header>
                          <Row>
                            <Col md={5}>
                              <h5>Contact</h5>
                            </Col>
                            <Col md={3}></Col>
                            <Col md={4}>
                              <h6>Available On:</h6>
                              <p>
                                {data?.propertytypes[0]?.avaliability}-{" "}
                                {data?.propertytypes[0]?.startTime} to{" "}
                                {data?.propertytypes[0]?.endTime}
                              </p>
                            </Col>
                          </Row>
                        </Modal.Header>
                        <Modal.Body>
                          <Row>
                            <Col md={1}>
                              <div>
                                <img
                                  alt=""
                                  src="/icon/man (1).png"
                                  style={{ width: "50px" }}
                                ></img>
                                {""}
                              </div>
                              <div
                                className="ms-1"
                                style={{ fontSize: "12px", color: "gray" }}
                              >
                                {data?.propertytypes[0]?.propertyowner}
                              </div>
                            </Col>
                            <Col md={5}>
                              <div
                                className="ms-4 mt-2"
                                style={{
                                  fontSize: "20px",
                                  color: "#323232",
                                  fontWeight: "600",
                                }}
                              >
                                {data?.propertytypes[0].name}
                              </div>
                              <div
                                className="ms-4"
                                style={{
                                  fontSize: "15px",
                                  color: "#323232",
                                  fontWeight: "600",
                                }}
                              >
                                <img
                                  alt=""
                                  src="/icon/india.png"
                                  style={{ width: "20px" }}
                                ></img>
                                {""}
                                <a
                                  href={`tel:${data?.propertytypes[0].mobileNumber}`}
                                  style={{
                                    textDecoration: "None",
                                    color: "#323232",
                                  }}
                                >
                                  +91-{data?.propertytypes[0]?.mobileNumber}
                                </a>
                              </div>
                              <div
                                className="ms-4"
                                style={{
                                  fontSize: "15px",
                                  color: "#323232",
                                  fontWeight: "600",
                                }}
                              >
                                <a
                                  href={
                                    "mailto:" + data?.propertytypes[0].emailId
                                  }
                                >
                                  {data?.propertytypes[0]?.emailId}
                                </a>
                              </div>
                            </Col>
                            <Col md={6}>
                              {/* <Input placeholder="Name" inputProps={ariaLabel} />
                                      <Input placeholder="Email" inputProps={ariaLabel} />
                                      <Input placeholder="Mobile Number" inputProps={ariaLabel} /> */}
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button
                            variant="primary"
                            style={{
                              backgroundColor: "#1B2433",
                              borderColor: "#ffffff",
                            }}
                            onClick={() => setSmShow(true)}
                          >
                            Contact Now
                          </Button>
                          <Modal
                            size="sm"
                            show={smShow}
                            centered
                            onHide={() => setSmShow(false)}
                            aria-labelledby="example-modal-sizes-title-sm"
                          >
                            <Modal.Body className="contact-details">
                              <a
                                href={`tel:${data?.propertytypes[0].mobileNumber}`}
                                style={{
                                  textDecoration: "None",
                                  color: "#323232",
                                }}
                              >
                                <img
                                  alt=""
                                  src="/icon/telephone.png"
                                  style={{ width: "50px" }}
                                ></img>
                                <p>Call</p>
                              </a>
                              <a
                                href={`https://wa.me/91${data?.propertytypes[0].mobileNumber}`}
                                style={{
                                  textDecoration: "None",
                                  color: "#323232",
                                }}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {/* https://wa.me/+919844445634 */}
                                <img
                                  alt=""
                                  src="/icon/whatsapp.png"
                                  style={{ width: "50px" }}
                                ></img>
                                <p>Message</p>
                              </a>
                            </Modal.Body>
                          </Modal>
                        </Modal.Footer>
                      </Modal>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </div>{" "}
            </Col>
            {data?.propertytypes[0]?.youtubeLink ? (
              <Col style={{ color: "rgb(253 203 2)", fontWeight: "500" }}>
                <a
                  href={data?.propertytypes[0]?.youtubeLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Want to see Video?
                  <img
                    alt=""
                    src="/icon/youtube.png"
                    style={{ width: "30px" }}
                  ></img>
                </a>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col className="mt-3" lg={12} xs={12}>
              <div className="Img-box" style={{ fontSize: "35px" }}>
                <Row>
                  <div>
                    <span style={{ fontSize: "23px" }}>
                      Available Facilities
                    </span>
                  </div>
                </Row>
                <Row>
                  {/* -------> pg details available facility <------ */}
                  {data?.propertytypes[0]?.propertytype === "PG/Hostel" ? (
                    <>
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/wifi.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.wifi === "true"
                              ? "Wifi"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/tv.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.commonTv === "true"
                              ? "Tv"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/refrigerator.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.refrigerator === "true"
                              ? "Refrigerator"
                              : "NA"}
                          </span>
                        </div>
                      </Col>
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/elevator.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.lift === "true"
                              ? "lift"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/air-conditioning.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.wifi === "true"
                              ? "Wifi"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/cooking.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.cookingAllowed === "true"
                              ? "Cooking Allowed"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/household.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableServices
                              ?.roomCleaning === "Yes"
                              ? "Room Cleaning"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/generator.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.powerbackup === "true"
                              ? "Power Backup"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/food.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.wifi === "true"
                              ? "Wifi"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/laundry.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableServices
                              ?.laundry === "Yes"
                              ? "Laundry"
                              : "NA"}
                          </span>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  {/* -----> New sale Details  available facility <----- */}

                  {data?.propertytypes[0]?.propertytype === "New sale" ? (
                    <>
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/elevator.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.lift === "true"
                              ? "Lift"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/clubhouse.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.clubhouse === "true"
                              ? "Club House"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/swimmer.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.swimmingpool === "true"
                              ? "Swimming Pool"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/fire-extinguisher.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.firesafety === "true"
                              ? "Fire Safety"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/wifi.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.internetservices === "true"
                              ? "Internet Services"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/intercom.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.intercom === "true"
                              ? "Intercom"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/playground.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.childrenplayarea === "true"
                              ? "Children Play Area"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/shopping-cart.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.shoppingcenter === "true"
                              ? "Shopping Center"
                              : "NA"}
                          </span>
                        </div>
                      </Col>
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/gas-pipeline.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.gaspipeline === "true"
                              ? "Gas Pipeline"
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/park.png"
                            style={{ width: "50px" }}
                          ></img>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.availableAmenities
                              ?.park === "true"
                              ? "Park"
                              : "NA"}
                          </span>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {/* residential rent , flatmates available features */}

                  {(data?.propertytypes[0]?.propertytype === "Rent" &&
                    data?.propertytypes[0]?.property === "Residential") ||
                  (data?.propertytypes[0]?.propertytype === "Flatmates" &&
                    data?.propertytypes[0]?.property === "Residential") ? (
                    <>
                      <>
                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/elevator.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.lift === "true"
                                ? "Lift"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/air-conditioning.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.airconditioner === "true"
                                ? "AC"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/clubhouse.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.clubhouse === "true"
                                ? "Club House"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/swimmer.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.swimmingpool === "true"
                                ? "Swimming Pool"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/fire-extinguisher.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.firesafety === "true"
                                ? "Fire Safety"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/wifi.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.internetservices === "true"
                                ? "Internet Services"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/intercom.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.intercom === "true"
                                ? "Intercom"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/playground.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.childrenplayarea === "true"
                                ? "Children Play Area"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/shopping-cart.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.shoppingcenter === "true"
                                ? "Shopping Center"
                                : "NA"}
                            </span>
                          </div>
                        </Col>
                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/gas-pipeline.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.gaspipeline === "true"
                                ? "Gas Pipeline"
                                : "NA"}
                            </span>
                          </div>
                        </Col>

                        <Col
                          className="common"
                          md={2}
                          xs={6}
                          style={{ textAlign: "center" }}
                        >
                          <div className="">
                            <img
                              alt=""
                              src="/icon/park.png"
                              style={{ width: "50px" }}
                            ></img>
                          </div>
                          <div className="Img-Text">
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#464646",
                                fontWeight: "600",
                              }}
                            >
                              {data?.amenitiesdetails[0]?.availableAmenities
                                ?.park === "true"
                                ? "Park"
                                : "NA"}
                            </span>
                          </div>
                        </Col>
                      </>
                    </>
                  ) : (
                    ""
                  )}

                  {/* commercial rent sale available facilities */}

                  {(data?.propertytypes[0]?.propertytype === "Rent" &&
                    data?.propertytypes[0]?.property === "Commercial") ||
                  (data?.propertytypes[0]?.propertytype === "Sale" &&
                    data?.propertytypes[0]?.property === "Commercial") ? (
                    <>
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/elevator.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Lift</b>
                          </span>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.lift}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/water-closet.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Washroom(s)</b>
                          </span>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.bathRooms !== undefined
                              ? data?.amenitiesdetails[0].bathRooms
                              : "NA"}
                          </span>
                        </div>
                      </Col>
                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/policeman.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Security</b>
                          </span>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.gatedSecurity !==
                            undefined
                              ? data?.amenitiesdetails[0].gatedSecurity
                              : "NA"}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/generator.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Power Backup</b>
                          </span>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.powerBackUp}
                          </span>
                        </div>
                      </Col>

                      <Col
                        className="common"
                        md={2}
                        xs={6}
                        style={{ textAlign: "center" }}
                      >
                        <div className="">
                          <img
                            alt=""
                            src="/icon/faucet.png"
                            style={{ width: "50px" }}
                          ></img>
                          <span style={{ fontSize: "14px", color: "" }}>
                            <b>Water Supply</b>
                          </span>
                        </div>
                        <div className="Img-Text">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#464646",
                              fontWeight: "600",
                            }}
                          >
                            {data?.amenitiesdetails[0]?.waterSupply}
                          </span>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {/* -----> plots available feature <------ */}

                  {data?.propertytypes[0]?.propertytype === "Plots" ? (
                    <>
                      {data?.plotdetails[0]?.onmainRoad === true ? (
                        <>
                          <Col
                            md={2}
                            className=""
                            style={{ textAlign: "center" }}
                          >
                            <div className="">
                              <img
                                alt=""
                                src="/icon/road.png"
                                style={{ width: "50px" }}
                              ></img>
                            </div>
                            <div className="Img-Text">
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#464646",
                                  fontWeight: "600",
                                }}
                              >
                                On Main Road
                              </span>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {data?.plotdetails[0]?.cornerProperty === true ? (
                        <>
                          <Col
                            md={2}
                            className=""
                            style={{ textAlign: "center" }}
                          >
                            <div className="">
                              <img
                                alt=""
                                src="/icon/corner.png"
                                style={{ width: "50px" }}
                              ></img>
                              <span style={{ fontSize: "14px", color: "" }}>
                                <b>Corner Property</b>
                              </span>
                            </div>
                            <div className="Img-Text">
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#464646",
                                  fontWeight: "600",
                                }}
                              >
                                Corner Property
                              </span>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2" lg={12} xs={12}>
              <div className="Img-box">
                <Row>
                  <div>
                    <span style={{ fontSize: "23px" }}>Description</span>
                  </div>
                </Row>
                <span
                  className="ms-4"
                  style={{ fontSize: "15px", color: "GrayText" }}
                >
                  {data?.propertytypes[0]?.propertytype === "Rent" ||
                  data?.propertytypes[0]?.propertytype === "New sale" ||
                  data?.propertytypes[0]?.propertytype === "Flatmates" ? (
                    <>{data?.rentaldetails[0].description}</>
                  ) : (
                    ""
                  )}
                  {data?.propertytypes[0]?.propertytype === "PG/Hostel" ? (
                    <>{data?.pgdetails[0].description}</>
                  ) : (
                    ""
                  )}
                  {(data?.propertytypes[0]?.propertytype === "Rent" &&
                    data?.propertytypes[0]?.property === "Commercial") ||
                  (data?.propertytypes[0]?.propertytype === "Sale" &&
                    data?.propertytypes[0]?.property === "Commercial") ? (
                    <>{data?.propertydetails[0].propertyDescription}</>
                  ) : (
                    ""
                  )}
                  {data?.propertytypes[0]?.propertytype === "Plots" ? (
                    <>{data?.plotdetails[0].description}</>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        " "
      )}
    </div>
  );
}
// render(<ControlledCarousel />);
// }

export default Propertydetails;
