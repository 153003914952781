import axios from "axios";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";

function Password() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const apiURL = process.env.REACT_APP_API_URL;
  const [captchaValue, setCaptchaValue] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));

  function onCaptchaVerify(value) {
    setCaptchaValue(value);
  }
  const handlesignin = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/users/changepassword",
        method: "post",
        baseURL: "https://houseliv.in/api",
        headers: { "content-type": "application/json" },
        data: {
          uId: user._id,
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Password changed Successfully");
        console.log(res.data);
        window.location.assign("/");
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  return (
    <div style={{ background: "#f3f3f3" }}>
      <Row>
      <Col md={3}>
      <ProSidebar>
  <Menu iconShape="square">
    <MenuItem >My Account
    <Link to="/profile" />
    </MenuItem>
    <MenuItem >Posted Properties
    <Link to="/myproperties" />
    </MenuItem>
    <MenuItem active style={{backgroundColor:"#dc3545",fontSize:"18px",color:"white"}}>Change Password
    <Link to="/change_pwd" />
    </MenuItem>
  </Menu>
  
</ProSidebar>
        </Col>
        <Col md={8}>
        <div className="container pt-2">
        <div className="row" style={{ padding: " 50px 30px" }}>
          {/* <div className="col-md-6 ">
            <img
              src="https://image.freepik.com/free-photo/close-up-two-business-people-shaking-hands-while-sitting-working-place_1150-1817.jpg"
              style={{ width: "100%", height: "500px" }}
            ></img>
            <p className="t-c pt-3">
              {" "}
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's{" "}
            </p>
          </div> */}
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <form>
              <div className="row ">
                <div className="col-md-12 t-c ">
                  <h1 className="" style={{ padding: "20px 20px 50px" }}>
                    Change Password{""}
                  </h1>
                </div>
                <div className="col-md-12">
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">
                      Old Password<span style={{ color: "#c50822" }}>*</span>
                    </label>
                    <input
                      className="form-control "
                      type="password"
                      style={{
                        background: "#fff",
                        color: "GrayText",
                        padding: "10px;",
                      }}
                      onChange={(e) => setOldPassword(e.target.value)}
                      //   placeholder="Enter Password"
                      //   onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">
                      New Password<span style={{ color: "#c50822" }}>*</span>
                    </label>
                    <input
                      className="form-control "
                      type="password"
                      style={{
                        background: "#fff",
                        color: "GrayText",
                        padding: "10px;",
                      }}
                      onChange={(e) => setNewPassword(e.target.value)}
                      //   placeholder="Enter Password"
                      //   onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">
                      Confirm Password
                      <span style={{ color: "#c50822" }}>*</span>
                    </label>
                    <input
                      className="form-control "
                      type="password"
                      style={{
                        background: "#fff",
                        color: "GrayText",
                        padding: "10px;",
                      }}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      //   placeholder="Enter Password"
                      //   onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="form-gropu pt-2">
                  <button
                    className="btn btn-primary form-control"
                    style={{
                      padding: "5px",
                      background: "#1b2435",
                      width: "100px",
                    }}
                    onClick={handlesignin}
                  >
                    {" "}
                    Update
                  </button>
                </div>

                {/* <p className="t-c pt-2" style={{textDecoration:" underline "}} > Don't have an account? <span style={{color:"blue"}}> Sign Up</span> </p> */}
                {/* <p className="t-c"> Or Continue with</p> */}
              </div>
            </form>
          </div>
        </div>
      </div>
        </Col>
        </Row>

     
    </div>
  );
}

export default Password;
