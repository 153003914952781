import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Button, Container } from "@material-ui/core";
import {
  Card,
  Carousel,
  Col,
  Dropdown,
  Form,
  Row,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import Select from "react-select";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

function Home() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [selectCity, setselectCity] = React.useState("");
  const [selectlocality, setselectlocality] = React.useState([]);
  const [apartmentType, setapartmentType] = React.useState("");
  const [propertyStatus, setpropertyStatus] = React.useState("");
  const [availability, setavailability] = React.useState("");
  const [propertyType, setpropertyType] = React.useState("");
  const [getcitydata, setgetcitydata] = React.useState([]);
  const [property, setproperty] = React.useState("");

  const [fullhouse, setfullhouse] = React.useState(false);
  console.log(fullhouse);

  const [pghostel, setpghostel] = React.useState(false);
  // console.log(pghostel);

  const [flatmates, setflatmates] = React.useState(false);
  // console.log(flatmates);

  const [rent, setrent] = React.useState(false);
  // console.log(rent);

  const [buy, setbuy] = React.useState(false);
  // console.log(buy);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const open = () => {
    if (user) {
      window.location.assign("/propertymanage");
    } else {
      alert("please login");
    }
  };

  useEffect(() => {
    getcity();
  }, []);

  const getcity = () => {
    try {
      axios
        .get("https://houseliv.in/api/propertyForOwner/getAddCity")
        .then(function (response) {
          if (response.status === 200) {
            // console.log(response.data);
            setgetcitydata(response.data.cityList1);
            return;
          } else {
            alert("Can't able to fetch ");
            return;
          }
        });
    } catch (error) {
      console.log(error.message.data);
      alert("Can't able  to fetch");
      return;
    }
  };

  return (
    <div
      className=" row me-0"
      style={{
        backgroundImage: "url(" + "/img/houseliv.jpg" + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="col-md-3"></div>
      <div className="col-md-6">
        <h1
          className="mt-5 "
          style={{
            fontSize: "35px",
            color: "#1b2435",
            textAlign: "center",
            height: "30%",
          }}
        >
          FIND YOUR DREAM HOME
        </h1>
        <h5 className="mt-4" style={{ textAlign: "center", color: "#1b2435" }}>
          The best properties are published here
        </h5>
      </div>
      <div className="col-md-3"></div>
      <div className="col-md-4"></div>
      <div className="col-md-4"></div>
      <div className="col-md-4"></div>
      <div className="col-md-2"></div>
      <div className="col-md-8 mt-3">
        <AppBar
          position="static"
          color="default"
          className="rentBar searchbar"
          // style={{ background: "red" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            aria-label="full width tabs example"
            centered
            // flexContainer
          >
            <Tab label="Buy" {...a11yProps(0)} />

            <Tab label="Rent" {...a11yProps(1)} />
            <Tab label="Commercial" {...a11yProps(2)} />
            <Tab label="Plots" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            style={{
              borderStyle: "ridge",
            }}
            value={value}
            index={0}
            dir={theme.direction}
          >
            <Container fluid="md">
              <Row>
                <Col md={3}>
                  <Form className="mt-3">
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-select"
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setselectCity(e.target.value)}
                      >
                        <option value="">Select City</option>
                        {getcitydata.map((data) => (
                          <option value={data.city}>{data.city}</option>
                        ))}
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3"
                    controlId="SearchBar"
                    style={{ height: "100px" }}
                  >
                    <GooglePlacesAutocomplete
                      // placeholder="Select Area"
                      apiKey="AIzaSyBSSh2iC9t7FJExi9-EwGXfcoq0LmTRp7M"
                      selectProps={{
                        isMulti: true,
                        placeholder: "Select Area",
                        isClearable: true,
                        value: selectlocality,
                        onChange: (val) => {
                          setselectlocality(val);
                        },
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <button className="btn btn-danger mt-3">
                    <Link
                      to={{
                        pathname: "/search",
                        state: {
                          data: {
                            city: selectCity,
                            locality: selectlocality,
                            apartmentType: apartmentType,
                            propertyType: "New sale",
                          },
                        },
                      }}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Search
                    </Link>{" "}
                  </button>{" "}
                </Col>
              </Row>
              {/* <Row>
                <Col md={3}>
                  <Form className="mt-3 mb-3">
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-select"
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setapartmentType(e.target.value)}
                      >
                        <option value="">BHK Type</option>
                        <option value="1 RK">1 RK</option>
                        <option value="1 BHK">1 BHK</option>
                        <option value="2 BHK">2 BHK</option>
                        <option value="3 BHK">3 BHK</option>
                        <option value="4 BHK">4 BHK</option>
                        <option value="4 BHK+">4 BHK+</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
              </Row> */}
            </Container>
          </TabPanel>
          <TabPanel
            style={{
              borderStyle: "ridge",
            }}
            value={value}
            index={1}
            dir={theme.direction}
          >
            <Container fluid="md">
              <Row>
                <Col md={3}>
                  <Form className="mt-3">
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-select "
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setselectCity(e.target.value)}
                      >
                        <option value="">Select City</option>
                        {getcitydata.map((data) => (
                          <option value={data.city}>{data.city}</option>
                        ))}
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3 mb-3" controlId="SearchBar">
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyBSSh2iC9t7FJExi9-EwGXfcoq0LmTRp7M"
                      selectProps={{
                        isMulti: true,
                        placeholder: "Select Area",
                        isClearable: true,
                        value: selectlocality,
                        onChange: (val) => {
                          setselectlocality(val);
                        },
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  {/* {fullhouse || pghostel || flatmates ? ( */}
                  <button className="btn btn-danger mt-3">
                    <Link
                      to={{
                        pathname: "/search",
                        state: {
                          data: {
                            city: selectCity,
                            locality: selectlocality,
                            fullhouse: fullhouse,
                            pghostel: pghostel,
                            flatmates: flatmates,
                            apartmentType: apartmentType,
                            propertyType: "Rent",
                          },
                        },
                      }}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Search
                    </Link>{" "}
                  </button>
                  {/* ) : (
                    ""
                  )} */}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {["radio"].map((type) => (
                    <div
                      key={`inline-${type}`}
                      className="mb-3"
                      style={{ color: "#252f42", fontWeight: "400" }}
                    >
                      <Form.Check
                        value={fullhouse}
                        inline
                        label="Full House"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        onChange={() => setfullhouse(!fullhouse)}
                      />
                      <Form.Check
                        inline
                        label="PG/Hostel"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                        value={pghostel}
                        onChange={() => {
                          setpghostel(!pghostel);
                          setfullhouse(false);
                          setflatmates(false);
                        }}
                      />
                      <Form.Check
                        inline
                        label="Flatmates"
                        name="group1"
                        type={type}
                        id={`inline-${type}-3`}
                        value={flatmates}
                        onChange={() => {
                          setflatmates(!flatmates);
                          setfullhouse(false);
                          setpghostel(false);
                        }}
                      />
                    </div>
                  ))}
                </Col>
                <Col md={3}>
                  {fullhouse ? (
                    <Form>
                      <Form.Group as={Col} controlId="formGridState">
                        <select
                          className="form-select "
                          style={{
                            width: "150px",
                            color: "GrayText",
                            cursor: "pointer",
                          }}
                          onChange={(e) => setapartmentType(e.target.value)}
                        >
                          <option value="">BHK Type</option>
                          <option value="1 RK">1 RK</option>
                          <option value="1 BHK">1 BHK</option>
                          <option value="2 BHK">2 BHK</option>
                          <option value="3 BHK">3 BHK</option>
                          <option value="4 BHK">4 BHK</option>
                          <option value="4 BHK+">4 BHK+</option>
                        </select>
                      </Form.Group>
                    </Form>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Container>
          </TabPanel>
          <TabPanel
            style={{
              borderStyle: "ridge",
            }}
            value={value}
            index={2}
            dir={theme.direction}
          >
            <Container fluid="md">
              <Row>
                <Col md={3}>
                  <Form className="mt-3">
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-select "
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setselectCity(e.target.value)}
                      >
                        <option value="">Select City</option>
                        {getcitydata.map((data) => (
                          <option value={data.city}>{data.city}</option>
                        ))}
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3 mb-3" controlId="SearchBar">
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyBSSh2iC9t7FJExi9-EwGXfcoq0LmTRp7M"
                      selectProps={{
                        isMulti: true,
                        placeholder: "Select Area",
                        isClearable: true,
                        value: selectlocality,
                        onChange: (val) => {
                          setselectlocality(val);
                        },
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <button className="btn btn-danger mt-3">
                    <Link
                      to={{
                        pathname: "/search",
                        state: {
                          data: {
                            city: selectCity,
                            locality: selectlocality,
                            rent: rent,
                            buy: buy,
                            property: property,
                            propertyType: "Commercial",
                          },
                        },
                      }}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Search
                    </Link>{" "}
                  </button>{" "}
                </Col>
              </Row>
              <Row>
                <Col md={3} style={{ color: "#252f42", fontWeight: "400" }}>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Rent"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        value={rent}
                        onChange={() => setrent(!rent)}
                      />
                      <Form.Check
                        inline
                        label="Buy"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                        value={buy}
                        onChange={() => setbuy(!buy)}
                      />
                    </div>
                  ))}
                </Col>

                <Col md={3}>
                  <Form>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-select "
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setproperty(e.target.value)}
                      >
                        <option value="">Property Type</option>
                        <option value="Showroom">Showroom</option>
                        <option value="Office Space">Office Space</option>
                        <option value="Co-Working">Co-Working</option>
                        <option value="Shop">Shop</option>
                        <option value="Godown/Warehouse">
                          Godown/Warehouse
                        </option>
                        <option value="Industrial Shed">Industrial Shed</option>
                        <option value="Industrial Building">
                          Industrial Building
                        </option>
                        <option value="Other Business">Other Business</option>
                        <option value="Restaurant">Restaurant</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Container>
          </TabPanel>
          <TabPanel
            style={{
              borderStyle: "ridge",
            }}
            value={value}
            index={3}
            dir={theme.direction}
          >
            <Container fluid="md">
              <Row className="mb-5">
                <Col md={3}>
                  <Form className="mt-3">
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-select "
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setselectCity(e.target.value)}
                      >
                        <option value="">Select City</option>
                        {getcitydata.map((data) => (
                          <option value={data.city}>{data.city}</option>
                        ))}
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3 mt-3" controlId="SearchBar">
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyBSSh2iC9t7FJExi9-EwGXfcoq0LmTRp7M"
                      selectProps={{
                        isMulti: true,
                        placeholder: "Select Area",
                        isClearable: true,
                        value: selectlocality,
                        onChange: (val) => {
                          setselectlocality(val);
                        },
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <button className="btn btn-danger mt-3">
                    <Link
                      to={{
                        pathname: "/search",
                        state: {
                          data: {
                            city: selectCity,
                            locality: selectlocality,
                            propertyType: "Plots",
                          },
                        },
                      }}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Search
                    </Link>{" "}
                  </button>{" "}
                </Col>
              </Row>
            </Container>
          </TabPanel>
        </SwipeableViews>
      </div>

      <div className="text-center mt-5 mb-3">
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <Card.Title className="mb-2 " style={{ color: "black" }}>
              Are you a builder owner or agent , Need not spend!
            </Card.Title>
            <button className="btn btn-success mt-2" onClick={open}>
              Post Free property ads{" "}
            </button>{" "}
          </blockquote>
        </Card.Body>
      </div>
    </div>
  );
}

export default Home;
