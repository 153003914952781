import { Link } from "@material-ui/core";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Com_sale_amenities() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [powerBackUp, setpowerBackUp] = React.useState("");
  const [lift, setlift] = React.useState("");
  const [parking, setparking] = React.useState("");
  const [bathRooms, setbathRooms] = React.useState("");
  const [reserved, setreserved] = React.useState("");
  const [security, setsecurity] = React.useState("");
  const [wifi, setwifi] = React.useState("");
  const [waterSupply, setwaterSupply] = React.useState("");

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createCommericalAmenitiesDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        powerBackUp: powerBackUp,
        lift: lift,
        parking: parking,
        bathRooms: bathRooms,
        reserved: reserved,
        security: security,
        wifi: wifi,
        waterSupply: waterSupply,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/commercial_sale_gallery");
    } else {
      console.log("error");
    }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Four">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Property Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Second">Location Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">Sale Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Additional Information</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 "
            style={{
              width: "100%",
              height: "700px",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Amenities
            </Card>{" "}
            <Row>
              <Col className="mt-3 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Power Backup<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setpowerBackUp(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Full ">Full </option>
                      <option value="DG Backup">DG Backup</option>
                      <option value="Need to Arrange">Need to Arrange</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-3 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>Lift<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setlift(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Personal">Personal</option>
                      <option value="Common">Common</option>
                      <option value="None">None</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Parking<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setparking(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="public And Reserved">
                        public And Reserved
                      </option>
                      <option value="Reserved">Reserved</option>
                      <option value="Public">Public</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              {parking == "public And Reserved" || parking == "Reserved" ? (
                <Col className="mt-5 ms-4">
                  <Form validated>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="text"
                        placeholder="No of Available Slots"
                        style={{
                          width: "250px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setreserved(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}              
            </Row>
            <Row>
            <Col className="mt-3 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Washroom(s)<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setbathRooms(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Shared">Shared</option>
                      <option value="No washroom">No washroom</option>
                      <option value="Private">Private</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-3 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>Wifi</Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setwifi(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Water Storage Facility
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setwaterSupply(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-3 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Security
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setsecurity(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card>{" "}
        </Col>{" "}
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Com_sale_amenities;
