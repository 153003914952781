import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Admindashboard() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [onlinedata, setonlinedata] = useState([]);
  const [offlinedata, setofflinedata] = useState([]);
  const [blockuser, setblockuser] = useState([]);
  const [alluser, setalluser] =  useState([]);

  useEffect(() => {online();offline(); block(); count();}, []);

  const online = async () => {
    try {
      axios
        .get(`https://houseliv.in/api/userModel/getonlineuser` )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setonlinedata(response.data.users);
            // window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const offline = async () => {
    try {
      axios
        .get(`https://houseliv.in/api/userModel/getofflineuser` )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setofflinedata(response.data.users);
            // window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const block = async () => {
    try {
      axios
        .get(`https://houseliv.in/api/userModel/getblockuser` )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setblockuser(response.data.users);
            // window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const count = async () => {
    try {
      axios
        .get(`https://houseliv.in/api/users/alluser` )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setalluser(response.data.user);
            // window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const allusercount = 
    alluser.length
  
  console.log(alluser.length);
  return (
    <div className="ml-4">
      {/* {user ? (
        <h1 className="pt-5">Hi {user.name} !!!</h1>
      ) : (
        <h1 className="pt-3">
          <Link to="/admin/signin">please login</Link>
        </h1>
      )} */}
      <Row className="pt-5 mt-4">
        <Col>
          {/* <Card
            style={{
              width: "15rem",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: "18px" }}>Active user</Card.Title>
              <Card.Title style={{ fontSize: "16px" }}>10</Card.Title>
            </Card.Body>
          </Card> */}
           <Card
            style={{
              width: "15rem",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: "18px" }}>Online User</Card.Title>
              <Card.Title style={{ fontSize: "16px" }}>{onlinedata}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {/* <Card
            style={{
              width: "15rem",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: "18px" }}>
                In-active User
              </Card.Title>
              <Card.Title style={{ fontSize: "16px" }}>5</Card.Title>
            </Card.Body>
          </Card> */}
           <Card
            style={{
              width: "15rem",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: "18px" }}>Offline User</Card.Title>
              <Card.Title style={{ fontSize: "16px" }}>{offlinedata}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              width: "15rem",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: "18px" }}>Bloked User</Card.Title>
              <Card.Title style={{ fontSize: "16px" }}>{blockuser}</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            style={{
              width: "15rem",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: "18px" }}>Total User</Card.Title>
              <Card.Title style={{ fontSize: "16px" }}>{allusercount}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Admindashboard;
