import { ButtonBase, TableRow } from "@material-ui/core";
import { Button } from "bootstrap";
import React, { useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";

function Contact() {
  return (
    <div
      className="container"
      // style={{
      //   backgroundImage: "url(" + "img/HouseLiv1234.png" + ")",
      //   backgroundPosition: "center",
      //   backgroundSize: "450px",
      //   backgroundRepeat: "no-repeat",
      // }}
    >
      <div class="row mt-5 pt-5">
        <div class="col-lg-10 offset-lg-1">
          <div class="contact_info_container d-flex flex-lg-row flex-column justify-content-between align-items-between">
            {/* <!-- Contact Item --> */}
            <div class="contact_info_item d-flex flex-row align-items-center justify-content-start">
              <div class="contact_info_image">
                <img
                  src="https://img.icons8.com/office/24/000000/iphone.png"
                  alt=""
                />
              </div>
              <div class="contact_info_content">
                <div class="contact_info_title">Phone</div>
                <div class="contact_info_text">
                  <a
                    href={"tel:${+91-9066555546}"}
                    style={{ color: "rgba(0,0,0,0.5)", textDecoration: "none" }}
                  >
                    +91-9066555546
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Contact Item --> */}
            <div class="contact_info_item d-flex flex-row align-items-center justify-content-start">
              <div class="contact_info_image">
                <img
                  src="https://img.icons8.com/ultraviolet/24/000000/filled-message.png"
                  alt=""
                />
              </div>
              <div class="contact_info_content">
                <div class="contact_info_title">Email</div>
                <div class="contact_info_text">
                  {" "}
                  <a
                    style={{ textDecoration: "none", color: "rgba(0,0,0,0.5)" }}
                    href={"mailto:${info@houseliv.com}"}
                  >
                    info@houseliv.com
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Contact Item --> */}
            <div class="contact_info_item d-flex flex-row align-items-center justify-content-start">
              <div class="contact_info_image">
                <img
                  src="https://img.icons8.com/ultraviolet/24/000000/map-marker.png"
                  alt=""
                />
              </div>
              <div class="contact_info_content">
                <div class="contact_info_title">Address</div>
                <div class="contact_info_text">
                  4/10, Vibha Complex, Nagarbhavi Main Road, Vijaya Nagar,
                  Bangaluru 560040
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5 mb-5">
        <div class="col-lg-10 offset-lg-1">
          <div class="contact_info_container d-flex flex-lg-row flex-column justify-content-between align-items-between">
            {/* <!-- Contact Item --> */}
            <div class="contact_info_item d-flex flex-row align-items-center justify-content-start">
              <div class="contact_info_image">
                <img
                  src="https://img.icons8.com/office/344/clock--v1.png"
                  alt=""
                />
              </div>
              <div class="contact_info_content">
                <div class="contact_info_title">Timings</div>
                <div class="contact_info_text">Monday to Saturday</div>
                <div class="contact_info_text">9AM TO 6PM</div>
              </div>
            </div>

            {/* <!-- Contact Item --> */}
            <div class="contact_info_item d-flex flex-row align-items-center justify-content-start">
              <div class="contact_info_image">
                <img src="https://img.icons8.com/office/344/link.png" alt="" />
              </div>
              <div class="contact_info_content">
                <div class="contact_info_title">Links</div>
                <div class="contact_info_text">
                  <span>
                    {" "}
                    <a
                      href="https://www.facebook.com/Houseliv.co/"
                      style={{ textDecoration: "none", color: "#1b2435" }}
                    >
                      <i
                        className="fa fa-facebook-square fa-2x pe-1"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://www.instagram.com/houselivrealty/?hl=en"
                      style={{ textDecoration: "none", color: "#1b2435" }}
                    >
                      <i
                        className="fa fa-instagram fa-2x pe-1 ps-1"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://twitter.com/houselivrealty"
                      style={{ textDecoration: "none", color: "#1b2435" }}
                    >
                      <i
                        className="fa fa-twitter-square fa-2x pe-1 ps-1"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://www.youtube.com/channel/UCGZsZxaCtTSQm57Sl-X71_w"
                      style={{ textDecoration: "none", color: "#1b2435" }}
                    >
                      <i
                        className="fa fa-youtube fa-2x pe-1 ps-1"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            {/* <!-- Contact Item --> */}
            {/* <div style={{ width: "calc((100% - 60px) / 3)", height: "100px" }}>
              <a
                href="https://play.google.com/store/apps/details?id=com.houseliv.app"
                target="_blank"
              >
                <img
                  src="/appdownload.png"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="pt-5" style={{fontSize:"20px"}}>Description</div><br /> */}

      {/* <div className="pt-5"  style={{fontSize:"20px"}}>Contact Details</div> */}
      {/* <div>
        <a href={"mailto:${info@houseliv.com}"}>
              info@houseliv.com
                </a>
        </div>
        <div>
        <a href={"tel:${+91-9066555546}"}  
            style={{  color: "black",
            textDecoration:"none"}}> 
           Call: +91-9066555546</a>
        </div><br/>
        <div style={{fontSize:"20px"}}>Address</div>
        <div>
         Vibha Complex, 10, Nagarabhavi Main Rd<br/>
        Govindaraja Nagar Ward, Vijayanagar,<br/>
         Bengaluru, Karnataka 560040<br/>
        </div> */}
      <br />
      {/* <div style={{fontSize:"20px"}}>Timings: Monday to Saturday 9AM TO 6PM</div>
        <br/>
        <div style={{fontSize:"20px"}}>Links</div>
        <div className="mb-4">
        <span>  <a href="https://www.facebook.com/Houseliv.co/" 
                style={{textDecoration:"none", color:"#1b2435"}}
                >
                <i
                  className="fa fa-facebook-square fa-2x pe-1"
                  aria-hidden="true"
                ></i>
              </a></span>
              <span><a href="https://www.instagram.com/houselivrealty/?hl=en" 
                style={{textDecoration:"none", color:"#1b2435"}}
                >
                <i
                  className="fa fa-instagram fa-2x pe-1 ps-1"
                  aria-hidden="true"
                ></i>
                </a>
                </span>
              <span><a href="https://twitter.com/houselivrealty" 
                style={{textDecoration:"none", color:"#1b2435"}}
                >
                <i
                  className="fa fa-twitter-square fa-2x pe-1 ps-1"
                  aria-hidden="true"
                ></i>
                </a>
                </span>
                <span>
                <a href="https://www.youtube.com/channel/UCGZsZxaCtTSQm57Sl-X71_w" 
                style={{textDecoration:"none", color:"#1b2435"}}
                >
                <i
                  className="fa fa-youtube fa-2x pe-1 ps-1"
                  aria-hidden="true"
                ></i>
                </a>
                </span>
                <span>
                </span>
        </div> */}
    </div>
  );
}

export default Contact;
