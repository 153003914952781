import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { DataGrid } from '@mui/x-data-grid';
import styled from '@emotion/styled';

import { Link } from "react-router-dom";

function Addcity() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [show, setshow] = useState(false);
  const [city, setcity] = useState("")
  const [selected, setselected] = useState({});
  const [edit, setedit] = useState(false);
  const [rowdata, setrowdata] = useState({});
  const [getcitydata, setgetcitydata] = useState([]);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);


  const updatecity = async () => {
    let config = {
      url: "/propertyForOwner/createAddCity",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
       
        city: city,
      },
    };
    let res = await axios(config);

    if (res.status == 200) {
      alert("City Added")
      window.location.reload();
    } else {
      console.log("error");
    }
  };

    const deletecity = async (data) => {
      let config = {
        url: "/propertyForOwner/deleteAddCity/" + data._id,
        method: "post",
        baseURL: "https://houseliv.in/api",
      //   data: {
       
      //   city: city,
      // },
        
      };
      let res = await axios(config);

      if (res.status == 200) {
        alert("City Deleted")
        window.location.reload();
      } else {
        console.log("error");
      }
    };

    useEffect(() => {
      getcity();
    }, [])


    const getcity =  () => {
      try{
        axios
        .get('https://houseliv.in/api/propertyForOwner/getAddCity').then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setgetcitydata(response.data.cityList1);
            return;
          } else {
            alert("Can't able to fetch ");
            return;
          }
        })
      }
      catch(error) {
      console.log(error.message.data);
      alert("Can't able  to fetch");
      return ;
      }; 
  };

    
    
        console.log(getcitydata);
  var i=1;
  return (
    <div className="ml-4">
      <Row>
          <div >
          <Button className="cityButton"  
        //   onClick={handleShow}
             onClick={() =>{ 
                setshow(true);
                setedit(true);
                setselected(city);
                
             }}>
        Add City
      </Button>

      <Modal show={show} onHide={() => setshow(false)}>
        <Modal.Header >
          <Modal.Title>City Name</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <h6>Add City Name</h6>
           
            <Form.Control type="text" placeholder="Enter city" 
            onChange={(e) => setcity(e.target.value)}
            />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" 
          style={{backgroundColor:"#6c757d", 
          borderColor:"#6c757d"}} 
          onClick={() => setshow(false)}>
            Close
          </Button>

          <Button variant="primary" 
          style={{backgroundColor:"rgb(201 84 95)", 
          borderColor:"rgb(201 84 95)"}} 
          onClick={()=> {
            updatecity(); 
                     }}
          // onClick={ updatecity}
          
          >
            Add 
          </Button>
        </Modal.Footer>

      </Modal>
          </div>

    <div className="cityTable">
    <Table striped bordered hover  variant="blue" size="sm" 
    style={{width:"75%", marginLeft:"16px", marginTop:"15px"}}
    >
        <thead style={{color:"white",  textAlign:"center"}}>
            <tr>
      <th></th>
      <th>City Name</th>
      <th>Status</th>
    </tr>
        </thead>
        <tbody style={{color:"white"}}>
          {getcitydata.map((data) => (
 <tr>
 <td style={{color:"white", textAlign:"center"}}>{i++}</td>
 <td style={{color:"white"}}>{data.city}</td>
 
 <td style={{textAlign:"center"}}>
     <Button className="citydelete" 
      onClick={() => deletecity(data)}>
 Delete
 </Button>
</td>
</tr>
          ))}
   
  </tbody>
</Table>
    </div>
       
      </Row>
    </div>
  );
}

export default Addcity;
