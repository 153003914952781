import React from "react";
import {
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div
      className="text-center text-lg-start  text-muted"
      style={{
        backgroundColor: "#1b2435",
        color: "white",
        // boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      {/* <div className="container d-flex">
        <div
          className="row pt-4  d-flex"
          style={{
            color: "white",
            width: "100%",
          }}
        >
          <div className="col-md-3 col-sm-12">
            <img
              src="/img/HouseLiv1234.png"
              className="mt-3"
              width="80px"
              height="60px"
            ></img>
          </div>

          <div className="col-md-4 col-sm-12 mt-1 quick-links text-center">
            <ul style={{ listStyleType: "none", textAlign:"left" }}>
              <li >
                <h5 style={{textAlign:"left", color:"#956433"}}>Contact Us</h5>
                <a href={"tel:${+91-9066555546}"}  
            style={{  color: "white",
            textDecoration:"none"}}> 
           Call: +91-9066555546</a>
                <br></br>
                <a href={"mailto:${info@houseliv.com}"}
                style={{ textDecoration: "none", color: "white" }}>
                  Email: info@houseliv.com
                </a>
              </li>

              <li className="social-media">
                <a href="https://www.facebook.com/Houseliv.co/" 
                style={{textDecoration:"none", color:"white"}}
                >
                <i
                  className="fa fa-facebook-square fa-2x pe-1"
                  aria-hidden="true"
                ></i>
              </a>
              <a href="https://twitter.com/" 
                style={{textDecoration:"none", color:"white"}}
                >
                <i
                  className="fa fa-twitter-square fa-2x pe-1 ps-1"
                  aria-hidden="true"
                ></i>
                </a>
                <a href="https://www.youtube.com/" 
                style={{textDecoration:"none", color:"white"}}
                >
                <i
                  className="fa fa-youtube fa-2x pe-1 ps-1"
                  aria-hidden="true"
                ></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-12 mt-1 quick-links text-center">
            <ul style={{ listStyleType: "none", textAlign:"left" }}>
              <li >
                <h5 style={{textAlign:"left", color:"#956433" }}>Address</h5>
                Vibha Complex, 10, Nagarabhavi Main Rd
                <br></br>
                Govindaraja Nagar Ward, Vijayanagar, <br></br>
                Bengaluru, Karnataka 560040
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      {/* <div
        className="row me-0 mt-0 pt-0 pb-1 mb-1"
        style={{ backgroundColor: "#1b2435" }}
      > */}
      <div className="row">
        <div className="col-md-4 col-sm-12" style={{ padding: "10px 0px " }}>
          <div
            className="text-center "
            style={{
              width: "100%",
              height: "10%",
            }}
          >
            <Link
              to="/privacy"
              style={{ color: "white", textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className="col-md-4 col-sm-12" style={{ padding: "10px 0px " }}>
          <div
            className="text-center "
            style={{
              color: "white",
              width: "100%",
              height: "10%",
            }}
          >
            HouseLiv © 2023
          </div>
        </div>
        <div className="col-md-4 col-sm-12" style={{ padding: "10px 0px " }}>
          <div
            className="text-center "
            style={{
              color: "white",
              width: "100%",
              height: "10%",
            }}
          >
            <Link
              to="/terms"
              style={{ color: "white", textDecoration: "none" }}
            >
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
