import { ButtonBase, TableRow } from "@material-ui/core";
import { Button } from "bootstrap";
import React, { useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";

function About() {
  return (
    <div
      className="container"
      style={{
        backgroundImage: "url(" + "img/HouseLiv1234.png" + ")",
        backgroundPosition: "center",
        backgroundSize: "450px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="pt-5" style={{ fontSize: "20px" }}>
        About Us
      </div>
      <br />
      <div>Welcome To HOUSELIV </div>
      Houseliv is a BOON to Buyer, Seller, and Tenant, anywhere in Bharat, The
      Great, with no broker and broker- troubles. We present facts of the
      property without exaggeration or diminution. Property details,
      encumbrances, surroundings, merits, demerits, if any etc are displayed and
      disseminated to the client after strict scrutiny. Buyer and seller can
      contact each other directly also without hindrance. If need be, we will
      help. Our presentation format is clear, crypt and precise for you to
      decide easily and with least effort. Any seller, buyer or prospective
      tenant can contact us without any hesitation. We are here to help you..
      <br />
      {/* <div className="pt-3">More Details visit</div>
        <div>
        <a href={"mailto:${info@houseliv.com}"}>
              info@houseliv.com
                </a>
        </div>
        <div>
        <a href={"tel:${+91-9066555546}"}  
            style={{  color: "black",
            textDecoration:"none"}}> 
           Call: +91-9066555546</a>
        </div><br/>
        <div style={{fontSize:"20px"}}>Address</div>
        <div>
         Vibha Complex, 10, Nagarabhavi Main Rd<br/>
        Govindaraja Nagar Ward, Vijayanagar,<br/>
         Bengaluru, Karnataka 560040<br/>
        </div> */}
      <br />
      <div style={{ fontSize: "20px" }}>Links</div>
      <div className="mb-4">
        <span>
          {" "}
          <a
            href="https://www.facebook.com/Houseliv.co/"
            style={{ textDecoration: "none", color: "#1b2435" }}
          >
            <i
              className="fa fa-facebook-square fa-2x pe-1"
              aria-hidden="true"
            ></i>
          </a>
        </span>
        <span>
          <a
            href="https://www.instagram.com/houselivrealty/?hl=en"
            style={{ textDecoration: "none", color: "#1b2435" }}
          >
            <i
              className="fa fa-instagram fa-2x pe-1 ps-1"
              aria-hidden="true"
            ></i>
          </a>
        </span>
        <span>
          <a
            href="https://twitter.com/houselivrealty"
            style={{ textDecoration: "none", color: "#1b2435" }}
          >
            <i
              className="fa fa-twitter-square fa-2x pe-1 ps-1"
              aria-hidden="true"
            ></i>
          </a>
        </span>
        <span>
          <a
            href="https://www.youtube.com/channel/UCGZsZxaCtTSQm57Sl-X71_w"
            style={{ textDecoration: "none", color: "#1b2435" }}
          >
            <i className="fa fa-youtube fa-2x pe-1 ps-1" aria-hidden="true"></i>
          </a>
        </span>
        <span></span>
      </div>
    </div>
  );
}

export default About;
