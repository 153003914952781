import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Tab,
  TabContainer,
} from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Pgamenities() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [availableServices, setavailableServices] = React.useState("");
  const [parking, setparking] = React.useState("");
  const [availableAmenities, setavailableAmenities] = React.useState("");

  const [yes, setyes] = React.useState(false);
  console.log(yes);

  const [no, setno] = React.useState(false);
  console.log(no);

  const [yes2, setyes2] = React.useState(false);
  console.log(yes2);

  const [no2, setno2] = React.useState(false);
  console.log(no2);

  const [yes3, setyes3] = React.useState(false);
  console.log(yes3);

  const [no3, setno3] = React.useState(false);
  console.log(no3);

  const [lift, setlift] = React.useState(false);
  console.log(lift);

  const [mess, setmess] = React.useState(false);
  console.log(mess);

  const [commonTv, setcommonTv] = React.useState(false);
  console.log(commonTv);

  const [refrigerator, setrefrigerator] = React.useState(false);
  console.log(refrigerator);

  const [cookingAllowed, setcookingAllowed] = React.useState(false);
  console.log(cookingAllowed);

  const [wifi, setwifi] = React.useState(false);
  console.log(wifi);

  const [powerbackup, setpowerbackup] = React.useState(false);
  console.log(powerbackup);

  // const [availableAmenities, setavailableAmenities] = React.useState(false);
  // console.log(availableAmenities);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createAmenitiesDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        availableServices: {
          laundry: yes ? "Yes" : no ? "No" : "",
          roomCleaning: yes2 ? "Yes" : no2 ? "No" : "",
          wardenFacility: yes3 ? "Yes" : no3 ? "No" : "",
        },

        availableAmenities: {
          lift: lift,
          mess: mess,
          commonTv: commonTv,
          refrigerator: refrigerator,
          cookingAllowed: cookingAllowed,
          wifi: wifi,
          powerbackup: powerbackup,
        },
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/pg_gallery");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Four">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Room Details</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">PG Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 mb-3"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "18px",
              }}
            >
              Provide additional details about your place
            </Card>{" "}
            <Row>
              <Row className="mt-3 ms-3">
                <Col
                  style={{ fontSize: "17px", fontWeight: "600" }}
                  onChange={(e) => setavailableServices(e.target.value)}
                >
                  Available Services
                </Col>
              </Row>
              <Row className="d-flex ms-3 mt-3">
                <Col>
                  <Form>
                    <Form.Label
                      style={{ fontWeight: "600", color: "GrayText" }}
                    >
                      Laundry
                    </Form.Label>
                  </Form>
                  {["radio"].map((type) => (
                    <div
                      key={`inline-${type}`}
                      className="mb-3"
                      style={{ color: "#1b2435" }}
                    >
                      <Form.Check
                        inline
                        label="Yes"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        value={yes}
                        onChange={() => setyes(!yes)}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                        value={no}
                        onChange={() => setno(!no)}
                      />
                    </div>
                  ))}
                </Col>
                <Col>
                  {" "}
                  <Form>
                    <Form.Label
                      style={{ fontWeight: "600", color: "GrayText" }}
                    >
                      Room Cleaning
                    </Form.Label>
                  </Form>
                  {["radio"].map((type) => (
                    <div
                      key={`inline-${type}`}
                      className="mb-3"
                      style={{ color: "#1b2435" }}
                    >
                      <Form.Check
                        inline
                        label="Yes"
                        name="group2"
                        type={type}
                        id={`inline-${type}-3`}
                        value={yes2}
                        onChange={() => setyes2(!yes2)}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="group2"
                        type={type}
                        id={`inline-${type}-4`}
                        value={no2}
                        onChange={() => setno2(!no2)}
                      />
                    </div>
                  ))}
                </Col>
                <Col md={4}>
                  {" "}
                  <Form>
                    <Form.Label
                      style={{ fontWeight: "600", color: "GrayText" }}
                    >
                      Warden Facility
                    </Form.Label>
                  </Form>
                  {["radio"].map((type) => (
                    <div
                      key={`inline-${type}`}
                      className="mb-3"
                      style={{ color: "#1b2435" }}
                    >
                      <Form.Check
                        inline
                        label="Yes"
                        name="group3"
                        type={type}
                        id={`inline-${type}-5`}
                        value={yes3}
                        onChange={() => setyes3(!yes3)}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="group3"
                        type={type}
                        id={`inline-${type}-6`}
                        value={no3}
                        onChange={() => setno3(!no3)}
                      />
                    </div>
                  ))}
                </Col>{" "}
              </Row>
              <Row className=" ms-0 mt-5">
                <hr />
                </Row>
              <Row className="ms-4 mt-4">
                
                <Row
                  className=" mt-3"
                  style={{
                    fontWeight: "600",

                    fontSize: "17px",
                  }}
                  onChange={(e) => setavailableAmenities(e.target.value)}
                >
                  Select the available amenities
                </Row>
                <Row className="mt-4 ms-2">
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Lift"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={lift}
                          onChange={() => setlift(!lift)}
                        />
                      </div>
                    ))}{" "}
                  </Col>

                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Mess"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={mess}
                          onChange={() => setmess(!mess)}
                        />
                      </div>
                    ))}{" "}
                  </Col>

                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Common TV"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={commonTv}
                          onChange={() => setcommonTv(!commonTv)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Refrigerator"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={refrigerator}
                          onChange={() => setrefrigerator(!refrigerator)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Cooking Allowed"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={cookingAllowed}
                          onChange={() => setcookingAllowed(!cookingAllowed)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Wifi"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={wifi}
                          onChange={() => setwifi(!wifi)}
                        />
                      </div>
                    ))}{" "}
                  </Col>

                  <Col md={6} className="mt-2 mb-2">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Power Backup"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={powerbackup}
                          onChange={() => setpowerbackup(!powerbackup)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                </Row>

                <Row className="mt-3">
                  {/* <hr /> */}
                  <Col>
                    <Form validated>
                      <Form.Label
                        style={{ fontSize: "17px", fontWeight: "600" }}
                      >
                        Parking
                      </Form.Label>
                      <Form.Group controlId="formGridState">
                        <select
                          className="form-control "
                          style={{
                            width: "250px",
                            color: "GrayText",
                            cursor: "pointer",
                          }}
                          onChange={(e) => setparking(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          <option value="Bike">Bike</option>
                          <option value="Car">Car</option>
                          <option value="Both">Both</option>
                          <option value="None">None</option>
                        </select>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </Row>
            </Row>
          </Card>{" "}
        </Col>
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Pgamenities;
