import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import 'bootstrap/dist/css/bootstrap.css'; 
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
// import MultiRangeSlider from "./multiRangeSlider/MultiRangeSlider";
// import "./styles.css";
import "bootstrap";
// import { StyledEngineProvider } from '@mui/material/styles';

import $ from "jquery";

ReactDOM.render(
  // <Router>
  //   <div className="App">
  //     <Navigation />
  //     <Routes />
  //   </div>
  // </Router>,

 
    <App />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
