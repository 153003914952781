import React from "react";
import { ButtonGroup, Card, Nav, Row } from "react-bootstrap";
import useState from "react";
import {
  Carousel,
  Col,
  Dropdown,
  Form,
  FormCheck,
  InputGroup,
  Sonnet,
} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { AppBar } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom";
import { Button, Container } from "@material-ui/core";
// import Typography from "@material-ui/core/Typography";
// import React from "react";
// import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Faq from "react-faq-component";
import Propertymanage from "./Propertymanage";
import axios from "axios";
import { LaptopWindows } from "@material-ui/icons";
import moment from "moment";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#1b2435",
    "&$checked": {
      color: "#1b2435",
    },
    "&$checked + $track": {
      backgroundColor: "#1b2435",
    },
  },
  checked: {},
  track: {},
})(Switch);

const label = { inputProps: { "aria-label": "Switch demo" } };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const data = {
  rows: [
    {
      title: "How do I post my house for rent?",
      content:
        "When you decide you want to rent your flat or find a tenant, you need to start by creating a property ad. The ad should have key information like – ",
    },
    {
      title: "What should an advertisement for sale of a house contain?",
      content:
        "HRA is calculated based on ? - Gross Salary - HRA Percentage of Salary - House Rent minus 10% of basic salary - 50% HRA if the employee is staying in a metro city and 40% HRA for non-metro cities.",
    },
    {
      title: "Why should I post my property for rent or sale online?",
      content:
        "Making a rental advertisement or advertisement for the sale of a flat is very important. With the right ad, you can let anywhere from 100s, to 1000s of people know that you are ready to rent or sell your house. The ad will give them the key information they need and capture their interest.The potential to find a tenant or buyer of your liking is larger. Instead of settling for a tenant or buyer from 2-3 choices, you can choose the best from a lot more. If you’re looking for a young family, students, IT working professionals, or any other type of tenant or buyer, you can find them with the right flat advertisement.",
    },
    {
      title: "Is it possible to rent homes without an agent?",
      content:
        " Most people think all house selling websites have brokers, and you will lose money when you use them. Most home selling sites, and listing sites even charge money just for listing your home for rent or sale. But, on a website or property app like NoBroker, the whole process of listing your home and finding a tenant or buyer is FREE. There are no agents involved. You will talk directly with interested buyers and tenants and you control the whole process.",
    },
    {
      title: "How do I sell/rent out a house myself?",
      content:
        " Many people wonder, if you don’t use an agent to rent or sell your house, how can you do it on your own? The answer is simple. Just visit any of the free listing sites, especially NoBroker, then create an account, as soon as your account is verified, you can add the important details about your house. You can access this free ad posting site from your desktop or use the mobile app, it is one of the best property apps India, it is also very easy to use. If you still find this process difficult or confusing, you can share your house details on WhatsApp! Just send a text to +91 9241700000. You can even give us a call on 9241700000 the experts at NoBroker will note down your details and make a free advertisement for sale of flat or advertise for a house for rent. This free ad post will be done in minutes, and from the comfort of your home.",
    },
    {
      title: "Where can I list a commercial property for rent for free?",
      content:
        " The landlord?s PAN is required only if you pay more than Rs.1,00,000 annually on rent. Without this PAN you will not be able to claim HRA. IF your landlord does not have a PAN account, then you need to get a declaration from him stating the same.",
    },
    {
      title: "Where can I list a commercial property for sale for free?",
      content:
        "When you want to sell a commercial property like sell a shop, sell an office building or office space, you need to provide the same details as above. Your free property selling ad should have all the key details about the property, locations of the property, amenities available in the property, and most importantly if all the required commercial property sale documents are in order. ",
    },
    {
      title: "What is the benefit of posting property ads on houseLiv?",
      content:
        "When you post a property for sale ad or post an ad for rent of a house on houseLiv, you’ll be connecting to lakhs of potential buyers and renters! Apart from this, the whole process of registering and posting an ad for house sale/ house for rent ad/ commercial property rental ad is FREE.The other benefit is that you will have experts who can guide you through the process, you have the freedom to decide if you want to do this on your own, or have an expert take over and post the property ad on your behalf.You will be sharing pictures of your house or commercial property; these pictures will help you get up to 10x better responses. In a newspaper or magazine ad, you will not be able to share more than 1-2 pictures, on houseLiv you can upload many pictures and you won’t have to pay anything to attach it to your property ad.",
    },
    {
      title: "Can I get additional support from houseLiv?",
      content:
        " If you feel that hiring an agent or broker to rent or sell your house/commercial property is a good idea, because they offer additional services, you’re not wrong. But, houseLiv has the ability and experience to offer you more benefits and services from a team of experienced and knowledgeable people.From assistance with taking pictures, to showing your house on your behalf, to helping you find the right price to sell/rent your property, our team can do it all. You can even boost your property listing, houseLiv can make sure that more people see it and make it a trending listing!.The whole process becomes so simple, fast and efficient. You don’t have to do things the old-fashioned way when there is a better way to rent houses faster and sell faster too, the same goes for commercial property.",
    },
    // {
    //   title:
    //     "Does the rent receipt need to be on paper or is a soft copy enough? ",
    //   content:
    //     "This varies from organization to organization, it is best to check with your company or employer as to what they require.",
    // },
    // {
    //   title:
    //     "If I change jobs, will the new company need my old Rent Receipts?",
    //   content:
    //     "As soon as you shift jobs, your new employer will inform you of all the documents that you need to submit. As part of these documents, your rent receipts should also be mentioned. In the case where your current employer is allowing you HRA exemptions from previous one, you will need to submit the old rent receipts as well as the new ones.",
    // },
    // {
    //   title: "Does HRA exemption apply to me if I pay rent to my family?",
    //   content:
    //     "Yes, you can. The only catch is the place that you are claiming rent for can't be owned by you. If you are living with your parents and the house is in their name, and you pay them rent, you can still claim HRA. This does not mean you can simply claim that you are paying them money for rent, you need to have authentic rent receipts and also bank records that show monthly transactions for the same amount. If the tax department does not think that the claim is authentic, you could get into trouble.",
    // },
    //   {
    //     title: "",
    //     content: "",
    //   },
  ],
};

const styles = {
  // bgColor: 'white',
  // width: "980px",
  // margin: "10px",
  // titleTextColor: "green",
  // rowTitleColor: "#009587",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function Contacts() {
  // const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const user = JSON.parse(sessionStorage.getItem("user")); //session storage
  const [modalShow, setModalShow] = React.useState(false);

  const [name, setname] = React.useState(user.name);
  // const [name, setname] = useState("");
  const [emailId, setemailId] = React.useState(user.email);
  const [mobileNumber, setmobileNumber] = React.useState(user.phonenumber);
  const [selectCity, setselectCity] = React.useState("");
  const [property, setproperty] = React.useState("Residential");
  const [propertytype, setpropertytype] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  console.log(value);

  const postdata = async () => {
    if (!propertytype) {
      alert("Please select Property Type");
    } else {
      let config = {
        url: "/propertyForOwner/createPropertyType",
        method: "post",
        baseURL: "https://houseliv.in/api",
        data: {
          userId: user._id,
          emailId: emailId,
          mobileNumber: mobileNumber,
          name: name,
          property: property,
          propertytype: propertytype,
          selectCity: selectCity,
          expire_at: moment().add(3, "month").calendar(),
        },
      };

      let res = await axios(config);

      if (res.status == 200) {
        console.log(res);
        sessionStorage.setItem("propertyId", res.data._id);
        if (property === "Residential" && propertytype === "Rent") {
          window.location.assign("/addproperty");
        } else if (property === "Residential" && propertytype === "New sale") {
          window.location.assign("/resale_propertymanage");
        } else if (property === "Residential" && propertytype === "PG/Hostel") {
          window.location.assign("/pg_roomselect");
        } else if (property === "Residential" && propertytype === "Flatmates") {
          window.location.assign("/flatmate_property");
        } else if (property === "Commercial" && propertytype === "Rent") {
          window.location.assign("/commercial_rent_property");
        } else if (property === "Commercial" && propertytype === "Sale") {
          window.location.assign("/commercial_sale_property");
        } else if (property === "Residential" && propertytype === "Plots") {
          window.location.assign("/commercial_plot_details");
        }
      } else {
        console.log("error");
        //alert("Incorrect mail id/password");
      }
    }
  };

  return (
    <div className="container">
      <div className="mt-5 row me-0">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <h1
            style={{
              fontSize: "23px",
              fontFamily: "Source Sans Pro,sans-serif",
              color: "#464646",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Sell or Rent your Property For Free
          </h1>
        </div>
      </div>
      <Row className=" text-center">
        <Col md={3}></Col>
        <Col md={6}>
          <div
            className="propertieee mt-3"
            style={{
              fontSize: "20px",
              color: "#464646",
              fontWeight: "500",
            }}
          >
            PROPERTY TYPE
          </div>
          <AppBar
            className=" property-type mt-3"
            width="50%"
            position="static"
            color="default"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="full width tabs example"
              centered
            >
              <Tab label="Residential" {...a11yProps(0)} />
              <Tab label="Commercial" {...a11yProps(1)} />
            </Tabs>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                style={{
                  borderStyle: "ridge",
                  textAlign: "center",
                }}
                value={value}
                index={0}
                dir={theme.direction}
              >
                <button
                  className="btn btn-success mt-1"
                  onClick={() => {
                    setproperty("Residential");
                    setpropertytype("Rent");
                  }}
                >
                  Rent
                </button>{" "}
                <button
                  className="btn btn-success mt-1"
                  onClick={() => {
                    setproperty("Residential");
                    setpropertytype("New sale");
                  }}
                >
                  {" "}
                  New sale
                </button>{" "}
                <button
                  className="btn btn-success mt-1"
                  onClick={() => {
                    setproperty("Residential");
                    setpropertytype("PG/Hostel");
                  }}
                >
                  PG/Hostel
                </button>{" "}
                <button
                  className="btn btn-success mt-1"
                  onClick={() => {
                    setproperty("Residential");
                    setpropertytype("Flatmates");
                  }}
                >
                  Flatmates
                </button>{" "}
                <button
                  className="btn btn-success mt-1"
                  onClick={() => {
                    setproperty("Residential");
                    setpropertytype("Plots");
                  }}
                >
                  Plots
                </button>{" "}
              </TabPanel>
              <TabPanel
                style={{
                  borderStyle: "ridge",
                }}
                value={value}
                index={1}
                dir={theme.direction}
              >
                <button
                  className="btn btn-success mt-1"
                  onClick={() => {
                    setproperty("Commercial");
                    setpropertytype("Rent");
                  }}
                >
                  Rent
                </button>{" "}
                <button
                  className="btn btn-success mt-1"
                  onClick={() => {
                    setproperty("Commercial");
                    setpropertytype("Sale");
                  }}
                >
                  Sale
                </button>{" "}
              </TabPanel>
            </SwipeableViews>
          </AppBar>
          <Button
            className="mb-3 mt-3"
            style={{ backgroundColor: "#1b2435", color: "white" }}
            onClick={postdata}
          >
            Start Posting Your Ad For Free
          </Button>
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row>
        {" "}
        <Col md={3}></Col>
        <Col md={6}>
          <div
            className="ps-1 mt-2 mb-3"
            style={{
              padding: "5px",
              margin: "center",
              borderRadius: "3px",
              color: "black",
              fontSize: "15px",
              textAlign: "center",
              backgroundColor: "#feebd2",
              fontFamily: "Source Sans Pro,sans-serif",
            }}
          >
            <img
              className="me-1"
              src="https://www.freeiconspng.com/thumbs/telephone-icon/tel-phone-icon-5.png"
              width="23px"
              height="22px"
            ></img>
            {""}
            Give a missed call to
            {/* {`tel:${data?.propertytypes[0].mobileNumber}` } */}
            <a
              href={"tel:${+91-9066555546}"}
              style={{
                backgroundColor: "rgb(206 187 163)",
                textDecoration: "none",
                color: "#323232",
              }}
            >
              <span>+91-9066555546</span>
            </a>{" "}
            to get help with your property listing{" "}
          </div>{" "}
        </Col>
        <Col md={3}></Col>
      </Row>
    </div>
  );
}

export default Contacts;
