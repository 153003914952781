import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton, Input } from "@material-ui/core";
import { Link } from "react-router-dom";
// import Box from '@mui/material/Box';
// import Input from 'material/Input';

function Header() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow3, setmodalShow3] = React.useState(false);
  const [modalShow4, setmodalShow4] = React.useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [fullname, setfullname] = useState("");
  const [emailaddress, setemailaddress] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [emailaddress1, setemailaddress1] = useState("");
  const [password1, setpassword1] = useState("");
  const [mobilePhone, setmobilePhone] = useState("");
  const [otp, setotp] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [email1, setemail1] = useState("");
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const handleLoginClose = () => setModalShow1(false);
  const handleSignupClose = () => setModalShow(false);
  const handlesendotp = () => setmodalShow3(true);
  const handlesignupsendotp = () => setmodalShow4(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const ariaLabel = { "aria-label": "description" };
  const open = () => {
    if (user) {
      window.location.assign("/propertymanage");
    } else {
      alert("Please Login");
    }
  };

  // const open = () =>{
  //   if(user){
  //     window.location.assign("/propertymanage")
  //   }else{
  //     alert("please login")
  //   }
  // }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const postdata = async () => {
    if (password !== confirmpassword) {
      alert("Password Mismatch");
    } else {
      try {
        let config = {
          url: "/users/createUser",
          method: "post",
          baseURL: "https://houseliv.in/api",
          data: {
            fullName: fullname,
            mobilePhone: mobilenumber,
            emailId: emailaddress,
            password: password,
            cPassword: confirmpassword,
          },
        };

        let res = await axios(config);

        if (res.status === 200) {
          // alert("Account Created");
          handleSignupClose();
          // setmobilePhone(res.data.user.phonenumber)
          handlesignupsendotp();
        }
        // else {
        //   alert("Account Created");
        //   handleSignupClose();
        //   window.location.reload();
        // }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  const postsignin = async () => {
    try {
      let config = {
        url: "/users/signin",
        method: "post",
        baseURL: "https://houseliv.in/api",
        data: {
          email: emailaddress1,
          password: password1,
        },
      };

      let res = await axios(config);

      if (res.status === 200) {
        console.log(res);
        console.log(res.data.user);
        if (res.data.user.blockstatus) {
          handleLoginClose();
          alert("Account Blocked");
        } else {
          handleLoginClose();
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          alert("Login Successful!!!");
          window.location.assign("/");
          // setmobilePhone(res.data.user.phonenumber)
          // handlesendotp();
        }
      } else {
        console.log("error");
        alert("Incorrect mail-Id/password");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const postsigninwithmobile = async () => {
    try {
      let config = {
        url: "/users/signinwithmobile",
        method: "post",
        baseURL: "https://houseliv.in/api",
        data: {
          mobilePhone: mobilenumber,
        },
      };

      let res = await axios(config);

      if (res.status === 200) {
        console.log(res);
        console.log(res.data.user);
        if (res.data.user.blockstatus) {
          handleLoginClose();
          alert("Account Blocked");
        } else {
          handleLoginClose();
          setmobilePhone(res.data.user.phonenumber);
          handlesendotp();
        }
      } else {
        console.log("error");
        alert("Incorrect mail-Id/password");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // const sendotp = async (e) => {
  //   // e.preventDefault();
  //   try {
  //     const config = {
  //       url: "/sendotp",
  //       method: "post",
  //       baseURL: "https://houseliv.in/api",
  //       headers: { "content-type": "application/json" },
  //       data: { email: email },
  //       //responseType:"application/json"
  //     };
  //     let res = await axios(config);
  //     if (res.status === 200) {
  //       console.log(res.data);

  //       // localStorage.setItem("signupdata",JSON.stringify(res.data.user));
  //       // localStorage.setItem("token",res.data.token);
  //       // window.location.assign("/verify-otp");
  //       postsignin();
  //       handlesendotp();
  //       return res;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  console.log(mobilenumber, mobilePhone);

  // login with mobile number
  const verifyotp = async () => {
    if (!otp) {
      alert("Please Enter OTP");
    } else {
      try {
        const config = {
          url: "/verifycustomerotp",
          method: "post",
          baseURL: "https://houseliv.in/api",
          headers: { "content-type": "application/json" },
          data: {
            // mobileNumber: mobileNumber,
            mobilePhone: mobilePhone,
            otp: otp,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          alert("Login Successful!");
          window.location.assign("/");
          return res;
        }
      } catch (error) {
        // console.log(error);
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  const verifysignupotp = async () => {
    try {
      if (otp === "") {
        alert("please enter otp");
      } else {
        const config = {
          url: "/verifycustomerotp",
          method: "post",
          baseURL: "https://houseliv.in/api",
          headers: { "content-type": "application/json" },
          data: {
            mobilePhone: mobilenumber,
            otp: otp,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          alert("Account create successfully. Please login!!!");
          window.location.assign("/");
          return res;
        }
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const signout = () => {
    try {
      axios
        .get(`https://houseliv.in/api/users/signout/` + userdata._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("propertyId");
            alert("Signout Successfully");
            window.location.href = "/";
            return;
          } else {
            alert("Signout Unsuccessfully");
            return;
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Signout Unsuccessfully");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Signout Unsuccessfully");
      return error;
    }
  };
  const about = () => {
    window.location.assign("/about");
  };
  const contact = () => {
    window.location.assign("/contact");
  };

  return (
    <div>
      <Navbar
        expand="lg"
        style={{
          backgroundColor: "#1b2435",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {userdata === null ? (
                <>
                  {/* <Nav.Link onClick={() => setModalShow(true)}>
                    Sign Up
                  </Nav.Link> */}
                  <Nav.Link onClick={() => setModalShow1(true)}>
                    Login/Sign-Up
                  </Nav.Link>{" "}
                  <Nav.Link onClick={about}>About</Nav.Link>
                  <Nav.Link onClick={contact}>Contact Us</Nav.Link>
                  <Nav.Link
                    onClick={
                      "https://play.google.com/store/apps/details?id=com.houseliv.app"
                    }
                  >
                    <img
                      src="/appdownload.png"
                      alt=""
                      style={{ width: "30%", height: "100%" }}
                    />
                  </Nav.Link>
                </>
              ) : (
                ""
                // <Nav.Link href="/propertymanage">For Property Owners</Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand href="/ ">
            <img
              className=" houselivlogo"
              src="/img/HouseLiv1234.png"
              alt="announcement"
              width="70px"
              height="50px"
            ></img>
          </Navbar.Brand>
          <Button className="owner" onClick={open}>
            Post Property{" "}
            <img src="/icon/free (3).png" style={{ width: "35px" }}></img>
          </Button>{" "}
          <Button
            className="owner1"
            onClick={open}
            // style={{ marginLeft: "77px" }}
          >
            Post Property{" "}
            <img src="/icon/free (3).png" style={{ width: "35px" }}></img>
          </Button>
          {userdata !== null ? (
            <>
              <span>
                <IconButton
                  aria-controls="simpl e-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Avatar style={{ height: "30px", width: "30px" }} alt={" "} />
                </IconButton>
                <span style={{ color: "white" }}>{userdata?.name}</span>
                <span className="ps-3 about">
                  <a
                    href="/about"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    About
                  </a>
                </span>
                <span className="ps-3 about">
                  <a
                    href="/contact"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Contact Us
                  </a>
                </span>
                <span className="ps-3 about">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.houseliv.app"
                    target="_blank"
                  >
                    <img
                      src="/appdownload.png"
                      alt=""
                      style={{ width: "10%", height: "8%" }}
                    />
                  </a>
                </span>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => window.location.assign("/profile")}>
                    <i className="fa fa-user header-fa"></i>{" "}
                    <span className="ms-2"> Profile</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => window.location.assign("/myproperties")}
                  >
                    <i className="fa fa-briefcase header-fa"></i>
                    <span className="ms-2"> My Properties</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => window.location.assign("/change_pwd")}
                  >
                    <i className="fa fa-key header-fa"></i>
                    <span className="ms-2"> Change Password</span>
                  </MenuItem>
                  <MenuItem onClick={signout}>
                    <i className="fa fa-power-off header-fa"></i>
                    <span className="ms-2">Logout</span>
                  </MenuItem>
                </Menu>
              </span>{" "}
            </>
          ) : (
            ""
          )}
        </Container>
      </Navbar>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Full Name"
                onChange={(e) => setfullname(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setemailaddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Mobile Number"
                onChange={(e) => setmobilenumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm password"
                onChange={(e) => setconfirmpassword(e.target.value)}
              />
            </Form.Group>
            <p>
              Already have an account?&nsbp;
              <a
                onClick={() => (setModalShow1(true), setModalShow(false))}
                style={{ fontWeight: "bold", textDecoration: "underline" }}
              >
                Login
              </a>
            </p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              color: "#fff",
              backgroundColor: "#0e121b",
              borderColor: " #0e121b",
            }}
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
          <Button
            style={{
              color: "#fff",
              backgroundColor: "#0e121b",
              borderColor: " #0e121b",
            }}
            onClick={postdata}
          >
            Signup
          </Button>
        </Modal.Footer>
      </Modal>
      {/* this modal for login with mobile */}

      <Modal
        size="sm"
        show={modalShow3}
        // onHide={() => setmodalShow3(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <img src="/icon/security (1).png" style={{ width: "40px" }}></img>{" "}
            {""}
            OTP
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Input
              style={{ fontSize: "18px" }}
              onChange={(e) => setotp(e.target.value)}
            />
          </div>
          <div style={{ fontSize: "small" }}>
            {" "}
            Enter 6 Digit SMS Otp <spam style={{ color: "#dc3545" }}>*</spam>
          </div>
        </Modal.Body>
        <Button
          style={{
            color: "#fff",
            backgroundColor: "#0e121b",
            borderColor: "#0e121b",
            width: "40%",
            marginLeft: "86px",
            marginBottom: "10px",
            fontSize: "18px",
          }}
          onClick={verifyotp}
        >
          Verify
        </Button>
      </Modal>

      <Modal
        className="login"
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4}>
              <img src="/icon/login (2).png" style={{ width: "90%" }}></img>
            </Col>
            <Col xs={8}>
              <h4>Login with</h4>
              <br />
              {["radio"].map((type) => (
                <div
                  key={`inline-${type}`}
                  className="mb-3"
                  style={{ color: "#1b2435" }}
                >
                  <Form.Check
                    inline
                    label="E-Mail"
                    name="group2"
                    type={type}
                    id={`inline-${type}-1`}
                    value={email}
                    onChange={() => {
                      setemail(!email);
                      setmobile(false);
                    }}
                  />
                  <Form.Check
                    inline
                    label="Mobile"
                    name="group2"
                    type={type}
                    id={`inline-${type}-2`}
                    value={mobile}
                    onChange={() => {
                      setmobile(!mobile);
                      setemail(false);
                    }}
                  />
                </div>
              ))}
              <Form validated>
                {email ? (
                  <>
                    <Form.Group
                      className="mb-3"
                      controlId="formGroupEmail"
                      style={{
                        marginTop: "15px",
                      }}
                    >
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => setemailaddress1(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setpassword1(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                {mobile ? (
                  <>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        placeholder="Enter mobile number"
                        onChange={(e) => setmobilenumber(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </>
                ) : (
                  " "
                )}

                <p>
                  Don't have an account? &nbsp;
                  <a
                    onClick={() => (setModalShow(true), setModalShow1(false))}
                    style={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    Sign-Up
                  </a>
                </p>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              color: "#fff",
              backgroundColor: "#0e121b",
              borderColor: " #0e121b",
            }}
            onClick={() => setModalShow1(false)}
          >
            Close
          </Button>
          <Button
            style={{
              color: "#fff",
              backgroundColor: "#0e121b",
              borderColor: " #0e121b",
            }}
            onClick={
              () => (email ? postsignin() : postsigninwithmobile())
              // setmodalShow3(true);
            }
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
      {/* this modal for Signup otp */}
      <Modal
        size="sm"
        show={modalShow4}
        // onHide={() => setmodalShow4(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <img src="/icon/security (1).png" style={{ width: "40px" }}></img>{" "}
            {""}
            OTP
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Input
              style={{ fontSize: "18px" }}
              onChange={(e) => setotp(e.target.value)}
            />
          </div>
          <div style={{ fontSize: "small" }}>
            {" "}
            Enter 6 Digit SMS Otp <spam style={{ color: "#dc3545" }}>*</spam>
          </div>
        </Modal.Body>
        <Button
          style={{
            color: "#fff",
            backgroundColor: "#0e121b",
            borderColor: " #0e121b",
            width: "40%",
            marginLeft: "86px",
            marginBottom: "10px",
            fontSize: "18px",
          }}
          onClick={verifysignupotp}
        >
          Verify
        </Button>
      </Modal>
    </div>
  );
}

export default Header;

// if (typeof input["password"] !== "undefined" && typeof input["confirmpassword"] !== "undefined") {

//   if (input["password"] != input["confirmpassword"]) {

//     isValid = false;

//     errors["password"] = "Passwords don't match.";

//   }

// }
