import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton } from "@material-ui/core";
// import Box from '@mui/material/Box';
// import Input from 'material/Input';

function Header1() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow3, setmodalShow3] = React.useState(false);

  const [fullname, setfullname] = useState("");
  const [emailaddress, setemailaddress] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [emailaddress1, setemailaddress1] = useState("");
  const [password1, setpassword1] = useState("");
  const [mobilePhone, setmobilePhone] = useState("");
  const [otp, setotp] = useState("");
  const [email, setemail] = useState("");
  const [email1, setemail1] = useState("");
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const handleLoginClose = () => setModalShow1(false);
  const handleSignupClose = () => setModalShow(false);
  const handlesendotp = () => setmodalShow3(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const postdata = async () => {
    if (password !== confirmpassword) {
      alert("Password Mismatch");
    } else {
      try {
        let config = {
          url: "/users/createUser",
          method: "post",
          baseURL: "https://houseliv.in n/api",
          data: {
            fullName: fullname,
            mobilePhone: mobilenumber,
            emailId: emailaddress,
            password: password,
            cPassword: confirmpassword,
          },
        };

        let res = await axios(config);

        if (res.status == 200) {
          alert("Account created");
          handleSignupClose();
        }
      } catch (error) {}
    }
  };

  const postsignin = async () => {
    try {
      let config = {
        url: "/users/signin",
        method: "post",
        baseURL: "https://houseliv.in n/api",
        data: {
          email: emailaddress1,
          password: password1,
        },
      };

      let res = await axios(config);

      if (res.status == 200) {
        console.log(res);
        console.log(res.data.user);
        if (res.data.user.blockstatus) {
          alert("Account Blocked");
        } else {
          alert("Login successfully");
          handleLoginClose();
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          // handlesendotp1()
          // window.location.reload();
        }
      } else {
        console.log("error");
        alert("Incorrect mail id/password");
      }
    } catch (error) {
      if (error.response) {
        // console.log("error");
        alert(error.response.data.error);
      }
    }
    // console.log(res);
  };

  const sendotp = async (e) => {
    // e.preventDefault();
    try {
      const config = {
        url: "/sendotp",
        method: "post",
        baseURL: "https://houseliv.in n/api",
        headers: { "content-type": "application/json" },
        data: { email: email },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        // localStorage.setItem("signupdata",JSON.stringify(res.data.user));
        // localStorage.setItem("token",res.data.token);
        // window.location.assign("/verify-otp");
        postsignin();
        handlesendotp();
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyotp = async () => {
    try {
      const config = {
        url: "/verifycustomerotp",
        method: "post",
        baseURL: "https://houseliv.in n/api",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
          otp: otp,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);

        // verifyotp();
        window.location.assign("/");
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(email);

  const signout = () => {
    try {
      axios
        .get(`https://houseliv.in n/api/users/signout/` + userdata._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("propertyId");
            alert("Signout Successfully");

            //    props.dispatch({type:"emptyBasket"});
            //    props.dispatch({type:"removeUser"});
            //  sessionStorage.removeItem("user");
            //  localStorage.removeItem("token");
            window.location.href = "/";
            return;
          } else {
            alert("Signout Unsuccessfully");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Signout Unsuccessfully");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Signout Unsuccessfully");
      // setdatacondition(true);
      return error;
    }
  };

  return (
    <div>
      <Navbar
        fixed="top"
        expand="lg"
        style={{
          marginBottom: "50px",
          backgroundColor: "#1b2435",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <Container>
          <Navbar.Brand href="/">
            {" "}
            <img
              className="me-1"
              src="/img/HouseLiv1234.png"
              alt="announcement"
              width="70px"
              height="50px"
            ></img>{" "}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {/* <Nav.Link href="/">My Booking</Nav.Link> */}

              {userdata === null ? (
                <>
                  <Nav.Link onClick={() => setModalShow(true)}>
                    Sign Up
                  </Nav.Link>
                  <Nav.Link onClick={() => setModalShow1(true)}>Login</Nav.Link>{" "}
                </>
              ) : (
                <Nav.Link href="/propertymanage">For Property Owners</Nav.Link>
              )}
            </Nav>
            {userdata !== null ? (
              <span>
                <IconButton
                  aria-controls="simpl e-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Avatar style={{ height: "30px", width: "30px" }} alt={" "} />
                </IconButton>
                <span style={{ color: "white" }}>{userdata?.name}</span>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => window.location.assign("/profile")}>
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => window.location.assign("/myproperties")}
                  >
                    My Properties
                  </MenuItem>
                  <MenuItem
                    onClick={() => window.location.assign("/change_pwd")}
                  >
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={signout}>Logout</MenuItem>
                </Menu>
              </span>
            ) : (
              ""
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name"
                onChange={(e) => setfullname(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setemailaddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Mobile Number"
                onChange={(e) => setmobilenumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="confirm password"
                onChange={(e) => setconfirmpassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              color: "#fff",
              backgroundColor: "#0e121b",
              borderColor: " #0e121b",
            }}
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
          <Button
            style={{
              color: "#fff",
              backgroundColor: "#0e121b",
              borderColor: " #0e121b",
            }}
            onClick={postdata}
          >
            signup
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                // onChange={(e) => setemail(e.target.value)}
                onChange={(e) => setemailaddress1(e.target.value)}
                required
              />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter mobile number"
                onChange={(e) => setmobilenumber(e.target.value)}
                required
              />
            </Form.Group> */}
            {/* <Row>
              <Col>
                <Button
                  variant="secondary"
                  style={{ backgroundColor: "#7a725d" }}
                >
                  Generate OPT
                </Button>{" "}
              </Col>
            </Row>
    

            <Form.Group className="mb-3 pt-3">
              <Form.Control
                placeholder="OTP Here"
                style={{ width: "40%" }}
                // onChange={(e) => setotp(e.target.value)}
                required
              />
            </Form.Group> */}

            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                // onChange={(e) => setpassword1(e.target.value)}
                onChange={(e) => setpassword1(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              color: "#fff",
              backgroundColor: "#0e121b",
              borderColor: " #0e121b",
            }}
            onClick={() => setModalShow1(false)}
          >
            Close
          </Button>
          <Button
            style={{
              color: "#fff",
              backgroundColor: "#0e121b",
              borderColor: " #0e121b",
            }}
            // onClick={postsignin}
            onClick={postsignin}
          >
            Sendotp
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        show={modalShow3}
        onHide={() => setmodalShow3(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Control
              placeholder="OTP Here"
              style={{ width: "40%" }}
              onChange={(e) => setotp(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Button
          style={{
            color: "#fff",
            backgroundColor: "#0e121b",
            borderColor: " #0e121b",
            width: "40%",
          }}
          onClick={verifyotp}
        >
          verify
        </Button>
      </Modal>
    </div>
  );
}

export default Header1;

// if (typeof input["password"] !== "undefined" && typeof input["confirmpassword"] !== "undefined") {

//   if (input["password"] != input["confirmpassword"]) {

//     isValid = false;

//     errors["password"] = "Passwords don't match.";

//   }

// }
