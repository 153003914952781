import React, { useEffect, useState } from "react";
import { Card, Col, Row, Modal, Form, Carousel } from "react-bootstrap";
import {
  Button,
  Container,
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography,
  Slider,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/styles";
// import TabBar from "./components/TabBar";
import TabBarTest from "./MobileResp";
import MobileResp from "./MobileResp";
import { GoogleComponent } from "react-google-location";
import Geocode from "react-geocode";
import ReactWaterMark from "react-watermark-component";

function Search(props) {
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [LocationPosition, setLocationPosition] = useState({});

  useEffect(() => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocationPosition({
          ...position,
          defaultLatitude: position.coords.latitude,
          defaultLongitude: position.coords.longitude,
        });
      });
    }
  }, []);

  // console.log(
  //   "LocationPosition",
  //   LocationPosition,
  //   LocationPosition.defaultLatitude,
  //   LocationPosition.defaultLongitude
  // );

  function valuetext(value) {
    return `${value}`;
  }
  const [fav, setFav] = React.useState(false);
  const [values, setValues] = React.useState([20, 37]);
  const handleChange1 = (event, newValue) => {
    setValues(newValue);
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [nearby, setnearby] = React.useState(true);
  const [apartmentType, setapartmentType] = React.useState("");
  const [apartmentType1, setapartmentType1] = React.useState("");
  const [apartmentType2, setapartmentType2] = React.useState("");
  const [apartmentType3, setapartmentType3] = React.useState("");
  const [getlistdata, setgetlistdata] = useState([]);

  const [male, setmale] = React.useState(false);
  // console.log(male);
  const [female, setfemale] = React.useState(false);
  // console.log(female);
  const [others, setothers] = React.useState(false);
  // console.log(others);

  const [others1, setothers1] = React.useState(false);
  // console.log(others1);

  const [under, setunder] = React.useState(false);
  // console.log(under);
  const [ready, setready] = React.useState(false);
  // console.log(ready);

  const [singleRoom, setsingleRoom] = React.useState(false);
  // console.log(singleRoom);
  const [doubleRoom, setdoubleRoom] = React.useState(false);
  // console.log(doubleRoom);
  const [threeRoom, setthreeRoom] = React.useState(false);
  // console.log(threeRoom);
  const [fourRoom, setfourRoom] = React.useState(false);
  // console.log(fourRoom);

  const [sharedRoom, setsharedRoom] = React.useState(false);
  // console.log(sharedRoom);
  const [both, setboth] = React.useState(false);
  // console.log(both);

  const [owner, setowner] = React.useState(false);
  // console.log(owner);
  const [agent, setagent] = React.useState(false);
  // console.log(agent);
  const [developer, setdeveloper] = React.useState(false);
  // console.log(developer);

  const [furnishing, setfurnishing] = React.useState(false);
  const [furnishing1, setfurnishing1] = React.useState(false);
  const [furnishing2, setfurnishing2] = React.useState(false);
  const [furnishing3, setfurnishing3] = React.useState(false);
  const [furnishing4, setfurnishing4] = React.useState(false);
  const [furnishing5, setfurnishing5] = React.useState(false);
  const [furnishing6, setfurnishing6] = React.useState(false);
  const [furnishing7, setfurnishing7] = React.useState(false);
  const [furnishing8, setfurnishing8] = React.useState(false);

  const [preferredTenants, setpreferredTenants] = React.useState(false);
  const [preferredTenants1, setpreferredTenants1] = React.useState(false);
  const [preferredTenants2, setpreferredTenants2] = React.useState(false);

  const [propertyType, setpropertyType] = React.useState(false);
  const [propertyType1, setpropertyType1] = React.useState(false);
  const [propertyType2, setpropertyType2] = React.useState(false);
  const [propertyType3, setpropertyType3] = React.useState(false);
  const [propertyType4, setpropertyType4] = React.useState(false);
  const [propertyType5, setpropertyType5] = React.useState(false);
  const [propertyType6, setpropertyType6] = React.useState(false);
  const [propertyType7, setpropertyType7] = React.useState(false);
  const [propertyType8, setpropertyType8] = React.useState(false);

  const [parking1, setparking1] = React.useState(false);
  const [parking2, setparking2] = React.useState(false);
  const [parking3, setparking3] = React.useState(false);
  const [parking4, setparking4] = React.useState(false);
  const [parking5, setparking5] = React.useState(false);

  const [north, setnorth] = useState(false);
  const [east, seteast] = useState(false);
  const [west, setwest] = useState(false);
  const [south, setsouth] = useState(false);
  const [northeast, setnortheast] = useState(false);
  const [southeast, setsoutheast] = useState(false);
  const [northwest, setnorthwest] = useState(false);
  const [southwest, setsouthwest] = useState(false);

  const [resale, setresale] = useState(false);
  const [newproperty, setnewproperty] = useState(false);

  const [lessthanone, setlessthanone] = useState(false);
  const [lessthanthree, setlessthanthree] = useState(false);
  const [lessthanfive, setlessthanfive] = useState(false);
  const [lessthanten, setlessthanten] = useState(false);
  const [morethanten, setmorethanten] = useState(false);

  const [yes, setyes] = useState(false);
  const [no, setno] = useState(false);

  const [borewell, setborewell] = useState(false);
  const [corporation, setcorporation] = useState(false);

  const [datas, setdatas] = useState([]);
  const [datas1, setdatas1] = useState([]);
  const [datas2, setdatas2] = useState([]);
  const [smShow, setSmShow] = useState(false);
  const [smShow1, setSmShow1] = useState(false);
  const [smShow2, setSmShow2] = useState(false);
  const [smShow3, setSmShow3] = useState(false);
  const [smShow4, setSmShow4] = useState(false);
  const [smShow5, setSmShow5] = useState(false);
  const [smShow6, setSmShow6] = useState(false);

  const [button1, setbutton1] = useState(false);
  const [button2, setbutton2] = useState(false);
  const [button3, setbutton3] = useState(false);
  const [button4, setbutton4] = useState(false);
  const [button5, setbutton5] = useState(false);
  const [button6, setbutton6] = useState(false);
  const [button7, setbutton7] = useState(false);
  const [button8, setbutton8] = useState(false);
  const [button9, setbutton9] = useState(false);
  const [button10, setbutton10] = useState(false);
  const [lat, setlat] = useState("");
  const [lon, setlon] = useState("");
  // console.log(button1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Container>
            <Box>{children}</Box>
          </Container>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const location = useLocation();
  const { data } = location.state;
  console.log("data", data);
  Geocode.setApiKey("AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg");
  Geocode.setLanguage("en");
  Geocode.setLocationType("ROOFTOP");

  Geocode.fromAddress(data.locality[0].label).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      // console.log("lat, lng", lat, lng);
      setlat(lat);
      setlon(lng);
    },
    (error) => {
      console.error(error);
    }
  );
  // console.log("lat, lngscjsd", lat, lon);

  useEffect(() => {
    // if (!nearby) {
    //   postdata();
    // } else if (nearby) {
    //   postlatlongdata();
    // }
    if (lat && lon) {
      postlatlongdata();
    }
  }, [data, nearby, lat, lon]);

  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/searchPropertyDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        city: data.city,
        locality: data.locality,
      },
    };
    // console.log(data);
    let res = await axios(config);
    if (res.status == 200) {
      // console.log("xyz", res.data.propertyDetail);
      let propertydata = res.data.propertyDetail;
      propertydata = propertydata.filter((x) => {
        return (
          x.propertytypes[0]?.approved === "approved" &&
          x.propertytypes[0]?.expire_status === "false"
        );
      });
      setdatas(propertydata);
      setdatas2(propertydata);
    } else {
      console.log("error");
    }
  };

  const postlatlongdata = async () => {
    // console.log("nscjncj", lat, lon);
    let config = {
      url: "/propertyForOwner/searchlatlngPropertyDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        lat: lat,
        lng: lon,
      },
    };

    let res = await axios(config);
    if (res.status == 200) {
      console.log("xyz", res.data.propertyDetail);
      let propertydata = res.data.propertyDetail;
      propertydata = propertydata.filter((x) => {
        return (
          x.propertytypes[0]?.approved === "approved" &&
          x.propertytypes[0]?.expire_status === "false"
        );
      });
      setdatas(propertydata);
      setdatas2(propertydata);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    postfliter();
  }, [datas2]);

  useEffect(() => {
    postrentfliter();
  }, [datas2]);

  useEffect(() => {
    postplotfliter();
    postcommercialfliter();
  }, [datas2]);

  const postfliter = () => {
    if (data.propertyType === "New sale") {
      let newdata;
      if (data?.apartmentType) {
        newdata = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === data?.propertyType &&
            item?.propertydetails[0]?.bhkType === data?.apartmentType
        );
      } else {
        newdata = datas2.filter(
          (item) => item?.propertytypes[0]?.propertytype === data?.propertyType
        );
      }
      setdatas(newdata);
      setdatas1(newdata);
      // console.log("1");
    }
  };

  const propertyTypefliter = (property) => {
    if (data.propertyType === "New sale") {
      let newdata = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.propertydetails[0]?.apartmentType === property
      );
      setdatas(newdata);
    }
  };
  const postapartmentfliter = (apartmentType) => {
    if (data.propertyType === "New sale") {
      let newdata = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.propertydetails[0]?.bhkType ===
            (apartmentType === "1 BHK" &&
            item?.propertydetails[0]?.bhkType === "1 BHK"
              ? "1 BHK"
              : apartmentType)
      );
      // console.log(newdata);
      // console.log(datas2);
      // console.log(datas1);
      // console.log(datas);
      setdatas(newdata);
    } else if (data.fullhouse) {
      let newdata1 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.propertydetails[0]?.bhkType === apartmentType
      );
      // console.log(newdata1);
      setdatas(newdata1);
    } else if (data.flatmates) {
      let newdata1 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Flatmates" &&
          item?.propertydetails[0]?.bhkType === apartmentType
      );
      // console.log(newdata1);
      // console.log(datas2);
      // console.log(datas1);
      // console.log(datas);
      setdatas(newdata1);
    }
  };

  const furnishingtypefilter = (furnishingtype) => {
    if (data.flatmates) {
      let newdata1 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Flatmates" &&
          item?.rentaldetails[0]?.furnishing === furnishingtype
      );
      setdatas(newdata1);
    }
  };

  const furnishtypefilter = (furnishtype) => {
    if (data.fullhouse) {
      let newdata1 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.rentaldetails[0]?.furnishing === furnishtype
      );
      setdatas(newdata1);
    } else if (data.propertyType === "New sale") {
      let newdata1 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "New sale" &&
          item?.rentaldetails[0]?.furnishing === furnishtype
      );
      setdatas(newdata1);
    }
  };

  const preferredtenantsfilter = (preferredtenants) => {
    if (data.fullhouse) {
      let newdata1 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.rentaldetails[0]?.preferredTenants === preferredtenants
      );
      setdatas(newdata1);
    }
  };

  const roomtypefilter = (roomtype) => {
    if (data.flatmates) {
      if (roomtype !== "Both") {
        let newdata1 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === "Flatmates" &&
            item?.propertydetails[0]?.roomType === roomtype
        );
        setdatas(newdata1);
      } else {
        let newdata1 = datas2.filter(
          (item) => item?.propertytypes[0]?.propertytype === "Flatmates"
        );
        setdatas(newdata1);
      }
    }
  };

  const tenantTypefilter = (tenanttype) => {
    let newdata1;
    if (data.flatmates) {
      if (tenanttype !== "Both") {
        newdata1 = datas2.filter((item) => {
          return (
            item?.propertytypes[0]?.propertytype === "Flatmates" &&
            item?.propertydetails[0]?.tenantType === tenanttype
          );
        });
      } else {
        newdata1 = datas2.filter(
          (item) => item?.propertytypes[0]?.propertytype === "Flatmates"
        );
      }
      setdatas(newdata1);
    }
  };

  const postrentfliter = () => {
    if (data.fullhouse) {
      let newdata1;
      if (data?.apartmentType) {
        newdata1 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === "Rent" &&
            item?.propertydetails[0]?.bhkType === data?.apartmentType
        );
      } else {
        newdata1 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === "Rent" &&
            item?.propertytypes[0]?.property === "Residential"
        );
      }
      setdatas(newdata1);
    } else if (data.pghostel) {
      let newdata1 = datas2.filter(
        (item) => item?.propertytypes[0]?.propertytype === "PG/Hostel"
      );
      setdatas(newdata1);
    } else if (data.flatmates) {
      let newdata1 = datas2.filter(
        (item) => item?.propertytypes[0]?.propertytype === "Flatmates"
      );
      setdatas(newdata1);
    } else if (data?.propertyType === "Rent") {
      let newdata1 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.propertytypes[0]?.property !== "Commercial"
      );
      setdatas(newdata1);
    }
  };

  const pgfilter = (gender) => {
    if (data.pghostel) {
      if (gender !== "Both") {
        let newdata1 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === "PG/Hostel" &&
            item?.pgdetails[0]?.genderType === gender
        );
        setdatas(newdata1);
      } else {
        let newdata1 = datas2.filter(
          (item) => item?.propertytypes[0]?.propertytype === "PG/Hostel"
        );
        setdatas(newdata1);
      }
    }
  };

  const pgroomfilter = (room) => {
    let newdata1;
    if (data.pghostel) {
      if (room === "single") {
        newdata1 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === "PG/Hostel" &&
            item.propertydetails[0].singleRoom === "true"
        );
      } else if (room === "double") {
        newdata1 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === "PG/Hostel" &&
            item.propertydetails[0].doubleRoom === "true"
        );
      } else if (room === "three") {
        newdata1 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === "PG/Hostel" &&
            item.propertydetails[0].threeRoom === "true"
        );
      } else if (room === "four") {
        newdata1 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.propertytype === "PG/Hostel" &&
            item.propertydetails[0].fourRoom === "true"
        );
      }
      setdatas(newdata1);
    }
  };

  const postplotfliter = () => {
    if (data.propertyType === "Plots") {
      let newdata2 = datas2.filter(
        (item) => item?.propertytypes[0]?.propertytype === data?.propertyType
      );
      setdatas(newdata2);
    }
  };

  const ownerFilter = (owner) => {
    if (data.propertyType === "New sale") {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "New sale" &&
          item?.propertytypes[0]?.propertyowner === owner
      );
      setdatas(newdata2);
    } else if (data.fullhouse) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.propertytypes[0]?.propertyowner === owner
      );
      setdatas(newdata2);
    } else if (data.pghostel) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "PG/Hostel" &&
          item?.propertytypes[0]?.propertyowner === owner
      );
      setdatas(newdata2);
    } else if (data.flatmates) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Flatmates" &&
          item?.propertytypes[0]?.propertyowner === owner
      );
      setdatas(newdata2);
    } else if (data.propertyType === "Plots") {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Plots" &&
          item?.propertytypes[0]?.propertyowner === owner
      );
      setdatas(newdata2);
    } else if (data.buy) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Sale" &&
          item?.propertytypes[0]?.property === "Commercial" &&
          item?.propertydetails[0]?.propertyowner === owner
      );
      setdatas(newdata2);
    } else if (data.rent) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.propertytypes[0]?.property === "Commercial" &&
          item?.propertydetails[0]?.propertyowner === owner
      );
      setdatas(newdata2);
    }
  };

  const plotFacingFilter = (facing) => {
    if (data.propertyType === "Plots") {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Plots" &&
          item?.plotdetails[0]?.facing === facing
      );
      setdatas(newdata2);
    }
  };

  const FacingFilter = (facing) => {
    if (data.fullhouse) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.propertydetails[0]?.facing === facing
      );
      setdatas(newdata2);
    } else if (data.propertyType === "New sale") {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "New sale" &&
          item?.propertydetails[0]?.facing === facing
      );
      setdatas(newdata2);
    } else if (data.flatmates) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Flatmates" &&
          item?.propertydetails[0]?.facing === facing
      );
      setdatas(newdata2);
    }
  };

  const securityFilter = (security) => {
    if (data.fullhouse) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.property === "Residential" &&
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.amenitiesdetails[0]?.gatedSecurity === security
      );
      setdatas(newdata2);
    }
  };

  const waterFilter = (water) => {
    if (data.fullhouse) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.property === "Residential" &&
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.amenitiesdetails[0]?.waterSupply === water
      );
      setdatas(newdata2);
    }
  };

  const propertyStatusFilter = (propertyStatus) => {
    if (data.propertyType === "New sale") {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "New sale" &&
          item?.propertydetails[0]?.propertystatus === propertyStatus
      );
      setdatas(newdata2);
    }
  };

  const plotsaleTypeFilter = (salesType) => {
    if (data.propertyType === "Plots") {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === "Plots" &&
          item?.plotdetails[0]?.salesType === salesType
      );
      setdatas(newdata2);
    }
  };
  const commercialFurnishTypeFilter = (furnishtype) => {
    if (data.buy) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.property === data?.property &&
          item?.propertytypes[0]?.propertytype === "Sale" &&
          item?.propertydetails[0]?.furnishing === furnishtype &&
          item.propertydetails[0]?.propertyType === data?.propertyType
      );
      setdatas(newdata2);
      // setdatas1(newdata2);
    } else if (data.rent) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.property === "Commercial" &&
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.propertydetails[0]?.furnishing === furnishtype &&
          item.propertydetails[0]?.propertyType === data?.propertyType
      );
      setdatas(newdata2);
      // setdatas1(newdata2);
    }
  };

  console.log("datas2", data, datas2);
  const postcommercialfliter = () => {
    if (data.buy) {
      let newdata2;
      if (data?.property) {
        newdata2 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.property === data?.propertyType &&
            item?.propertytypes[0]?.propertytype === "Sale" &&
            item?.propertydetails[0].propertyType === data?.property
        );
      } else {
        newdata2 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.property === data?.propertyType &&
            item?.propertytypes[0]?.propertytype === "Sale"
        );
      }
      setdatas(newdata2);
      setdatas1(newdata2);
    } else if (data.rent) {
      let newdata2;
      if (data?.property) {
        newdata2 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.property === data?.propertyType &&
            item?.propertytypes[0]?.propertytype === "Rent" &&
            item?.propertydetails[0].propertyType === data?.property
        );
      } else {
        newdata2 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.property === data?.propertyType &&
            item?.propertytypes[0]?.propertytype === "Rent"
        );
      }
      // console.log(newdata2);
      setdatas(newdata2);
      setdatas1(newdata2);
    } else if (data?.propertyType === "Commercial") {
      let newdata2;
      newdata2 = datas2.filter(
        (item) => item?.propertytypes[0]?.property === data?.propertyType
      );
      setdatas(newdata2);
      setdatas1(newdata2);
    }
  };

  const commercialpropertyfilter = (property) => {
    if (data.buy) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.property === data?.propertyType &&
          item?.propertytypes[0]?.propertytype === "Sale" &&
          item?.propertydetails[0].propertyType === data?.property
      );
      console.log(newdata2, property);
      setdatas(newdata2);
      // setdatas1(newdata2);
    } else if (data.rent) {
      let newdata2 = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.property === data?.propertyType &&
          item?.propertytypes[0]?.propertytype === "Rent" &&
          item?.propertydetails[0].propertyType === data?.property
      );
      console.log(newdata2, property);
      setdatas(newdata2);
    }
  };
  // property Age
  const propertyageFilter = (propertyage) => {
    if (data.propertyType === "New sale") {
      let newdata = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.propertydetails[0]?.propertyAge === propertyage
      );
      console.log(newdata, propertyage);
      setdatas(newdata);
    } else if (data.propertyType === "Plots") {
      let newdata = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.plotdetails[0]?.propertyAge === propertyage
      );
      console.log(newdata, propertyage);
      setdatas(newdata);
    }
  };

  const parkingFilter = (parking) => {
    if (data.propertyType === "New sale") {
      let newdata = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.rentaldetails[0]?.parking === parking
      );
      console.log(newdata, parking);
      setdatas(newdata);
    }
  };

  const amenitiesFilter = (amenities) => {
    if (data.propertyType === "New sale" && amenities === "lift") {
      let newdata = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.amenitiesdetails[0]?.availableAmenities?.lift === "true"
      );
      console.log(newdata, amenities);
      setdatas(newdata);
    } else if (
      data.propertyType === "New sale" &&
      amenities === "swimmingpool"
    ) {
      let newdata = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.amenitiesdetails[0]?.availableAmenities?.swimmingpool === "true"
      );
      console.log(newdata, amenities);
      setdatas(newdata);
    } else if (data.propertyType === "New sale" && amenities === "wifi") {
      let newdata = datas2.filter(
        (item) =>
          item?.propertytypes[0]?.propertytype === data?.propertyType &&
          item?.amenitiesdetails[0]?.availableAmenities?.internetservices ===
            "true"
      );
      console.log(newdata, amenities);
      setdatas(newdata);
    }
  };

  const parkingTypeFilter = (parkingType) => {
    if (data.buy) {
      let newdata2;
      if (parkingType === "Both") {
        newdata2 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.property === data?.property &&
            item?.propertytypes[0]?.propertytype === "Sale" &&
            item?.amenitiesdetails[0].parking === "public And Reserved" &&
            item.propertydetails[0]?.propertyType === data?.propertyType
        );
      } else {
        newdata2 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.property === data?.property &&
            item?.propertytypes[0]?.propertytype === "Sale" &&
            item?.amenitiesdetails[0].parking === parkingType &&
            item.propertydetails[0]?.propertyType === data?.propertyType
        );
      }
      console.log(newdata2, parkingType);
      setdatas(newdata2);
      // setdatas1(newdata2);
    } else if (data.rent) {
      let newdata2;
      if (parkingType === "Both") {
        newdata2 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.property === data?.property &&
            item?.propertytypes[0]?.propertytype === "Rent" &&
            item?.amenitiesdetails[0].parking === "public And Reserved" &&
            item.propertydetails[0]?.propertyType === data?.propertyType
        );
      } else {
        newdata2 = datas2.filter(
          (item) =>
            item?.propertytypes[0]?.property === data?.property &&
            item?.propertytypes[0]?.propertytype === "Rent" &&
            item?.amenitiesdetails[0].parking === parkingType &&
            item.propertydetails[0]?.propertyType === data?.propertyType
        );
      }
      console.log(newdata2, parkingType);
      setdatas(newdata2);
    }
  };

  const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  };

  const followersMarks = [
    {
      value: 0,
      scaledValue: 1000,
      label: "1k",
    },
    {
      value: 25,
      scaledValue: 5000,
      label: "5k",
    },
    {
      value: 50,
      scaledValue: 10000,
      label: "10k",
    },
    {
      value: 75,
      scaledValue: 25000,
      label: "25k",
    },
    {
      value: 100,
      scaledValue: 50000,
      label: "50k",
    },
    {
      value: 125,
      scaledValue: 100000,
      label: "100k",
    },
    {
      value: 150,
      scaledValue: 250000,
      label: "250k",
    },
    {
      value: 175,
      scaledValue: 500000,
      label: "500k",
    },
    {
      value: 200,
      scaledValue: 100000000,
      label: "100M",
    },
  ];
  const scale = (value) => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = followersMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = followersMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };

  const [value5, setValue5] = React.useState([1, 20]);

  const handleChange5 = (event, newValue) => {
    console.log(newValue);
    filterprice(scale(newValue[0]), scale(newValue[1]));
    setValue5(newValue);
  };

  function refreshPage() {
    window.location.reload();
  }

  const filterprice = (s1, s2) => {
    console.log("scjcjsncjcj", data);
    if (datas.length === 0) {
      // setdata(data1);
      if (data?.buy && data?.property === "Commercial") {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.resaledetails[0]?.expectedPrice >= s1 &&
              item.resaledetails[0]?.expectedPrice <= s2 &&
              item.propertydetails[0]?.propertyType === data?.propertyType
          );
          console.log("newdata", newdata, 1);
          setdatas(newdata);
        }
      } else if (data?.rent && data?.property === "Commercial") {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.rentaldetails[0]?.expectedRent >= s1 &&
              item.rentaldetails[0]?.expectedRent <= s2 &&
              item.propertydetails[0]?.propertyType === data?.propertyType
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (data?.fullhouse) {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.rentaldetails[0]?.expectedRent >= s1 &&
              item.rentaldetails[0]?.expectedRent <= s2 &&
              item?.propertytypes[0].propertytype === "Rent" &&
              item?.propertytypes[0].property === "Residential"
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (data.flatmates) {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.rentaldetails[0]?.expectedRent >= s1 &&
              item.rentaldetails[0]?.expectedRent <= s2 &&
              item?.propertytypes[0].propertytype === "Flatmates"
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (!data?.pghostel && data.propertyType !== "Plots") {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.rentaldetails[0]?.expectedRent >= s1 &&
              item.rentaldetails[0]?.expectedRent <= s2 &&
              item?.propertytypes[0].propertytype === data?.propertyType
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (data?.pghostel) {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              (item.propertydetails[0].expectedRentsingleroom >= s1 &&
                item.propertydetails[0].expectedRentsingleroom <= s2) ||
              (item.propertydetails[0].expectedRentdoubleroom >= s1 &&
                item.propertydetails[0].expectedRentdoubleroom <= s2) ||
              (item.propertydetails[0].expectedRentthreeroom >= s1 &&
                item.propertydetails[0].expectedRentthreeroom <= s2) ||
              (item.propertydetails[0].expectedRentfourroom >= s1 &&
                item.propertydetails[0].expectedRentfourroom <= s2)
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (data.propertyType === "Plots") {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.plotdetails[0]?.plotPrice >= s1 &&
              item.plotdetails[0]?.plotPrice <= s2
          );
          console.log(newdata);
          setdatas(newdata);
        }
      }
    } else {
      if (data?.buy && data?.property === "Commercial") {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.resaledetails[0]?.expectedPrice >= s1 &&
              item.resaledetails[0]?.expectedPrice <= s2 &&
              item.propertydetails[0]?.propertyType === data?.propertyType
          );
          console.log(newdata, 1);
          setdatas(newdata);
        }
      } else if (data?.rent && data?.property === "Commercial") {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.rentaldetails[0]?.expectedRent >= s1 &&
              item.rentaldetails[0]?.expectedRent <= s2 &&
              item.propertydetails[0]?.propertyType === data?.propertyType
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (data?.pghostel) {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              (item.propertydetails[0]?.expectedRentsingleroom >= s1 &&
                item.propertydetails[0]?.expectedRentsingleroom <= s2) ||
              (item.propertydetails[0]?.expectedRentdoubleroom >= s1 &&
                item.propertydetails[0]?.expectedRentdoubleroom <= s2) ||
              (item.propertydetails[0]?.expectedRentthreeroom >= s1 &&
                item.propertydetails[0]?.expectedRentthreeroom <= s2) ||
              (item.propertydetails[0]?.expectedRentfourroom >= s1 &&
                item.propertydetails[0]?.expectedRentfourroom <= s2)
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (data.propertyType === "Plots") {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.plotdetails[0]?.plotPrice >= s1 &&
              item.plotdetails[0]?.plotPrice <= s2
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (data.fullhouse) {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.rentaldetails[0]?.expectedRent >= s1 &&
              item.rentaldetails[0]?.expectedRent <= s2 &&
              item?.propertytypes[0].propertytype === "Rent" &&
              item?.propertytypes[0].property === "Residential"
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (data.flatmates) {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.rentaldetails[0]?.expectedRent >= s1 &&
              item.rentaldetails[0]?.expectedRent <= s2 &&
              item?.propertytypes[0].propertytype === "Flatmates"
          );
          console.log(newdata);
          setdatas(newdata);
        }
      } else if (!data?.pghostel && data.propertyType !== "Plots") {
        if (s1 && s2) {
          let newdata = datas2.filter(
            (item) =>
              item.rentaldetails[0]?.expectedRent >= s1 &&
              item.rentaldetails[0]?.expectedRent <= s2 &&
              item?.propertytypes[0].propertytype === data?.propertyType
          );
          console.log(newdata);
          setdatas(newdata);
        }
      }
    }
  };

  // Built Up Area slider
  const numFormatter1 = (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + ""; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + ""; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  };

  const followersMarks1 = [
    {
      value: 0,
      scaledValue: 0,
      label: "0",
    },
    {
      value: 25,
      scaledValue: 1000,
      label: "1",
    },
    {
      value: 50,
      scaledValue: 2000,
      label: "2",
    },
    {
      value: 75,
      scaledValue: 3000,
      label: "3",
    },
    {
      value: 100,
      scaledValue: 4000,
      label: "4",
    },
    {
      value: 125,
      scaledValue: 5000,
      label: "5",
    },
    {
      value: 150,
      scaledValue: 6000,
      label: "6",
    },
    {
      value: 175,
      scaledValue: 7000,
      label: "7",
    },
    {
      value: 200,
      scaledValue: 10000,
      label: "10",
    },
  ];
  const scale1 = (value) => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = followersMarks1[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = followersMarks1[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };

  const [value6, setValue6] = React.useState([1, 20]);

  const handleChange6 = (event, newValue) => {
    console.log(newValue);
    areaFilter(scale1(newValue[0]), scale1(newValue[1]));
    setValue6(newValue);
  };

  const areaFilter = (m1, m2) => {
    if (data?.fullhouse) {
      if (m1 && m2) {
        let newdata = datas2.filter(
          (item) =>
            item.propertydetails[0]?.propertySize >= m1 &&
            item.propertydetails[0]?.propertySize <= m2
        );
        console.log(newdata, m1, m2);
        setdatas(newdata);
      }
    } else if (data.propertyType === "New sale") {
      if (m1 && m2) {
        let newdata = datas2.filter(
          (item) =>
            item.propertydetails[0]?.builtUpArea >= m1 &&
            item.propertydetails[0]?.builtUpArea <= m2 &&
            item?.propertytypes[0].propertytype === "New sale"
        );
        console.log(newdata, m1, m2);
        setdatas(newdata);
      }
    } else if (data.propertyType === "Plots") {
      if (m1 && m2) {
        let newdata = datas2.filter(
          (item) =>
            item.plotdetails[0]?.totalPlotSize >= m1 &&
            item.plotdetails[0]?.totalPlotSize <= m2 &&
            item?.propertytypes[0].propertytype === "Plots"
        );
        console.log(newdata, m1, m2);
        setdatas(newdata);
      }
    }
  };

  // java script for filter

  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "nav-filter") {
      x.className += " responsive";
    } else {
      x.className = "nav-filter";
    }
  }
  // end here

  const text = `HOUSELIV`;
  // const beginAlarm = function() { console.log('start alarm'); };
  const options = {
    chunkWidth: 500,
    chunkHeight: 125,
    textAlign: "center",
    textBaseline: "bottom",
    globalAlpha: 1,
    font: "16px arial",
    rotateAngle: 0,
    fillStyle: "#f8f8f8",
    backgroundRepeat: "no-repeat",
  };

  // const count = datas.filter(item => item.i).length;
  // const count =   alluser.length
  console.log("datas", datas);
  return (
    <div>
      {/* for desktop responsive */}
      <div className="forbigscreen" style={{ backgroundColor: "#e1e0de" }}>
        <div className="container-fuild mt-5 pt-5 ">
          <div className="row mt-5 me-0">
            <Col
              md={4}
              className="col-md-4 nav-filter col-xs-4 forbigscreen"
              // style={{ marginTop: "60px" }}
            >
              <Card className="bar" style={{ marginLeft: "5px" }}>
                <Box sx={{ bgcolor: "background.paper", width: "430px" }}>
                  <AppBar position="static">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="secondary"
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab label=" Filters" {...a11yProps(0)} />
                      <Tab label="more filters" {...a11yProps(1)} />
                      <a
                        href="javascript:void(0);"
                        class="icon"
                        onclick={myFunction}
                      >
                        <i className="fa fa-bars"></i>
                      </a>
                    </Tabs>
                  </AppBar>
                  <SwipeableViews
                    index={value}
                    onChangeIndex={handleChangeIndex}
                  >
                    <TabPanel value={value} index={0}>
                      <div style={{ marginLeft: "200px" }}>
                        {/* <Button onClick={refreshPage}>
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                          {""} Reload
                        </Button> */}
                      </div>
                      <div>
                        {["checkbox"].map((type) => (
                          <span key={`inline-${type}`} className="mb-3">
                            <Form.Check
                              inline
                              label="Include nearby properties"
                              name="group2"
                              type={type}
                              id={`inline-${type}-1`}
                              style={{ backgroundcolor: "#009587" }}
                              checked={nearby}
                              onChange={() => {
                                setnearby(true);
                              }}
                            />
                          </span>
                        ))}{" "}
                      </div>
                      {data.pghostel ? (
                        // pg
                        <Row>
                          <div>
                            <p className="mt-3">
                              <b>PG For</b>
                            </p>

                            {["radio"].map((type) => (
                              <div
                                key={`inline-${type}`}
                                className="mb-3"
                                style={{ color: "#1b2435" }}
                              >
                                <Form.Check
                                  inline
                                  label="Male"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={male}
                                  onChange={() => {
                                    setmale(!male);
                                    setothers(false);
                                    pgfilter("Male");
                                    setfemale(false);
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="Female"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-2`}
                                  checked={female}
                                  onChange={() => {
                                    setfemale(!female);
                                    pgfilter("Female");
                                    setmale(false);
                                    setothers(false);
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="Both"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-3`}
                                  checked={others}
                                  onChange={() => {
                                    setothers(!others);
                                    pgfilter("Both");
                                    setmale(false);
                                    setfemale(false);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Room Type</b>
                            </p>
                            <Col>
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Single Room"
                                    name="group2"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={singleRoom}
                                    onChange={() => {
                                      setsingleRoom(!singleRoom);
                                      setdoubleRoom(false);
                                      setthreeRoom(false);
                                      setfourRoom(false);
                                      pgroomfilter("single");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Double Room"
                                    name="group2"
                                    type={type}
                                    id={`inline-${type}-2`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={doubleRoom}
                                    onChange={() => {
                                      setsingleRoom(false);
                                      // setdoubleRoom(false);
                                      setthreeRoom(false);
                                      setfourRoom(false);
                                      setdoubleRoom(!doubleRoom);
                                      pgroomfilter("double");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                            </Col>
                            <Col className="mt-2">
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Triple Room"
                                    name="group2"
                                    type={type}
                                    id={`inline-${type}-3`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={threeRoom}
                                    onChange={() => {
                                      setsingleRoom(false);
                                      setdoubleRoom(false);
                                      // setthreeRoom(false);
                                      setfourRoom(false);
                                      setthreeRoom(!threeRoom);
                                      pgroomfilter("three");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Four Room"
                                    name="group2"
                                    type={type}
                                    id={`inline-${type}-4`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={fourRoom}
                                    onChange={() => {
                                      setsingleRoom(false);
                                      setdoubleRoom(false);
                                      setthreeRoom(false);
                                      // setfourRoom(false);
                                      setfourRoom(!fourRoom);
                                      pgroomfilter("four");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                            </Col>
                          </div>
                          <div>
                            {/* 
                          <Box sx={{ width: 300 }}>
                            <Slider
                              getAriaLabel={() => "Temperature range"}
                              value={values}
                              onChange={handleChange1}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                            />
                          </Box> */}
                            <div>
                              <Typography
                                id="non-linear-slider"
                                className="mt-3"
                                gutterBottom
                              >
                                <b> Rent Range</b> (per month)
                              </Typography>
                              <Slider
                                style={{ maxWidth: "80%" }}
                                value={value5}
                                min={0}
                                step={1}
                                max={200}
                                valueLabelFormat={1}
                                marks={followersMarks}
                                scale={scale}
                                onChange={handleChange5}
                                valueLabelDisplay="auto"
                                aria-labelledby="non-linear-slider"
                              />
                              <Typography>
                                Price between {scale(value5[0])} to{" "}
                                {scale(value5[1])}{" "}
                              </Typography>
                            </div>
                          </div>
                        </Row>
                      ) : data.propertyType === "New sale" ? (
                        // resale or
                        <Row>
                          <div>
                            <p className="mt-3">
                              <b>Property Type</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Apartment"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-5`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={apartmentType}
                                  onChange={(e) => {
                                    setapartmentType(!apartmentType);
                                    setapartmentType1(false);
                                    setapartmentType2(false);
                                    setapartmentType3(false);
                                    propertyTypefliter("Apartment");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Independent House/Villa"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-6`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={apartmentType1}
                                  onChange={(e) => {
                                    setapartmentType1(!apartmentType1);
                                    setapartmentType(false);
                                    setapartmentType2(false);
                                    setapartmentType3(false);
                                    propertyTypefliter(
                                      "Independent House/Villa"
                                    );
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Gated Community Villa"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-7`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={apartmentType2}
                                  onChange={(e) => {
                                    setapartmentType2(!apartmentType2);
                                    setapartmentType1(false);
                                    setapartmentType(false);
                                    setapartmentType3(false);
                                    propertyTypefliter("Gated Community Villa");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-5">
                                <Form.Check
                                  inline
                                  label="Standalone Building"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-8`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={apartmentType3}
                                  onChange={(e) => {
                                    setapartmentType3(!apartmentType3);
                                    setapartmentType1(false);
                                    setapartmentType2(false);
                                    setapartmentType(false);
                                    propertyTypefliter("Standalone Building");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSmShow1(false);
                            }}
                          >
                            <p style={{ marginTop: "5px" }}>
                              <b>BHK Type</b>
                            </p>
                            <Button
                              variant="outlined"
                              color={button1 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(true);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("1 BHK");
                              }}
                            >
                              1 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button2 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(true);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("2 BHK");
                              }}
                            >
                              2 BHk
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button3 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(true);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("3 BHK");
                              }}
                            >
                              3 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button4 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(true);
                                setbutton5(false);
                                postapartmentfliter("4 BHK");
                              }}
                            >
                              4 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button5 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(true);
                                postapartmentfliter("4+ BHK");
                              }}
                            >
                              4+ BHK
                            </Button>{" "}
                          </div>
                          <div>
                            <Typography
                              id="non-linear-slider"
                              className="mt-3"
                              gutterBottom
                            >
                              <b> Budget</b>
                            </Typography>
                            <Slider
                              style={{ width: "80%" }}
                              value={value5}
                              min={0}
                              step={1}
                              max={200}
                              valueLabelFormat={numFormatter}
                              marks={followersMarks}
                              scale={scale}
                              onChange={handleChange5}
                              valueLabelDisplay="auto"
                              aria-labelledby="non-linear-slider"
                            />
                            <Typography>
                              Price between {scale(value5[0])} to{" "}
                              {scale(value5[1])}{" "}
                            </Typography>
                          </div>
                          <div>
                            <p style={{ marginTop: "5px" }}>
                              <b>Property Status</b>
                            </p>
                            {["radio"].map((type) => (
                              <div
                                key={`inline-${type}`}
                                className="mb-3"
                                style={{ color: "#1b2435" }}
                              >
                                <Form.Check
                                  inline
                                  label="Under Construction"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={under}
                                  onChange={() => {
                                    setunder(!under);
                                    setready(false);
                                    propertyStatusFilter("Under Construction");
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="Ready"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={ready}
                                  onChange={() => {
                                    setunder(false);
                                    setready(!ready);
                                    propertyStatusFilter("Ready To Move");
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="mt-3">
                            <p className="mt-3">
                              <b>Posted By</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Owner"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={owner}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setowner(!owner);
                                    setdeveloper(false);
                                    setagent(false);
                                    ownerFilter("Owner");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Agent"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={agent}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setowner(false);
                                    setdeveloper(false);
                                    setagent(!agent);
                                    ownerFilter("Agent");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Developer"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={developer}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setowner(false);
                                    setdeveloper(!developer);
                                    setagent(false);
                                    ownerFilter("Developer");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                          <div className="mt-3">
                            <p className="mt-3">
                              <b>Parking Available</b>
                            </p>
                            <Button
                              variant="outlined"
                              color={button6 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton6(true);
                                setbutton7(false);
                                parkingFilter("Bike");
                              }}
                            >
                              2 Wheeler
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button7 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton7(true);
                                setbutton6(false);
                                parkingFilter("Car");
                              }}
                            >
                              4 Wheeler
                            </Button>{" "}
                          </div>
                          <div className="mt-3">
                            <p className="mt-3">
                              <b>Amenities</b>
                            </p>
                            <Button
                              variant="outlined"
                              color={button8 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton8(true);
                                setbutton9(false);
                                setbutton10(false);
                                amenitiesFilter("lift");
                              }}
                            >
                              Lift
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button9 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton9(true);
                                setbutton8(false);
                                setbutton10(false);
                                amenitiesFilter("swimmingpool");
                              }}
                            >
                              Swimming Pool
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button10 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton9(false);
                                setbutton8(false);
                                setbutton10(true);
                                amenitiesFilter("wifi");
                              }}
                            >
                              Wifi
                            </Button>{" "}
                          </div>
                        </Row>
                      ) : data.flatmates ? (
                        <Row>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSmShow1(false);
                            }}
                          >
                            <p style={{ marginTop: "5px" }}>
                              <b>BHK Type</b>
                            </p>
                            <Button
                              variant="outlined"
                              color={button1 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(true);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("1 BHK");
                              }}
                            >
                              1 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button2 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(true);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("2 BHK");
                              }}
                            >
                              2 BHk
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button3 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(true);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("3 BHK");
                              }}
                            >
                              3 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button4 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(true);
                                setbutton5(false);
                                postapartmentfliter("4 BHK");
                              }}
                            >
                              4 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button5 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(true);
                                postapartmentfliter("4+ BHK");
                              }}
                            >
                              4+ BHK
                            </Button>{" "}
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Room Type</b>
                            </p>
                            <Col>
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Shared Room"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-9`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={sharedRoom}
                                    onChange={() => {
                                      setsharedRoom(!sharedRoom);
                                      setsingleRoom(false);
                                      setboth(false);
                                      roomtypefilter("Shared Room");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Single Room"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-10`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={singleRoom}
                                    onChange={() => {
                                      setsharedRoom(false);
                                      setboth(false);
                                      setsingleRoom(!singleRoom);
                                      roomtypefilter("Single Room");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Both"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-11`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={both}
                                    onChange={() => {
                                      setsharedRoom(false);
                                      setsingleRoom(false);
                                      setboth(!both);
                                      roomtypefilter("Both");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                            </Col>
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Tenant Type</b>
                            </p>

                            {["radio"].map((type) => (
                              <div
                                key={`inline-${type}`}
                                className="mb-3"
                                style={{ color: "#1b2435" }}
                              >
                                <Form.Check
                                  inline
                                  label="Male"
                                  name="group2"
                                  type={type}
                                  id={`inline-${type}-4`}
                                  checked={male}
                                  onChange={() => {
                                    setmale(!male);
                                    setfemale(false);
                                    setothers1(false);
                                    tenantTypefilter("Male");
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="Female"
                                  name="group2"
                                  type={type}
                                  id={`inline-${type}-5`}
                                  checked={female}
                                  onChange={() => {
                                    setfemale(!female);
                                    setmale(false);
                                    setothers1(false);
                                    tenantTypefilter("Female");
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="Both"
                                  name="group2"
                                  type={type}
                                  id={`inline-${type}-6`}
                                  checked={others1}
                                  onChange={() => {
                                    setothers1(!others1);
                                    tenantTypefilter("Both");
                                    setmale(false);
                                    setfemale(false);
                                  }}
                                />
                              </div>
                            ))}
                          </div>

                          <div>
                            <Typography
                              id="non-linear-slider"
                              className="mt-3"
                              gutterBottom
                            >
                              <p>
                                <b> Rent Range</b>(per month)
                              </p>
                            </Typography>
                            <Slider
                              style={{ maxWidth: "80%" }}
                              value={value5}
                              min={0}
                              step={1}
                              max={200}
                              valueLabelFormat={numFormatter}
                              marks={followersMarks}
                              scale={scale}
                              onChange={handleChange5}
                              valueLabelDisplay="auto"
                              aria-labelledby="non-linear-slider"
                            />
                            <Typography>
                              Price between {scale(value5[0])} to{" "}
                              {scale(value5[1])}{" "}
                            </Typography>
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Furnishing</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Full"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-12`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing}
                                  onChange={(e) => {
                                    setfurnishing(!furnishing);
                                    setfurnishing1(false);
                                    setfurnishing8(false);
                                    furnishingtypefilter("Fully-furnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Semi"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-13`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing1}
                                  onChange={(e) => {
                                    setfurnishing(false);
                                    setfurnishing1(!furnishing1);
                                    setfurnishing8(false);
                                    furnishingtypefilter("Semi-furnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Unfurnishing"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-13`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing8}
                                  onChange={(e) => {
                                    setfurnishing(false);
                                    setfurnishing1(false);
                                    setfurnishing8(!furnishing8);
                                    furnishingtypefilter("Unfurnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                        </Row>
                      ) : data.buy || data.rent ? (
                        // commercial start here---------->
                        <Row>
                          <div>
                            <p className="mt-3">
                              <b>Property Type</b>
                            </p>
                            <Row>
                              <Col>
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Office Space"
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-15`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType}
                                      onChange={() => {
                                        setpropertyType(!propertyType);
                                        setpropertyType1(false);
                                        setpropertyType2(false);
                                        setpropertyType3(false);
                                        setpropertyType4(false);
                                        setpropertyType5(false);
                                        setpropertyType6(false);
                                        setpropertyType7(false);
                                        setpropertyType8(false);
                                        commercialpropertyfilter(
                                          "Office Space"
                                        );
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Industrial Building"
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-16`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType1}
                                      onChange={() => {
                                        setpropertyType1(!propertyType1);
                                        setpropertyType(false);
                                        setpropertyType2(false);
                                        setpropertyType3(false);
                                        setpropertyType4(false);
                                        setpropertyType5(false);
                                        setpropertyType6(false);
                                        setpropertyType7(false);
                                        setpropertyType8(false);
                                        commercialpropertyfilter(
                                          "Industrial Building"
                                        );
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                              </Col>
                              <Col>
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Showroom"
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-17`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType2}
                                      onChange={() => {
                                        setpropertyType2(!propertyType2);
                                        setpropertyType1(false);
                                        setpropertyType(false);
                                        setpropertyType3(false);
                                        setpropertyType4(false);
                                        setpropertyType5(false);
                                        setpropertyType6(false);
                                        setpropertyType7(false);
                                        setpropertyType8(false);
                                        commercialpropertyfilter("Showroom");
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Shop"
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-18`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType3}
                                      onChange={() => {
                                        setpropertyType3(!propertyType3);
                                        setpropertyType1(false);
                                        setpropertyType2(false);
                                        setpropertyType(false);
                                        setpropertyType4(false);
                                        setpropertyType5(false);
                                        setpropertyType6(false);
                                        setpropertyType7(false);
                                        setpropertyType8(false);
                                        commercialpropertyfilter("Shop");
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                              </Col>
                              <Col>
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Other business "
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-19`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType4}
                                      onChange={() => {
                                        setpropertyType4(!propertyType4);
                                        setpropertyType1(false);
                                        setpropertyType2(false);
                                        setpropertyType3(false);
                                        setpropertyType(false);
                                        setpropertyType5(false);
                                        setpropertyType6(false);
                                        setpropertyType7(false);
                                        setpropertyType8(false);
                                        commercialpropertyfilter(
                                          "Other business"
                                        );
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Industrial Shed"
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-20`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType5}
                                      onChange={() => {
                                        setpropertyType5(!propertyType5);
                                        setpropertyType1(false);
                                        setpropertyType2(false);
                                        setpropertyType3(false);
                                        setpropertyType4(false);
                                        setpropertyType(false);
                                        setpropertyType6(false);
                                        setpropertyType7(false);
                                        setpropertyType8(false);
                                        commercialpropertyfilter(
                                          "Industrial Shed"
                                        );
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                              </Col>
                              <Col>
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Co-Working"
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-1`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType6}
                                      onChange={() => {
                                        setpropertyType6(!propertyType6);
                                        setpropertyType1(false);
                                        setpropertyType2(false);
                                        setpropertyType3(false);
                                        setpropertyType4(false);
                                        setpropertyType5(false);
                                        setpropertyType(false);
                                        setpropertyType7(false);
                                        setpropertyType8(false);
                                        commercialpropertyfilter("Co-Working");
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Restaurant/Cafe"
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-1`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType7}
                                      onChange={() => {
                                        setpropertyType7(!propertyType7);
                                        setpropertyType1(false);
                                        setpropertyType2(false);
                                        setpropertyType3(false);
                                        setpropertyType4(false);
                                        setpropertyType5(false);
                                        setpropertyType6(false);
                                        setpropertyType(false);
                                        setpropertyType8(false);
                                        commercialpropertyfilter(
                                          "Restaurant/Cafe"
                                        );
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                              </Col>
                              <Col>
                                {["checkbox"].map((type) => (
                                  <span key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                      inline
                                      label="Godown/Warehouse"
                                      name="group3"
                                      type={type}
                                      id={`inline-${type}-1`}
                                      style={{ backgroundcolor: "#009587" }}
                                      checked={propertyType8}
                                      onChange={() => {
                                        setpropertyType8(!propertyType8);
                                        setpropertyType1(false);
                                        setpropertyType2(false);
                                        setpropertyType3(false);
                                        setpropertyType4(false);
                                        setpropertyType5(false);
                                        setpropertyType6(false);
                                        setpropertyType7(false);
                                        setpropertyType(false);
                                        commercialpropertyfilter(
                                          "Godown/Warehouse"
                                        );
                                      }}
                                    />
                                  </span>
                                ))}{" "}
                              </Col>
                            </Row>
                          </div>

                          <div>
                            <p className="mt-3">
                              <b>Parking</b>
                            </p>
                            <Col>
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Public"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={parking1}
                                    onChange={(e) => {
                                      setparking1(!parking1);
                                      setparking2(false);
                                      setparking3(false);
                                      parkingTypeFilter("Public");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Reserved"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    checked={parking2}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setparking2(!parking2);
                                      setparking1(false);
                                      setparking3(false);
                                      parkingTypeFilter("Reserved");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Both"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    checked={parking3}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setparking3(!parking3);
                                      setparking2(false);
                                      setparking1(false);
                                      parkingTypeFilter("Public And Reserved");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                            </Col>
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Furnishing</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Full"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing4}
                                  onChange={(e) => {
                                    setfurnishing4(!furnishing4);
                                    setfurnishing5(false);
                                    setfurnishing6(false);
                                    commercialFurnishTypeFilter(
                                      "Fully Furnished"
                                    );
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Semi"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing5}
                                  onChange={(e) => {
                                    setfurnishing5(!furnishing5);
                                    setfurnishing4(false);
                                    setfurnishing6(false);
                                    commercialFurnishTypeFilter(
                                      "Semi Furnished"
                                    );
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Unfurnishing"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing6}
                                  onChange={(e) => {
                                    setfurnishing6(!furnishing6);
                                    setfurnishing4(false);
                                    setfurnishing5(false);
                                    commercialFurnishTypeFilter("Unfurnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                          <div>
                            <Typography
                              id="non-linear-slider"
                              className="mt-3"
                              gutterBottom
                            >
                              <b>Budget </b>
                            </Typography>
                            <Slider
                              style={{ maxWidth: "80%" }}
                              value={value5}
                              min={0}
                              step={1}
                              max={200}
                              valueLabelFormat={numFormatter}
                              marks={followersMarks}
                              scale={scale}
                              onChange={handleChange5}
                              valueLabelDisplay="auto"
                              aria-labelledby="non-linear-slider"
                            />
                            <Typography>
                              Price between {scale(value5[0])} to{" "}
                              {scale(value5[1])}{" "}
                            </Typography>
                          </div>
                        </Row>
                      ) : data.fullhouse ? (
                        <Row>
                          <div
                            className="pl-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSmShow1(false);
                            }}
                          >
                            <p style={{}}>
                              <b>BHK Type</b>
                            </p>
                            <Button
                              variant="outlined"
                              color={button1 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(true);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("1 BHK");
                              }}
                            >
                              1 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button2 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(true);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("2 BHK");
                              }}
                            >
                              2 BHk
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button3 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(true);
                                setbutton4(false);
                                setbutton5(false);
                                postapartmentfliter("3 BHK");
                              }}
                            >
                              3 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button4 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(true);
                                setbutton5(false);
                                postapartmentfliter("4 BHK");
                              }}
                            >
                              4 BHK
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color={button5 ? "secondary" : "warning"}
                              onClick={() => {
                                setbutton1(false);
                                setbutton2(false);
                                setbutton3(false);
                                setbutton4(false);
                                setbutton5(true);
                                postapartmentfliter("4+ BHK");
                              }}
                            >
                              4+ BHK
                            </Button>{" "}
                          </div>

                          <div>
                            <p className="mt-3">
                              <b>Preferred Tenants</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Family"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={preferredTenants}
                                  onChange={(e) => {
                                    setpreferredTenants(!preferredTenants);
                                    setpreferredTenants1(false);
                                    setpreferredTenants2(false);
                                    preferredtenantsfilter("Family");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Bachelors"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={preferredTenants1}
                                  onChange={(e) => {
                                    setpreferredTenants(false);
                                    setpreferredTenants2(false);
                                    setpreferredTenants1(!preferredTenants1);
                                    preferredtenantsfilter("Bachelors");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Company"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={preferredTenants2}
                                  onChange={(e) => {
                                    setpreferredTenants1(false);
                                    setpreferredTenants(false);
                                    setpreferredTenants2(!preferredTenants2);
                                    preferredtenantsfilter("Company");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Furnishing</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Full"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-12`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing2}
                                  onChange={(e) => {
                                    setfurnishing2(!furnishing2);
                                    setfurnishing3(false);
                                    setfurnishing7(false);
                                    furnishtypefilter("Fully-furnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Semi"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-13`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing3}
                                  onChange={(e) => {
                                    setfurnishing2(false);
                                    setfurnishing7(false);
                                    setfurnishing3(!furnishing3);
                                    furnishtypefilter("Semi-furnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Unfurnishing"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-13`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing7}
                                  onChange={(e) => {
                                    setfurnishing2(false);
                                    setfurnishing3(false);
                                    setfurnishing7(!furnishing7);
                                    furnishtypefilter("Unfurnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                          <div>
                            <Typography
                              id="non-linear-slider"
                              className="mt-3"
                              gutterBottom
                            >
                              <b>Budget</b>
                            </Typography>
                            <Slider
                              style={{ maxWidth: "80%" }}
                              value={value5}
                              min={0}
                              step={1}
                              max={200}
                              valueLabelFormat={numFormatter}
                              marks={followersMarks}
                              scale={scale}
                              onChange={handleChange5}
                              valueLabelDisplay="auto"
                              aria-labelledby="non-linear-slider"
                            />
                            <Typography>
                              Price between {scale(value5[0])} to{" "}
                              {scale(value5[1])}{" "}
                            </Typography>
                          </div>
                        </Row>
                      ) : (
                        <>
                          {/*commercial  plot */}
                          <Row>
                            <div>
                              <p className="mt-3">
                                <b>Facing</b>
                              </p>
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="North"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={north}
                                    onChange={(e) => {
                                      setnorth(!north);
                                      seteast(false);
                                      setwest(false);
                                      setsouth(false);
                                      setnortheast(false);
                                      setsoutheast(false);
                                      setnorthwest(false);
                                      setsouthwest(false);
                                      plotFacingFilter("North");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="East"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    style={{ backgroundcolor: "#009587" }}
                                    checked={east}
                                    onChange={(e) => {
                                      setnorth(false);
                                      seteast(!east);
                                      setwest(false);
                                      setsouth(false);
                                      setnortheast(false);
                                      setsoutheast(false);
                                      setnorthwest(false);
                                      setsouthwest(false);
                                      plotFacingFilter("East");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="West"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    checked={west}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setnorth(false);
                                      seteast(false);
                                      setwest(!west);
                                      setsouth(false);
                                      setnortheast(false);
                                      setsoutheast(false);
                                      setnorthwest(false);
                                      setsouthwest(false);
                                      plotFacingFilter("West");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="South"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    checked={south}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setnorth(false);
                                      seteast(false);
                                      setwest(false);
                                      setsouth(!south);
                                      setnortheast(false);
                                      setsoutheast(false);
                                      setnorthwest(false);
                                      setsouthwest(false);
                                      plotFacingFilter("South");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="North-East"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    checked={northeast}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setnorth(false);
                                      seteast(false);
                                      setwest(false);
                                      setsouth(false);
                                      setnortheast(!northeast);
                                      setsoutheast(false);
                                      setnorthwest(false);
                                      setsouthwest(false);
                                      plotFacingFilter("North-East");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="South-West"
                                    name="group1"
                                    type={type}
                                    checked={southwest}
                                    id={`inline-${type}-1`}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setnorth(false);
                                      seteast(false);
                                      setwest(false);
                                      setsouth(false);
                                      setnortheast(false);
                                      setsoutheast(false);
                                      setnorthwest(false);
                                      setsouthwest(!southwest);
                                      plotFacingFilter("South-West");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="North-West"
                                    name="group1"
                                    type={type}
                                    checked={northwest}
                                    id={`inline-${type}-1`}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setnorth(false);
                                      seteast(false);
                                      setwest(false);
                                      setsouth(false);
                                      setnortheast(false);
                                      setsoutheast(false);
                                      setnorthwest(!northwest);
                                      setsouthwest(false);
                                      plotFacingFilter("North-West");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="South-East"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    checked={southeast}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setnorth(false);
                                      seteast(false);
                                      setwest(false);
                                      setsouth(false);
                                      setnortheast(false);
                                      setsoutheast(!southeast);
                                      setnorthwest(false);
                                      setsouthwest(false);
                                      plotFacingFilter("South-East");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                            </div>
                            <div>
                              <Typography
                                id="non-linear-slider"
                                className="mt-3"
                                gutterBottom
                              >
                                <p>
                                  <b>Budget</b>
                                </p>
                              </Typography>
                              <Slider
                                style={{ maxWidth: "80%" }}
                                value={value5}
                                min={0}
                                step={1}
                                max={200}
                                valueLabelFormat={numFormatter}
                                marks={followersMarks}
                                scale={scale}
                                onChange={handleChange5}
                                valueLabelDisplay="auto"
                                aria-labelledby="non-linear-slider"
                              />
                              <Typography>
                                Price between {scale(value5[0])} to{" "}
                                {scale(value5[1])}{" "}
                              </Typography>
                            </div>

                            <div>
                              <p className="mt-3">
                                <b>Sales Type</b>
                              </p>
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Ready for registration"
                                    name="group1"
                                    type={type}
                                    checked={newproperty}
                                    id={`inline-${type}-1`}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setnewproperty(!newproperty);
                                      setresale(false);
                                      plotsaleTypeFilter("New Property");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                              {["checkbox"].map((type) => (
                                <span key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Under development"
                                    name="group1"
                                    checked={resale}
                                    type={type}
                                    id={`inline-${type}-1`}
                                    style={{ backgroundcolor: "#009587" }}
                                    onChange={(e) => {
                                      setnewproperty(false);
                                      setresale(!resale);
                                      plotsaleTypeFilter("New sale");
                                    }}
                                  />
                                </span>
                              ))}{" "}
                            </div>
                          </Row>
                        </>
                      )}
                    </TabPanel>

                    {/* ------->  premium filter <------- */}
                    <TabPanel value={value} index={1}>
                      {/* {data.propertyType === "New sale" ||
                      data.propertyType === "fullhouse" ? (
                        <div style={{ marginLeft: "200px" }}>
                          <Button onClick={refreshPage()}>
                            <i className="fa fa-refresh" aria-hidden="true">
                              {" "}
                            </i>
                            {""} Reload
                          </Button>
                        </div>
                      ) : (
                        ""
                      )} */}
                      {data.propertyType === "New sale" ? (
                        <>
                          <div>
                            <Typography
                              id="non-linear-slider"
                              className="mt-3"
                              gutterBottom
                            >
                              <b>Built Up Area(sq. ft.)</b>
                            </Typography>
                            <Slider
                              className="mb-3"
                              style={{ maxWidth: "80%" }}
                              value={value6}
                              min={0}
                              step={1}
                              max={200}
                              valueLabelFormat={numFormatter1}
                              marks={followersMarks1}
                              scale={scale1}
                              onChange={handleChange6}
                              valueLabelDisplay="auto"
                              aria-labelledby="non-linear-slider"
                            />
                            <Typography>
                              <span
                                style={{
                                  color: "#464646",
                                  fontWeight: "600",
                                }}
                              >
                                {scale1(value6[0])}
                              </span>{" "}
                              Sq.ft to
                              <span
                                style={{
                                  color: "#464646",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {scale1(value6[1])}
                              </span>{" "}
                              Sq.ft{" "}
                            </Typography>
                          </div>
                          <div className="mt-3">
                            <b className="mb-3">Property Age</b>
                          </div>
                          <div>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="< 1 year"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={lessthanone}
                                  onChange={() => {
                                    setlessthanone(!lessthanone);
                                    setlessthanthree(false);
                                    setlessthanfive(false);
                                    setlessthanten(false);
                                    setmorethanten(false);
                                    propertyageFilter("Less than a year");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="1 to 5 year"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={lessthanthree}
                                  onChange={() => {
                                    setlessthanthree(!lessthanthree);
                                    setlessthanone(false);
                                    setlessthanfive(false);
                                    setlessthanten(false);
                                    setmorethanten(false);
                                    propertyageFilter("1 to 5 years");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="5 to 10 year"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={lessthanfive}
                                  onChange={() => {
                                    setlessthanfive(!lessthanfive);
                                    setlessthanthree(false);
                                    setlessthanone(false);
                                    setlessthanten(false);
                                    setmorethanten(false);
                                    propertyageFilter("5 to 10 years");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="More than 10 years"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={morethanten}
                                  onChange={() => {
                                    setmorethanten(!morethanten);
                                    setlessthanthree(false);
                                    setlessthanfive(false);
                                    setlessthanten(false);
                                    setlessthanone(false);
                                    propertyageFilter("More than 10 years");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Furnishing</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Full"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-12`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing2}
                                  onChange={(e) => {
                                    setfurnishing2(!furnishing2);
                                    setfurnishing3(false);
                                    furnishtypefilter("Fully-furnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Semi"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-13`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing3}
                                  onChange={(e) => {
                                    setfurnishing2(false);
                                    setfurnishing3(!furnishing3);
                                    furnishtypefilter("Semi-furnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Unfurnishing"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-13`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={furnishing7}
                                  onChange={(e) => {
                                    setfurnishing2(false);
                                    setfurnishing3(false);
                                    setfurnishing7(!furnishing7);
                                    furnishtypefilter("Unfurnishing");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Facing</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="North"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={north}
                                  onChange={(e) => {
                                    setnorth(!north);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("North");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="East"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={east}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(!east);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("East");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="West"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={west}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(!west);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("West");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="South"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={south}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(!south);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("South");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="North-East"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={northeast}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(!northeast);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("North-East");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="South-West"
                                  name="group1"
                                  type={type}
                                  checked={southwest}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(!southwest);
                                    FacingFilter("South-West");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="North-West"
                                  name="group1"
                                  type={type}
                                  checked={northwest}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(!northwest);
                                    setsouthwest(false);
                                    FacingFilter("North-West");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="South-East"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={southeast}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(!southeast);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("South-East");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {data.fullhouse ? (
                        <>
                          <div>
                            <Typography
                              id="non-linear-slider"
                              className="mt-3"
                              gutterBottom
                            >
                              <b>Built Up Area(sq. ft.)</b>
                            </Typography>
                            <Slider
                              className="mb-3"
                              style={{ maxWidth: "80%" }}
                              value={value6}
                              min={0}
                              step={1}
                              max={200}
                              valueLabelFormat={numFormatter1}
                              marks={followersMarks1}
                              scale={scale1}
                              onChange={handleChange6}
                              valueLabelDisplay="auto"
                              aria-labelledby="non-linear-slider"
                            />
                            <Typography>
                              <span
                                style={{
                                  color: "#464646",
                                  fontWeight: "600",
                                }}
                              >
                                {scale1(value6[0])}
                              </span>{" "}
                              Sq.ft to
                              <span
                                style={{
                                  color: "#464646",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {scale1(value6[1])}
                              </span>{" "}
                              Sq.ft{" "}
                            </Typography>
                          </div>

                          <div className="mt-3">
                            <b className="mb-3">Gated Security</b>
                          </div>
                          <div>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={yes}
                                  onChange={() => {
                                    setyes(!yes);
                                    setno(false);
                                    securityFilter("Yes");
                                  }}
                                />
                              </span>
                            ))}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={no}
                                  onChange={() => {
                                    setno(!no);
                                    setyes(false);
                                    securityFilter("No");
                                  }}
                                />
                              </span>
                            ))}
                          </div>
                          <div className="mt-3">
                            <b className="mb-3">Water Supply</b>
                          </div>
                          <div>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Borewell"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={borewell}
                                  onChange={() => {
                                    setborewell(!borewell);
                                    setcorporation(false);
                                    waterFilter("Borewell");
                                  }}
                                />
                              </span>
                            ))}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Corporation"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={corporation}
                                  onChange={() => {
                                    setcorporation(!corporation);
                                    setborewell(false);
                                    waterFilter("Corporation");
                                  }}
                                />
                              </span>
                            ))}
                          </div>
                          <div>
                            <p className="mt-3">
                              <b>Facing</b>
                            </p>
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="North"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={north}
                                  onChange={(e) => {
                                    setnorth(!north);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("North");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="East"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  checked={east}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(!east);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("East");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="West"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={west}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(!west);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("West");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="South"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={south}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(!south);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("South");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="North-East"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={northeast}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(!northeast);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("North-East");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="South-West"
                                  name="group1"
                                  type={type}
                                  checked={southwest}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(false);
                                    setsouthwest(!southwest);
                                    FacingFilter("South-West");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="North-West"
                                  name="group1"
                                  type={type}
                                  checked={northwest}
                                  id={`inline-${type}-1`}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(false);
                                    setnorthwest(!northwest);
                                    setsouthwest(false);
                                    FacingFilter("North-West");
                                  }}
                                />
                              </span>
                            ))}{" "}
                            {["checkbox"].map((type) => (
                              <span key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="South-East"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  checked={southeast}
                                  style={{ backgroundcolor: "#009587" }}
                                  onChange={(e) => {
                                    setnorth(false);
                                    seteast(false);
                                    setwest(false);
                                    setsouth(false);
                                    setnortheast(false);
                                    setsoutheast(!southeast);
                                    setnorthwest(false);
                                    setsouthwest(false);
                                    FacingFilter("South-East");
                                  }}
                                />
                              </span>
                            ))}{" "}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {data.flatmates || data.pghostel ? <>Unavailable</> : ""}
                      {data.buy || data.rent ? <>Unavailable</> : ""}
                      {data.propertyType === "Plots" ? (
                        <>
                          <div>
                            <Typography
                              id="non-linear-slider"
                              className="mt-3"
                              gutterBottom
                            >
                              <b>Built Up Area(sq. ft.)</b>
                            </Typography>
                            <Slider
                              className="mb-3"
                              style={{ maxWidth: "80%" }}
                              value={value6}
                              min={0}
                              step={1}
                              max={200}
                              valueLabelFormat={numFormatter1}
                              marks={followersMarks1}
                              scale={scale1}
                              onChange={handleChange6}
                              valueLabelDisplay="auto"
                              aria-labelledby="non-linear-slider"
                            />
                            <Typography>
                              <span
                                style={{
                                  color: "#464646",
                                  fontWeight: "600",
                                }}
                              >
                                {scale1(value6[0])}
                              </span>{" "}
                              Sq.ft to
                              <span
                                style={{
                                  color: "#464646",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {scale1(value6[1])}
                              </span>{" "}
                              Sq.ft{" "}
                            </Typography>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </TabPanel>
                  </SwipeableViews>
                </Box>
              </Card>
            </Col>

            {/* property details started here -------->*/}

            <Col className="col-md-7 col-xs-12b ">
              <Card
                style={{
                  width: "100%",
                  height: "auto",
                  marginLeft: "10px",
                  marginBottom: "20px",
                }}
              >
                <div className="ms-2 mt-1 ">
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      marginTop: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    <span>
                      <i className="fa fa-arrow-left"></i> Back
                    </span>
                  </Link>
                </div>

                <div className="col-md-12 p-2">
                  <h5 style={{ color: "rgb(54, 72, 104)" }}>
                    {data?.locality.map((item) => item.label)}
                    {/* in{" "} */}
                    {/* {data.locality[0]?.label} */}
                  </h5>{" "}
                  <span style={{ color: "rgb(54, 72, 104)", fontSize: "18px" }}>
                    {/* {datas[0]?.propertytypes[0]?.property} */}
                    {data?.propertyType}
                  </span>
                </div>
              </Card>
              {datas.map((i) => (
                <Link
                  to={{
                    pathname: `/propertydetails/${i.locality}/${i.city}/${i.propertyId}`,
                    // pathname: `/propertydetails/${i.propertyId}`,
                    state: { data: i },
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Card
                    key={i._id}
                    className="mt-3 mb-3 ms-2"
                    style={{ width: "100%", height: "267px" }}
                  >
                    <div className="row">
                      <div className="col-sm-4">
                        <Carousel
                          className="p-2"
                          style={{ height: "250px" }}
                          interval={null}
                        >
                          <Carousel.Item>
                            {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                            <img
                              className="d-block w-100"
                              src={
                                i?.propertydetails[0]?.propertyimage[0] === null
                                  ? "/img/noimgae.jpeg"
                                  : "https://houseliv.in/property/" +
                                    i?.propertydetails[0]?.propertyimage[0]
                              }
                              width="250px"
                              height="250px"
                              alt=""
                            />
                            {/* </ReactWaterMark> */}
                          </Carousel.Item>
                          <Carousel.Item>
                            {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                            <img
                              className="d-block w-100"
                              src={
                                i?.propertydetails[0]?.propertyimage[1] === null
                                  ? "/img/noimgae.jpeg"
                                  : "https://houseliv.in/property/" +
                                    i?.propertydetails[0]?.propertyimage[1]
                              }
                              width="250px"
                              height="250px"
                              alt=""
                            />
                            {/* </ReactWaterMark> */}
                          </Carousel.Item>
                          <Carousel.Item>
                            {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                            <img
                              className="d-block w-100"
                              src={
                                i?.propertydetails[0]?.propertyimage[2] === null
                                  ? "/img/noimgae.jpeg"
                                  : "https://houseliv.in/property/" +
                                    i?.propertydetails[0]?.propertyimage[2]
                              }
                              width="250px"
                              height="250px"
                              alt=""
                            />
                            {/* </ReactWaterMark> */}
                          </Carousel.Item>
                          <Carousel.Item>
                            {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                            <img
                              className="d-block w-100"
                              src={
                                i?.propertydetails[0]?.propertyimage[3] === null
                                  ? "/img/noimgae.jpeg"
                                  : "https://houseliv.in/property/" +
                                    i?.propertydetails[0]?.propertyimage[3]
                              }
                              width="250px"
                              height="250px"
                              alt=""
                            />
                            {/* </ReactWaterMark> */}
                          </Carousel.Item>
                          <Carousel.Item>
                            {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                            <img
                              className="d-block w-100"
                              src={
                                i?.propertydetails[0]?.propertyimage[4] === null
                                  ? "/img/noimgae.jpeg"
                                  : "https://houseliv.in/property/" +
                                    i?.propertydetails[0]?.propertyimage[4]
                              }
                              width="250px"
                              height="250px"
                              alt=""
                            />
                            {/* </ReactWaterMark> */}
                            <Carousel.Item>
                              {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                              <img
                                className="d-block w-100"
                                src={
                                  i?.propertydetails[0]?.propertyimage[5] ===
                                  null
                                    ? "/img/noimgae.jpeg"
                                    : "https://houseliv.in/property/" +
                                      i?.propertydetails[0]?.propertyimage[5]
                                }
                                width="500px"
                                height="500px"
                                alt=""
                              />
                              {/* </ReactWaterMark> */}
                            </Carousel.Item>
                            <Carousel.Item>
                              {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                              <img
                                className="d-block w-100"
                                src={
                                  i?.propertydetails[0]?.propertyimage[6] ===
                                  null
                                    ? "/img/noimgae.jpeg"
                                    : "https://houseliv.in/property/" +
                                      i?.propertydetails[0]?.propertyimage[6]
                                }
                                width="500px"
                                height="500px"
                                alt=""
                              />
                              {/* </ReactWaterMark> */}
                            </Carousel.Item>
                            <Carousel.Item>
                              {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                              <img
                                className="d-block w-100"
                                src={
                                  i?.propertydetails[0]?.propertyimage[7] ===
                                  null
                                    ? "/img/noimgae.jpeg"
                                    : "https://houseliv.in/property/" +
                                      i?.propertydetails[0]?.propertyimage[7]
                                }
                                width="500px"
                                height="500px"
                                alt=""
                              />
                              {/* </ReactWaterMark> */}
                            </Carousel.Item>
                            <Carousel.Item>
                              {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                              <img
                                className="d-block w-100"
                                src={
                                  i?.propertydetails[0]?.propertyimage[8] ===
                                  null
                                    ? "/img/noimgae.jpeg"
                                    : "https://houseliv.in/property/" +
                                      i?.propertydetails[0]?.propertyimage[8]
                                }
                                width="500px"
                                height="500px"
                                alt=""
                              />
                              {/* </ReactWaterMark> */}
                            </Carousel.Item>
                            <Carousel.Item>
                              {/* <ReactWaterMark
                        waterMarkText={text}
                        // openSecurityDefense
                        // securityAlarm={beginAlarm}
                        options={options}
                      > */}
                              <img
                                className="d-block w-100"
                                src={
                                  i?.propertydetails[0]?.propertyimage[9] ===
                                  null
                                    ? "/img/noimgae.jpeg"
                                    : "https://houseliv.in/property/" +
                                      i?.propertydetails[0]?.propertyimage[9]
                                }
                                width="500px"
                                height="500px"
                                alt=""
                              />
                              {/* </ReactWaterMark> */}
                            </Carousel.Item>
                          </Carousel.Item>
                        </Carousel>
                      </div>
                      <div className="col-sm-8 mt-3">
                        <Card.Title>
                          <Row>
                            <Row>
                              <Col
                                className="rentalamound"
                                md={9}
                                style={{
                                  color: "rgb(54 72 104)",
                                  fontWeight: "600",
                                  fontSize: "27px",
                                  fontFamily: "inherit",
                                  marginBottom: "17px",
                                }}
                              >
                                ₹ {i?.rentaldetails[0]?.expectedRent}
                                {i?.resaledetails[0]?.expectedPrice}
                                {i?.propertytypes[0]?.propertytype ===
                                "PG/Hostel"
                                  ? i?.propertydetails[0]?.fourRoom === "true"
                                    ? i?.propertydetails[0]
                                        ?.expectedRentfourroom + "/Four Sharing"
                                    : i?.propertydetails[0]?.threeRoom ===
                                      "true"
                                    ? i?.propertydetails[0]
                                        ?.expectedRentthreeroom +
                                      "/Triple Sharing"
                                    : i?.propertydetails[0]?.doubleRoom ===
                                      "true"
                                    ? i?.propertydetails[0]
                                        ?.expectedRentdoubleroom +
                                      "/Double Sharing"
                                    : i?.propertydetails[0]?.singleRoom ===
                                      "true"
                                    ? i?.propertydetails[0]
                                        ?.expectedRentsingleroom +
                                      "/Single Sharing"
                                    : ""
                                  : ""}
                                {i?.plotdetails[0]?.plotPrice}{" "}
                              </Col>
                              {/* 
                            <Col md={3}>
                              <IconButton
                                onClick={() => {
                                  setFav(!fav); updatelist();
                                }}
                                aria-label="delete"
                                color="#8b0000"
                              >
                                <FavoriteBorderIcon></FavoriteBorderIcon>
                              </IconButton>
                            </Col> */}
                            </Row>

                            <div className="ms-2">
                              {" "}
                              <span
                                style={{
                                  color: "rgb(0 0 0)",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                }}
                              >
                                {i?.propertydetails[0]?.buildingType}{" "}
                                {i?.propertydetails[0]?.bhkType}{" "}
                                {i?.propertydetails[0]?.apartmentType ===
                                "Apartment"
                                  ? "Flat"
                                  : i?.propertydetails[0]?.apartmentType}
                              </span>
                              <span
                                style={{ color: "#3c3c3c", fontSize: "15px" }}
                              >
                                {i?.propertydetails[0]?.propertytype !==
                                "PG/Hostel" ? (
                                  <> for</>
                                ) : (
                                  ""
                                )}
                                {i?.pgdetails[0]?.apartmentName}{" "}
                                {i?.propertytypes[0]?.propertytype} in{" "}
                                {data.locality.label}
                              </span>
                              {i?.propertytypes[0]?.propertytype !==
                              "PG/Hostel" ? (
                                <span
                                  style={{
                                    color: "#606060",
                                    fontSize: "15px",
                                    fontFamily: "system-ui",
                                    fontWeight: "700",
                                  }}
                                >
                                  {" "}
                                  {i?.propertydetails[0]?.propertySize}
                                  {i?.propertydetails[0]?.builtUpArea}
                                  {i?.plotdetails[0]?.totalPlotSize} sq.ft{" "}
                                </span>
                              ) : (
                                ""
                              )}
                              <br />
                              <div
                                className="mb-1 mt-1"
                                style={{
                                  color: "#c66565",
                                  fontSize: "13px",
                                }}
                              >
                                {i?.locality}
                              </div>
                            </div>
                          </Row>
                        </Card.Title>
                        <Link
                          to={{
                            pathname: `/propertydetails/${i.propertyId}`,
                            state: { data: i },
                          }}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <Card
                            className="ms-2"
                            style={{
                              backgroundColor: "rgb(244 244 244)",
                              height: "auto",
                              width: "95%",
                            }}
                          >
                            <div class="container">
                              <div className="d-sm-flex flex-direction-sm-row justify-content-sm-between houseliv-property">
                                {i?.propertytypes[0]?.property ===
                                  "Residential" &&
                                i?.propertytypes[0]?.propertytype !==
                                  "PG/Hostel" &&
                                i?.propertytypes[0]?.propertytype !==
                                  "Plots" ? (
                                  <>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                      }}
                                    >
                                      <img
                                        src="/icon/sofa.png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Furnishing
                                      <div
                                        className="mt-4 "
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {i?.rentaldetails[0]?.furnishing !==
                                        undefined
                                          ? i?.rentaldetails[0]?.furnishing
                                          : i?.propertydetails[0]?.furnishing}
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/bathroom.png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Bathroom
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.amenitiesdetails[0]?.bathRooms !==
                                        undefined
                                          ? i?.amenitiesdetails[0]?.bathRooms
                                          : "-"}
                                      </div>
                                    </div>

                                    {i?.propertytypes[0]?.propertytype !==
                                    "Flatmates" ? (
                                      <>
                                        <div
                                          class="col-sm mt-2"
                                          style={{
                                            fontSize: "11px",
                                            color: "#7f7f7f",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <img
                                            src="/icon/antique-balcony.png"
                                            style={{
                                              width: "28px",
                                              height: "49",
                                            }}
                                          />
                                          Balcony
                                          <div
                                            className="mt-4 ms-1"
                                            style={{
                                              fontSize: "12px",
                                              color: "black",
                                            }}
                                          >
                                            {i?.amenitiesdetails[0]?.balcony !==
                                            undefined
                                              ? i?.amenitiesdetails[0]?.balcony
                                              : "-"}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {i?.propertytypes[0]?.propertytype ===
                                    "Flatmates" ? (
                                      <div
                                        class="col-sm mt-2"
                                        style={{
                                          fontSize: "11px",
                                          color: "#7f7f7f",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <img
                                          src="/icon/hotel.png"
                                          style={{
                                            width: "28px",
                                            height: "49",
                                          }}
                                        />
                                        Room
                                        <div
                                          className="mt-4 ms-1"
                                          style={{
                                            fontSize: "12px",
                                            color: "black",
                                          }}
                                        >
                                          {i?.propertydetails[0]?.roomType !==
                                          undefined
                                            ? i?.propertydetails[0]?.roomType
                                            : "-"}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {i?.propertytypes[0]?.propertytype !==
                                    "New sale" ? (
                                      <div
                                        class="col-sm mt-2"
                                        style={{
                                          fontSize: "11px",
                                          color: "#7f7f7f",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <img
                                          src="/icon/tenant.png"
                                          style={{
                                            width: "25px",
                                            height: "49",
                                          }}
                                        />
                                        Preferred Tenants
                                        <div
                                          className="mt-2 ms-1"
                                          style={{
                                            fontSize: "12px",
                                            color: "black",
                                          }}
                                        >
                                          {i?.rentaldetails[0]
                                            ?.preferredTenants !== undefined
                                            ? i?.rentaldetails[0]
                                                ?.preferredTenants
                                            : i?.propertydetails[0]
                                                ?.tenantType !== undefined
                                            ? i?.propertydetails[0]?.tenantType
                                            : "-"}
                                        </div>
                                      </div>
                                    ) : i?.propertytypes[0]?.propertytype ===
                                      "New sale" ? (
                                      <>
                                        <div
                                          class="col-sm mt-2"
                                          style={{
                                            fontSize: "11px",
                                            color: "#7f7f7f",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <img
                                            src="/icon/compass (3).png"
                                            style={{
                                              width: "25px",
                                              height: "49",
                                            }}
                                          />
                                          Facing
                                          <div
                                            className="mt-2 ms-1"
                                            style={{
                                              fontSize: "12px",
                                              color: "black",
                                            }}
                                          >
                                            {i?.propertydetails[0]?.facing !==
                                            undefined
                                              ? i?.propertydetails[0]?.facing
                                              : "-"}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : i?.propertytypes[0]?.propertytype ===
                                  "PG/Hostel" ? (
                                  <>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/team.png"
                                        style={{ width: "25px", height: "49" }}
                                      />
                                      PG for
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.pgdetails[0]?.genderType !==
                                        undefined
                                          ? i?.pgdetails[0]?.genderType
                                          : "-"}
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                      }}
                                    >
                                      Single
                                      <div
                                        className="mt-4 "
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {i?.propertydetails[0]?.singleRoom ===
                                        "true" ? (
                                          <>
                                            {
                                              i?.propertydetails[0]
                                                ?.expectedRentsingleroom
                                            }
                                          </>
                                        ) : (
                                          "_"
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                      }}
                                    >
                                      Double
                                      <div
                                        className="mt-4 "
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {i?.propertydetails[0]?.doubleRoom ===
                                        "true" ? (
                                          <>
                                            {
                                              i?.propertydetails[0]
                                                ?.expectedRentdoubleroom
                                            }
                                          </>
                                        ) : (
                                          "_"
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                      }}
                                    >
                                      Triple
                                      <div
                                        className="mt-4 "
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {i?.propertydetails[0]?.threeRoom ===
                                        "true" ? (
                                          <>
                                            {
                                              i?.propertydetails[0]
                                                ?.expectedRentthreeroom
                                            }
                                          </>
                                        ) : (
                                          "_"
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                      }}
                                    >
                                      Four
                                      <div
                                        className="mt-4 "
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {i?.propertydetails[0]?.fourRoom ===
                                        "true" ? (
                                          <>
                                            {
                                              i?.propertydetails[0]
                                                ?.expectedRentfourroom
                                            }
                                          </>
                                        ) : (
                                          "_"
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (i?.propertytypes[0]?.propertytype ===
                                    "Rent" &&
                                    i?.propertytypes[0]?.property ===
                                      "Commercial") ||
                                  (i?.propertytypes[0]?.propertytype ===
                                    "Sale" &&
                                    i?.propertytypes[0]?.property ===
                                      "Commercial") ? (
                                  <>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/assets.png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Propety Type
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.propertydetails[0]?.propertyType !==
                                        undefined
                                          ? i?.propertydetails[0]?.propertyType
                                          : "-"}
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/car-parking.png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Parking
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.amenitiesdetails[0]?.parking !==
                                        undefined
                                          ? i?.amenitiesdetails[0]?.parking
                                          : "-"}
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/sofa.png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Furnishing
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.propertydetails[0]?.furnishing !==
                                        undefined
                                          ? i?.propertydetails[0]?.furnishing
                                          : "-"}
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/bathroom.png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Bathroom
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.amenitiesdetails[0]?.bathRooms !==
                                        undefined
                                          ? i?.amenitiesdetails[0]?.bathRooms
                                          : "-"}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {i?.propertytypes[0]?.propertytype ===
                                  "Plots" &&
                                i?.propertytypes[0]?.property ===
                                  "Residential" ? (
                                  <>
                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/sale.png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Sales type
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.plotdetails[0]?.salesType !==
                                        undefined
                                          ? i?.plotdetails[0]?.salesType
                                          : "-"}
                                      </div>
                                    </div>

                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/compass (1).png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Facing
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.plotdetails[0]?.facing !== undefined
                                          ? i?.plotdetails[0]?.facing
                                          : "-"}
                                      </div>
                                    </div>

                                    <div
                                      class="col-sm mt-2"
                                      style={{
                                        fontSize: "11px",
                                        color: "#7f7f7f",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <img
                                        src="/icon/hour-glass.png"
                                        style={{ width: "28px", height: "49" }}
                                      />
                                      Age of property
                                      <div
                                        className="mt-4 ms-1"
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        {i?.plotdetails[0]?.propertyAge !==
                                        undefined
                                          ? i?.plotdetails[0]?.propertyAge
                                          : "-"}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Card>
                        </Link>
                        {(i?.propertytypes[0]?.propertytype !== "Plots" &&
                          i?.propertytypes[0]?.property !== "Residential") ||
                        (i?.propertytypes[0]?.propertytype !== "Rent" &&
                          i?.propertytypes[0]?.property !== "Commercial") ||
                        (i?.propertytypes[0]?.propertytype !== "Sale" &&
                          i?.propertytypes[0]?.property !== "Commercial") ? (
                          <div className="mt-2 ms-2">
                            <b style={{ color: "rgb(54 72 104)" }}>
                              Available Facilities
                            </b>
                            :{" "}
                            <span
                              style={{ fontSize: "14px", color: "#464646" }}
                            >
                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.lift === "true"
                                  ? "Lift, "
                                  : ""}
                              </span>

                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.powerbackup === "true"
                                  ? "Power Backup, "
                                  : ""}
                              </span>

                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.park === "true"
                                  ? "Park, "
                                  : ""}
                              </span>

                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.airconditioner === "true"
                                  ? "AC, "
                                  : ""}
                              </span>

                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.housekeeping === "true"
                                  ? "House Keeping, "
                                  : ""}
                              </span>

                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.internetservices === "true"
                                  ? "Wifi, "
                                  : ""}
                              </span>

                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.intercom === "true"
                                  ? "Intercom, "
                                  : ""}
                              </span>

                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.swimmingpool === "true"
                                  ? "Swimming Pool, "
                                  : ""}
                              </span>
                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.mess === "true"
                                  ? "Mess, "
                                  : ""}
                              </span>
                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.commonTv === "true"
                                  ? "Tv, "
                                  : ""}
                              </span>
                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.refrigerator === "true"
                                  ? "Refrigerator, "
                                  : ""}
                              </span>
                              <span>
                                {" "}
                                {i?.amenitiesdetails[0]?.availableAmenities
                                  ?.cookingAllowed === "true"
                                  ? "Cooking Allowed, "
                                  : ""}
                              </span>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Card>
                </Link>
              ))}
            </Col>
          </div>
        </div>
      </div>

      {/* for mobile responsive */}
      <div>
        <MobileResp />
      </div>
    </div>
  );
}

export default Search;
