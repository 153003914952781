import axios from "axios";
import React, { useState } from "react";

function Adminsignin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, settype] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;
  const [captchaValue, setCaptchaValue] = useState(false);

  function onCaptchaVerify(value) {
    setCaptchaValue(value);
  }
  const handlesignin = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/users/signin",
        method: "post",
        baseURL: "https://houseliv.in/api",
        headers: { "content-type": "application/json" },
        data: { email: email, password: password },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);

        sessionStorage.setItem("user", JSON.stringify(res.data.user));

        if (res.data.user.role === 1) {
          window.location.assign("/admin/dashboard");
        }
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  return (
    <div style={{ background: "#f6f8fc" }}>
      <div className="container pt-5">
        <div className="row" style={{ padding: " 50px 30px" }}>
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <form>
              <div className="row ">
                <div className="col-md-12 t-c ">
                  <h1 className="" style={{ padding: "20px 20px 50px" }}>
                    Admin Sign In{" "}
                  </h1>
                </div>
                <div className="col-md-12">
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Email ID</label>
                    <input
                      className="form-control "
                      type="text"
                      style={{ background: "#fff", padding: "10px;" }}
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Password</label>
                    <input
                      className="form-control "
                      type="password"
                      style={{ background: "#fff", padding: "10px;" }}
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div
                  className="col-md-12 pt-2"
                  style={{ padding: "20px 20px 10px", float: "right" }}
                >
                  <div className="form-gropu pt-2">
                    <button
                      className="btn btn-primary form-control"
                      style={{ padding: "5px", background: "#3f5ea9" }}
                      onClick={handlesignin}
                    >
                      {" "}
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminsignin;
