import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Tab,
  TabContainer,
} from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Com_rent_additional() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [description, setdescription] = React.useState("");
  const [previousOccupancy, setpreviousOccupancy] = React.useState("");
  const [propertyShow, setpropertyShow] = React.useState("");
  const [propertyPainted, setpropertyPainted] = React.useState("");
  const [propertyCleaned, setpropertyCleaned] = React.useState("");
  const [secondaryNumber, setsecondaryNumber] = React.useState("");
  // const [availableAmenities, setavailableAmenities] = React.useState("");
  const [propertyowner, setpropertyowner] = React.useState("");
  const [youTube, setyouTube] = React.useState("")

  // const [availableAmenities, setavailableAmenities] = React.useState(false);
  // console.log(availableAmenities);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    if(!propertyowner){
      alert("Please Select Owner Details")
   }else{
    let config = {
      url: "/propertyForOwner/updateCommercialPropertyAdditionalInformation",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        propertyDescription: description,
        previousOccupancy: previousOccupancy,
        propertyShow: propertyShow,
        propertyPainted: propertyPainted,
        propertyCleaned: propertyCleaned,
        secondaryNumber: secondaryNumber,
        propertyowner: propertyowner,
        youTube: youTube,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      alert("Your property is successfully posted");
      window.location.assign("/");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Six">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Property Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">Sale Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Additional Information</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 mb-5"
            style={{
              width: "100%",
              height: "800px",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "18px",
              }}
            >
              Additional Information
            </Card>{" "}
            <Row>
              {/* <Row>
                <Form.Label
                  className="mt-3 ms-2 "
                  style={{ fontWeight: "600" }}
                >
                  Property Description
                </Form.Label>

                <textarea
                  style={{ width: "620px" }}
                  onChange={(e) => setdescription(e.target.value)}
                  placeholder="Provide any specific description you want to add about your property like furnishing and other amenities....."
                ></textarea>
              </Row> */}
              <Row className="mt-5 ms-4">
                <Form.Label style={{ fontWeight: "600" }}>
                  Description<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>

                <textarea
                  style={{ width: "90%" }}
                  onChange={(e) => setdescription(e.target.value)}
                  placeholder="Provide any specific description you want to add about your property like furnishing and other amenities....."
                ></textarea>
              </Row>

              <Row className="ms-4 mt-4">
                <Col md={6}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Previous Occupancy
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setpreviousOccupancy(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="First Time Renting">
                          First time renting
                        </option>
                        <option value="Currently Rented">
                          Currently Renting
                        </option>
                        <option value="Previously Rented">
                          Previously Rented
                        </option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={6}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Who will show the Property?<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setpropertyShow(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Need Help">Need Help</option>
                        <option value="I will show">I will show</option>
                        <option value="Neighbours">Neighbours</option>
                        <option value="Friends/Relatives">
                          Friends/Relatives
                        </option>
                        <option value="Security">Security</option>
                        <option value="Tenants">Tenants</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row className="ms-4 mt-4">
                <Col md={6}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      I want to get my property painted
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setpropertyPainted(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>{" "}
                <Col md={6}>
                  {" "}
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      I want to get my property cleaned
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "150px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setpropertyCleaned(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>

              <Row className="ms-4 mt-4">
                <Col md={6}>
                  {" "}
                  <Form>
                    <Form.Label style={{ fontWeight: "600" }}>
                    Secondary Number
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="text"
                        placeholder="Optional"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setsecondaryNumber(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col  md={6}>
                 <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                     Posted By<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                    <select
                        className="form-control "
                        style={{
                          width: "200px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setpropertyowner(e.target.value)}
                        required
                      >
                        <option value="">Select Property Owner</option>
                        <option value="Owner">Owner</option>
                        <option value="Agent">Agent</option>
                        <option value="Developer">Developer</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <div>
          <Form className="mt-3" style={{marginLeft:"10px", width:"70%"}}>
            <Form.Text className="text-muted">
                If you have a youtube link about this property paste here 
              </Form.Text>
            <Form.Group  controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Optional Only!" 
               onChange={(e) => setyouTube(e.target.value)}
                />              
            </Form.Group>
            </Form>
          </div>
            </Row>
          </Card>{" "}
        </Col>

        {/* //// */}
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Final Submission
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Com_rent_additional;
