import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Tab,
  TabContainer,
} from "react-bootstrap";
import { DropzoneArea } from "material-ui-dropzone";
import { faItalic } from "@fortawesome/free-solid-svg-icons";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Pgroomdetail() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [expectedRentsingleroom, setexpectedRentsingleroom] =
    React.useState("");
  const [expectedDepositsingleroom, setexpectedDepositsingleroom] =
    React.useState("");

  const [expectedRentdoubleroom, setexpectedRentdoubleroom] =
    React.useState("");
  const [expectedDepositdoubleroom, setexpectedDepositdoubleroom] =
    React.useState("");

  const [expectedRentthreeroom, setexpectedRentthreeroom] = React.useState("");
  const [expectedDepositthreeroom, setexpectedDepositthreeroom] =
    React.useState("");

  const [expectedRentfourroom, setexpectedRentfourroom] = React.useState("");
  const [expectedDepositfourroom, setexpectedDepositfourroom] =
    React.useState("");

  const [availableAmenities, setavailableAmenities] = React.useState("");

  const [singleRoom, setsingleRoom] = React.useState(false);
  console.log(singleRoom);

  const [doubleRoom, setdoubleRoom] = React.useState(false);
  console.log(doubleRoom);

  const [threeRoom, setthreeRoom] = React.useState(false);
  console.log(threeRoom);

  const [fourRoom, setfourRoom] = React.useState(false);
  console.log(fourRoom);

  const [ac, setac] = React.useState(false);
  console.log(ac);

  const [tv, settv] = React.useState(false);
  console.log(tv);

  const [geyser, setgeyser] = React.useState(false);
  console.log(geyser);

  const [attachedBathroom, setattachedBathroom] = React.useState(false);
  console.log(attachedBathroom);

  const [bedding, setbedding] = React.useState(false);
  console.log(bedding);

  const [cupBoard, setcupBoard] = React.useState(false);
  console.log(cupBoard);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createPGPropertyDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        singleRoom: singleRoom,
        doubleRoom: doubleRoom,
        threeRoom: threeRoom,
        fourRoom: fourRoom,
        expectedRentsingleroom: expectedRentsingleroom,
        expectedDepositsingleroom: expectedDepositsingleroom,
        expectedRentdoubleroom: expectedRentdoubleroom,
        expectedDepositdoubleroom: expectedDepositdoubleroom,
        expectedRentthreeroom: expectedRentthreeroom,
        expectedDepositthreeroom: expectedDepositthreeroom,
        expectedRentfourroom: expectedRentfourroom,
        expectedDepositfourroom: expectedDepositfourroom,
        availableAmenities: {
          tv: tv,
          ac: ac,
          bedding: bedding,
          geyser: geyser,
          cupBoard: cupBoard,
          attachedBathroom: attachedBathroom,
        },
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/pg_locality");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  return (
    <div>
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Room Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">PG Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={7}>
          <Card
            className="mt-5 mb-2"
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3 "
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "18px",
              }}
            >
              Provide details about your place to find a tenant soon
            </Card>{" "}
            <Row className="mt-3 ms-3" style={{ fontWeight: "600" }}>
              <Col>Select the type of rooms available in your PG</Col>
            </Row>
            <Row>
              <Col className="ms-4 mt-3 md-4">
                <img src="/icon/bed.png" width="50px" height="50px" />
                <div className="ms-1" style={{}}>
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-1">
                      <Form.Check
                        inline
                        label="Single Room"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={singleRoom}
                        onChange={() => setsingleRoom(!singleRoom)}
                      />
                    </div>
                  ))}{" "}
                </div>
              </Col>
              {singleRoom ? (
                <Col className="md-4 mt-3">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Rent<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) =>
                          setexpectedRentsingleroom(e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
              {singleRoom ? (
                <Col className="md-4 mt-3">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Deposit<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) =>
                          setexpectedDepositsingleroom(e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col className="ms-4 mt-3 md-4">
                <img src="/icon/bed.png" width="50px" height="50px" />
                <img src="/icon/bed.png" width="50px" height="50px" />
                <div className="ms-1" style={{}}>
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-1">
                      <Form.Check
                        inline
                        label="Double Room"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={doubleRoom}
                        onChange={() => setdoubleRoom(!doubleRoom)}
                      />
                    </div>
                  ))}{" "}
                </div>
              </Col>
              {doubleRoom ? (
                <Col className="md-4 mt-3">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Rent/person<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) =>
                          setexpectedRentdoubleroom(e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
              {doubleRoom ? (
                <Col className="md-4 mt-3">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Deposit/person<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) =>
                          setexpectedDepositdoubleroom(e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col className="ms-4 mt-3 md-4">
                <img src="/icon/bed.png" width="50px" height="50px" />
                <img src="/icon/bed.png" width="50px" height="50px" />
                <img src="/icon/bed.png" width="50px" height="50px" />
                <div className="ms-1" style={{}}>
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-1">
                      <Form.Check
                        inline
                        label="Three Room"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={threeRoom}
                        onChange={() => setthreeRoom(!threeRoom)}
                      />
                    </div>
                  ))}{" "}
                </div>
              </Col>
              {threeRoom ? (
                <Col className="md-4 mt-3">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Rent/person<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) =>
                          setexpectedRentthreeroom(e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
              {threeRoom ? (
                <Col className="md-4 mt-3">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Deposit/person<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) =>
                          setexpectedDepositthreeroom(e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col className="ms-4 mt-3 md-4">
                <img src="/icon/bed.png" width="50px" height="50px" />
                <img src="/icon/bed.png" width="50px" height="50px" />
                <img src="/icon/bed.png" width="50px" height="50px" />
                <img src="/icon/bed.png" width="50px" height="50px" />
                <div className="ms-1" style={{}}>
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-1">
                      <Form.Check
                        inline
                        label="Four Room"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={fourRoom}
                        onChange={() => setfourRoom(!fourRoom)}
                      />
                    </div>
                  ))}{" "}
                </div>
              </Col>
              {fourRoom ? (
                <Col className="md-4 mt-3">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Rent/person<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) =>
                          setexpectedRentfourroom(e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
              {fourRoom ? (
                <Col className="md-4 mt-3">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Deposit/person<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col}>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "150px",
                          color: "GrayText",
                        }}
                        onChange={(e) =>
                          setexpectedDepositfourroom(e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <hr />
            <Row className="mt-3 ms-3" style={{ fontWeight: "600" }}>
              <Col onChange={(e) => setavailableAmenities(e.target.value)}>
                Room Amenities
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="mt-2 ms-5 ">
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="AC"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={ac}
                      onChange={() => setac(!ac)}
                    />
                  </div>
                ))}{" "}
              </Col>
              <Col className="mt-2 ">
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label=" TV"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={tv}
                      onChange={() => settv(!tv)}
                    />
                  </div>
                ))}{" "}
              </Col>
              <Col className="mt-2">
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Geyser"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={geyser}
                      onChange={() => setgeyser(!geyser)}
                    />
                  </div>
                ))}{" "}
              </Col>
            </Row>
            <Row>
              <Col className="mt-2 ms-5 ">
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label=" Cupboard"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={cupBoard}
                      onChange={() => setcupBoard(!cupBoard)}
                    />
                  </div>
                ))}{" "}
              </Col>
              <Col className="mt-2 ">
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Attached Bathroom"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={attachedBathroom}
                      onChange={() => setattachedBathroom(!attachedBathroom)}
                    />
                  </div>
                ))}{" "}
              </Col>
              <Col className="mt-2 ">
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Bedding"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={bedding}
                      onChange={() => setbedding(!bedding)}
                    />
                  </div>
                ))}{" "}
              </Col>
            </Row>
          </Card>{" "}
        </Col>
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Pgroomdetail;
