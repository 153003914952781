import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Tab,
  TabContainer,
} from "react-bootstrap";
import { DropzoneArea } from "material-ui-dropzone";
import { faItalic } from "@fortawesome/free-solid-svg-icons";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Schedule() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [startTime, setstartTime] = React.useState("");
  const [endTime, setendTime] = React.useState("");
  const [avaliability, setavaliability] = React.useState("");
  const [avaliableAllDay, setavaliableAllDay] = React.useState(false);
  const [propertyowner, setpropertyowner] = React.useState("")
  const [youTube, setyouTube] = React.useState("")

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    if(!propertyowner){
      alert("Please Select Owner Details")
   }else{
    let config = {
      url: "/propertyForOwner/updatePropertySchedule",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        startTime: startTime,
        endTime: endTime,
        avaliability: avaliability,
        avaliableAllDay: avaliableAllDay,
        propertyowner: propertyowner,
        youTube: youTube,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      alert("Your property is successfully posted");
      window.location.assign("/");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  }
  };

  return (
    <div>
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Six">
              {/* <Row>
        <Col sm={3}> */}
              <Nav
                variant="pills"
                className="flex-column"
                // style={{ color: "#009587" }}
              >
                <Nav.Item>
                  <Nav.Link eventKey="first" href="/addproperty">
                    Property Details
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second" href="/localitydetails">
                    Locality Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third" href="/rentaldetails">
                    Sale Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four" href="/amenitiesdetails">
                    Amenities
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five" href="/gallery">
                    Gallery
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six" href="/scheduler">
                    Schedule
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 "
            style={{
              width: "100%",
              height: "700px",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3 "
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "18px",
              }}
            >
              Make house visits hassle-free by providing us your availability
            </Card>{" "} 

            <Row>
            <Col md={4} className="mt-4 ms-4">
            <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>
                  Start Time<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>
                <Form.Group as={Col} controlId="formGridState">
                <select
                  className="form-control ms-3"
                  style={{
                    width: "100px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setstartTime(e.target.value)}
                  required
                >
                  <option value="">Start time</option>
                  {avaliableAllDay ? (
                    <option value="09:00 AM">09:00 AM</option>
                  ) : (
                    <>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 PM">12:00 PM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                      <option value="03:00 PM">03:00 PM</option>
                      <option value="04:00 PM">04:00 PM</option>
                      <option value="05:00 PM">05:00 PM</option>
                      <option value="06:00 PM">06:00 PM</option>
                      <option value="07:00 PM">07:00 PM</option>
                      <option value="08:00 PM">08:00 PM</option>
                      <option value="09:00 PM">09:00 PM</option>
                    </>
                  )}
                </select>
                </Form.Group>
              </Form>
              
            </Col>
            <Col md={4} className="mt-4 ms-4">
            <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>End Time<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                <Form.Group as={Col} controlId="formGridState" style={{ width:"100%" }}>
                <select
                  className="form-control ms-3"
                  style={{
                    width: "100px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setendTime(e.target.value)}
                  required
                >
                  <option value="">End time</option>
                  {avaliableAllDay ? (
                    <option value="09:00 PM">09:00 PM</option>
                  ) : (
                    <>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 PM">12:00 PM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                      <option value="03:00 PM">03:00 PM</option>
                      <option value="04:00 PM">04:00 PM</option>
                      <option value="05:00 PM">05:00 PM</option>
                      <option value="06:00 PM">06:00 PM</option>
                      <option value="07:00 PM">07:00 PM</option>
                      <option value="08:00 PM">08:00 PM</option>
                      <option value="09:00 PM">09:00 PM</option>
                    </>
                  )}
                </select>
                </Form.Group>
              </Form>
            </Col>
            <Col md={4} className="mt-4 ms-4">
            <div className="mt-3 ms-2">
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Available All Day"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={avaliableAllDay}
                        onChange={() => setavaliableAllDay(!avaliableAllDay)}
                      />
                    </div>
                  ))}
                </div>
            </Col>
            </Row>
            <Row>
            <Col md={4} className="mt-4 ms-4">
            <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>Availability<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                <Form.Group as={Col} controlId="formGridState" style={{ width:"100%" }}>
                <select
                  className="form-control ms-3"
                  style={{
                    width: "170px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setavaliability(e.target.value)}
                  required
                >
                  <option value="">Select available days</option>
                  <option value="Everyday">Everyday</option>
                  <option value="Weekday">Weekday</option>
                  <option value="Weekend">Weekend</option>
                </select>{" "}
                </Form.Group>
              </Form>
            </Col>
            <Col md={4} className="mt-4 ms-4">
            <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>Posted By<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                <Form.Group as={Col} controlId="formGridState" style={{ width:"100%" }}>
                <select
                  className="form-control ms-3"
                  style={{
                    width: "170px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setpropertyowner(e.target.value)}
                  required
                >
                  <option value="">Select Property Owner</option>
                  <option value="Owner">Owner</option>
                  <option value="Agent">Agent</option>
                  <option value="Developer">Developer</option>
                </select>{" "}
                </Form.Group>
              </Form>
                </Col>
                <div>
          <Form className="mt-3" style={{marginLeft:"10px", width:"70%"}}>
            <Form.Text className="text-muted">
                If you have a youtube link about this property paste here 
              </Form.Text>
            <Form.Group  controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Optional Only!" 
               onChange={(e) => setyouTube(e.target.value)}
               required />              
            </Form.Group>
            </Form>
          </div>
          </Row>
          
          
            {/* <Row className="mt-5 ms-4" style={{ fontWeight: "600" }}>
              <Col md={6} style={{ fontWeight: "600" }}>Select time schedule</Col>
              <div>
              
                
              </div>
              
              <Col md={6} style={{ fontWeight: "600" }}>Availability</Col>
              <div>
              
              </div>
            </Row>
            <Row className="mt-3 ms-2">
              {" "}
              <Col md={6} className="d-flex">
                {" "}
                <select
                  className="form-control ms-3"
                  style={{
                    width: "100px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setstartTime(e.target.value)}
                >
                  <option value="">Start time</option>
                  {avaliableAllDay ? (
                    <option value="09:00 AM">09:00 AM</option>
                  ) : (
                    <>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 PM">12:00 PM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                      <option value="03:00 PM">03:00 PM</option>
                      <option value="04:00 PM">04:00 PM</option>
                      <option value="05:00 PM">05:00 PM</option>
                      <option value="06:00 PM">06:00 PM</option>
                      <option value="07:00 PM">07:00 PM</option>
                      <option value="08:00 PM">08:00 PM</option>
                      <option value="09:00 PM">09:00 PM</option>
                    </>
                  )}
                </select>
                <select
                  className="form-control ms-3"
                  style={{
                    width: "100px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setendTime(e.target.value)}
                >
                  <option value="">End time</option>
                  {avaliableAllDay ? (
                    <option value="09:00 PM">09:00 PM</option>
                  ) : (
                    <>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 PM">12:00 PM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                      <option value="03:00 PM">03:00 PM</option>
                      <option value="04:00 PM">04:00 PM</option>
                      <option value="05:00 PM">05:00 PM</option>
                      <option value="06:00 PM">06:00 PM</option>
                      <option value="07:00 PM">07:00 PM</option>
                      <option value="08:00 PM">08:00 PM</option>
                      <option value="09:00 PM">09:00 PM</option>
                    </>
                  )}
                </select>
              </Col>

            </Row>
               
            <Row>
            
              <Col className="mt-4 ms-4">
              <Form>
                  <Form.Label style={{ fontWeight: "600" }}>
                    End Time<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                  <select
                  className="form-control ms-3"
                  style={{
                    width: "100px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setendTime(e.target.value)}
                >
                  <option value="">End time</option>
                  {avaliableAllDay ? (
                    <option value="09:00 PM">09:00 PM</option>
                  ) : (
                    <>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 PM">12:00 PM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                      <option value="03:00 PM">03:00 PM</option>
                      <option value="04:00 PM">04:00 PM</option>
                      <option value="05:00 PM">05:00 PM</option>
                      <option value="06:00 PM">06:00 PM</option>
                      <option value="07:00 PM">07:00 PM</option>
                      <option value="08:00 PM">08:00 PM</option>
                      <option value="09:00 PM">09:00 PM</option>
                    </>
                  )}
                </select>
                  </Form.Group>
                </Form>
                
              </Col>
              <Col  className="mt-4 ms-4">
              <Form>
                  <Form.Label style={{ fontWeight: "600" }}>Starting Time<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                  <Form.Group as={Col} controlId="formGridState" style={{ width:"100%" }}>
                  <select
                  className="form-control ms-3"
                  style={{
                    width: "100px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setstartTime(e.target.value)}
                >
                  <option value="">Start time</option>
                  {avaliableAllDay ? (
                    <option value="09:00 AM">09:00 AM</option>
                  ) : (
                    <>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 PM">12:00 PM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                      <option value="03:00 PM">03:00 PM</option>
                      <option value="04:00 PM">04:00 PM</option>
                      <option value="05:00 PM">05:00 PM</option>
                      <option value="06:00 PM">06:00 PM</option>
                      <option value="07:00 PM">07:00 PM</option>
                      <option value="08:00 PM">08:00 PM</option>
                      <option value="09:00 PM">09:00 PM</option>
                    </>
                  )}
                </select>
                  </Form.Group>
                </Form>
              </Col>
              <Form.Label style={{ fontWeight: "600" }}>Availability<span style={{ color: "#dc3545" }}>*</span></Form.Label>
              <Col md={6} className="mt-4 ms-4">
                <select
                  className="form-control ms-3"
                  style={{
                    width: "170px",
                    height: "35px",
                    color: "GrayText",
                    cursor: "pointer",
                  }}
                  onChange={(e) => setavaliability(e.target.value)}
                >
                  <option value="">Select available days</option>
                  <option value="Everyday">Everyday</option>
                  <option value="Weekday">Weekday</option>
                  <option value="Weekend">Weekend</option>
                </select>{" "}
              </Col>
              <Col md={6}>
                <div className="mt-3 ms-2">
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Available All Day"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={avaliableAllDay}
                        onChange={() => setavaliableAllDay(!avaliableAllDay)}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
           */}
          </Card>{" "}
        </Col>
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
              Final Submission
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Schedule;
