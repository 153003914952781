import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

function LogIn() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <h1
        style={{
          fontSize: "20px",
          fontFamily: "Source Sans Pro",
          color: "black",
          textAlign: "center",
        }}
      ></h1>
      <p style={{ fontSize: "15px", fontFamily: "Arial", textAlign: "center" }}>
        hi
      </p>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Full Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Full Name" />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="number" placeholder="Enter Mobile Number" />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" placeholder="confrim password " />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LogIn;
