import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";

function Adminsidebar() {
  const [show, setshow] = useState(false);
  const userdata = JSON.parse(sessionStorage.getItem("user"));

  const apiURL = process.env.REACT_APP_API_URL;

  const signout = () => {
    try {
      axios
        .get(`${apiURL}/admin/signout/` + userdata.id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("profile");
            alert("Signout Successfully");

            //    props.dispatch({type:"emptyBasket"});
            //    props.dispatch({type:"removeUser"});
            //  sessionStorage.removeItem("user");
            //  localStorage.removeItem("token");
            window.location.href = "/";
            return;
          } else {
            alert("Signout Unsuccessfully");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Signout Unsuccessfully");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Signout Unsuccessfully");
      // setdatacondition(true);
      return error;
    }
  };

  return (
    <ProSidebar width="200px" collapsed={show}>
      <SidebarContent style={{ background: "#1b2435", color: "white" }}>
        {/**
         *  You can add the content of the sidebar ex: menu, profile details, ...
         */}
        <Menu
          iconShape=""
          className="p-0 pt-1"
          style={{
            background: "#1b2435",
            color: "white",
            fontWeight: "bolder",
            textAlign: "left",
          }}
        >
          <hr className="mt-0" />
          <MenuItem icon={<i class="fa fa-tachometer" aria-hidden="true"></i>}>
            Dashboard <Link to="/admin/dashboard" />
          </MenuItem>
          <hr className="mt-0" />

          <MenuItem icon={<i class="fa fa-user" aria-hidden="true"></i>}>
            User <Link to="/admin/user" />
          </MenuItem>
          <hr className="mt-0" />
          <MenuItem
            icon={<i class="fa fa-picture-o" aria-hidden="true"></i>}
          >
            Property <Link to="/admin/property" />
          </MenuItem>
          <hr className="mt-0" />
          <MenuItem
            icon={<i class="fa fa-home" aria-hidden="true"></i>}
          >
            Add City <Link to="/admin/addcity" />
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter style={{ background: "#1b2435", color: "white" }}>
        {/**
         *  You can add a footer for the sidebar ex: copyright
         */}
        {/* {show ? 
                 <Button className="mt-2 ml-3" variant="dark" style={{borderRadius:"100%"}} onClick={()=>setshow(!show)}><i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                 :<Button className="mt-3" variant="dark" style={{borderRadius:"100%", marginLeft:"70px"}} onClick={()=>setshow(!show)}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>}
                 */}
        <div style={{ height: "200px" }}></div>
      </SidebarFooter>
    </ProSidebar>
  );
}

export default Adminsidebar;
