import { Link } from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Geocode from "react-geocode";

function Com_plot_locality() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [city, setcity] = React.useState("");
  const [locality, setlocality] = React.useState("");
  const [streetArea, setstreetArea] = React.useState("");
  const [landmark, setlandmark] = React.useState("");
  const [getcitydata, setgetcitydata] = React.useState([]);

  
  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");

  const [lat, setlat] = useState("");
  const [lon, setlon] = useState("");
  
  Geocode.setApiKey("AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg");
  Geocode.setLanguage("en");
  Geocode.setLocationType("ROOFTOP");
  
  useEffect(() => {

    if(locality){
      getlatlong()
    }

    // navigator.geolocation.getCurrentPosition(function (position) {
    //   setlat(position.coords.latitude);
    //   setlon(position.coords.longitude);
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });    
  }, [locality]);

const getlatlong = () => {
  Geocode.fromAddress(locality.label).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      console.log(lat, lng);
      setlat(lat);
      setlon(lng)
    },
    (error) => {
      console.error(error);
    }
  );
  }

  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createLocalityDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        city: city,
        locality: locality.label,
        streetArea: streetArea,
        lat: lat,
        lng: lon,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/commercial_plot_gallery");
    } else {
      console.log("error");
    }
  };

  useEffect(() => {getcity();}, [])

  const getcity =  () => {
    try{
      axios
      .get('https://houseliv.in/api/propertyForOwner/getAddCity').then(function (response) {
        if (response.status === 200) {
          console.log(response.data);
          setgetcitydata(response.data.cityList1);
          return;
        } else {
          alert("Can't able to fetch ");
          return;
        }
      })
    }
    catch(error) {
    console.log(error.message.data);
    alert("Can't able  to fetch");
    return ;
    }; 
};

  return (
    <div className="mb-5">
      <Row className="me-0" >
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Second">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Plot Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Second">Location Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 "
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Location details
            </Card>{" "}
            <Row>
              <Col className="mt-5 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>City<span style={{ color: "#dc3545" }}>*</span></Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setcity(e.target.value)}
                      required
                    >
                     <option value="">Select</option>
                        {getcitydata.map((data) => (
                      <option value={data.city}>{data.city}</option>
                      ))}
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-5 ms-4">
                <Form>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Location<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <GooglePlacesAutocomplete                      
                      apiKey="AIzaSyBSSh2iC9t7FJExi9-EwGXfcoq0LmTRp7M"
                      selectProps={{
                        // onChange={(e) => setlocality(e.target.value)}  
                        isClearable: true,
                        value: locality,
                        onChange: (val) => {
                          setlocality(val);
                        },     
                      }}                                 
                      />
                  <Form.Group as={Col} controlId="formGridState"></Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-5 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Street / Area<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g.Evergreen Street"
                    style={{
                      width: "250px",
                      color: "GrayText",
                    }}
                    onChange={(e) => setstreetArea(e.target.value)}
                    required
                  />
                  <Form.Group as={Col} controlId="formGridState"></Form.Group>
                </Form>
              </Col>
            </Row>
            <Row className="mt-4 mb-5">
              {/* <img
                src="/img/GoogleMap.PNG"
                style={{ width: "100%", height: "100%" }}
              ></img> */}
              <div >
                <iframe className="maps"
                src="https://maps.google.com/maps?width=100%25&amp;height=350px&amp;hl=en&amp;q=bangalore+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                  <a href="https://www.gps.ie/">vehicle gps tracker</a>
                  </iframe>
                </div>
            </Row>
          </Card>{" "}
        </Col>{" "}
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Com_plot_locality;

// export default Com_plot_locality;
