import { Link } from "@material-ui/core";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Resalerental() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [expectedRent, setexpectedRent] = React.useState("");
  const [maintenanceCost, setmaintenanceCost] = React.useState("");
  const [currentlyUnderLoan, setcurrentlyUnderLoan] = React.useState("");
  const [negotiable, setnegotiable] = React.useState("");
  const [kitchenType, setkitchenType] = React.useState("");
  const [avaliableFrom, setavaliableFrom] = React.useState(new Date());

  const [furnishing, setfurnishing] = React.useState("");
  const [parking, setparking] = React.useState("");
  const [description, setdescription] = React.useState("");

  const [onlynegotiable, setonlynegotiable] = React.useState(false);
  console.log(negotiable);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createRentalDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        // propertyType: propertyType,
        expectedRent: expectedRent,

        negotiable: negotiable,
        kitchenType: kitchenType,
        maintenanceCost: maintenanceCost,
        avaliableFrom: avaliableFrom,

        furnishing: furnishing,
        parking: parking,
        description: description,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/resale_amenities");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Third">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Property Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">New sale Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Additional Information</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Seven">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 mb-3"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Provide Sale Details about your property
            </Card>{" "}
            <Row>
              <Row>
                <Col md={5} className="mt-3 ms-4">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Expected Price<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-1">
                      <InputGroup.Text>₹</InputGroup.Text>
                      <Form.Control
                        id="inlineFormInputGroup"
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "215px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setexpectedRent(e.target.value)}
                        required
                      />
                    </InputGroup>
                  </Form>
                </Col>

                <Col className="mt-3 ms-5">
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Maintenance Cost<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-1">
                      <InputGroup.Text>₹</InputGroup.Text>
                      <Form.Control
                        id="inlineFormInputGroup"
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "215px",
                          color: "GrayText",
                        }}
                        onChange={(e) => setmaintenanceCost(e.target.value)}
                        required
                      />
                    </InputGroup>
                  </Form>
                </Col>
              </Row>
              <Row className="ms-4">
                <Col>
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Price Negotiable"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={negotiable}
                        onChange={() => setnegotiable(!negotiable)}
                      />
                    </div>
                  ))}
                </Col>
                <Col>
                  {" "}
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Currently Under Loan"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={currentlyUnderLoan}
                        onChange={() =>
                          setcurrentlyUnderLoan(!currentlyUnderLoan)
                        }
                      />
                    </div>
                  ))}
                </Col>
              </Row>
            </Row>
            <Row>
              <Col className="mt-1 ms-4">
                {" "}
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Available From<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col}>
                    <DatePicker
                      placeholder="YYYY/MM/DD"
                      format="YYYY/MM/DD"
                      style={{
                        width: "250px",
                        height: "38px",
                        // cursor: "pointer",
                      }}
                      onChange={(date) =>
                        setavaliableFrom(date.format("YYYY/MM/DD"))
                      }
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-1 ms-3">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Kitchen Type
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setkitchenType(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Modular">Modular</option>
                      <option value="Coverd Shelves">Coverd Shelves </option>
                      <option value="Open Shelves">Open Shelves</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 ms-4">
                {" "}
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Furnishing<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setfurnishing(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Fully-furnishing">Fully-furnishing</option>
                      <option value="Semi-furnishing">Semi-furnishing</option>
                      <option value="Unfurnishing">Unfurnishing</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-4 ms-4">
                {" "}
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Parking<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setparking(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Bike">Bike</option>
                      <option value="Car">Car</option>
                      <option value="Both">Both</option>
                      <option value="None">None</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Row className="mt-5 ms-4">
                <Form.Label style={{ fontWeight: "600" }}>
                  Description
                </Form.Label>

                <textarea
                  style={{ width: "90%" }}
                  onChange={(e) => setdescription(e.target.value)}
                  required
                  placeholder="Provide any specific description you want to add about your property like furnishing and other amenities....."
                ></textarea>
              </Row>
            </Row>
          </Card>{" "}
        </Col>
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Resalerental;
