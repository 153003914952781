import logo from "./logo.svg";
import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import { Component } from "react";
import Contacts from "./components/Contacts";
import LogIn from "./components/LogIn";
import Propertymanage from "./components/Propertymanage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import Locality from "./components/Locality";
import Rental from "./components/Rental";
import Amenities from "./components/Amenities";
import Gallery from "./components/Gallery";
import Schedule from "./components/Schedule";
import Search from "./components/Search";
import Adminlayout from "./components/admin/Adminlayout";
import Admindashboard from "./components/admin/Admindashboard";
import User from "./components/admin/User";
import Property from "./components/admin/Property";
import Profile from "./components/Profile";
import Adminsignin from "./components/admin/Adminsignin";
import Password from "./components/Password";
import Myproperty from "./components/Myproperty";
import Propertydetails from "./components/Propertydetails";
import Resalepropertymanage from "./components/Resalepropertymanage";
import Resalelocality from "./components/Resalelocality";
import Resalerental from "./components/Resalerental";
import Resaleamenities from "./components/Resaleamenities";
import Resalegallery from "./components/Resalegallery";
import Resaleadditional from "./components/Resaleadditional";
import Resaleschedule from "./components/Resaleschedule";
import Pgroomdetail from "./components/Pgroomdetail";
import Pglocality from "./components/Pglocality";
import Pgdetails from "./components/Pgdetails";
import Pgamenities from "./components/Pgamenities";
import Pggallery from "./components/Pggallery";
import Pgschedule from "./components/Pgschedule";
import Flatpropertmanage from "./components/Flatpropertmanage";
import Flatlocality from "./components/Flatlocality";
import Flatrental from "./components/Flatrental";
import Flatamenities from "./components/Flatamenities";
import Flatgallery from "./components/Flatgallery ";
import Flatschedule from "./components/Flatschedule";
import Com_rent_property from "./components/Com_rent_property";
import Com_rent_locality from "./components/Com_rent_locality";
import Com_rent_amenities from "./components/Com_rent_amenities";
import Com_rent_gallery from "./components/Com_rent_gallery";

import Com_rent_rental from "./components/Com_rent_rental";
import Com_rent_additional from "./components/Com_rent_additional";
import Com_sale_property from "./components/Com_sale_property";
import Com_sale_locality from "./components/Com_sale_locality ";
import Com_sale_rental from "./components/Com_sale_rental ";
import Com_sale_amenities from "./components/Com_sale_amenities";
import Com_sale_gallery from "./components/Com_sale_gallery";
import Com_sale_additional from "./components/Com_sale_additional";
import Com_plot_details from "./components/Com_plot_details";
import Com_plot_locality from "./components/Com_plot_locality";
import Com_plot_gallery from "./components/Com_plot_gallery";
import Com_plot_schedule from "./components/Com_plot_schedule";
import Header1 from "./components/Header1";
import Addcity from "./components/admin/Addcity";
import Myfavorite from "./components/Myfavorite";
import About from "./components/About";
import ErrorPage from "./components/ErrorPage";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
// import Userdetails from "./components/admin/Userdetails";
// import "Component/FontawesomeIcon";

// const routes = [
//   {
//     path: "/My Booking",
//     component: Home,
//   },
//   {
//     path: "/Pay Rent",
//     component: About,
//     routes: [
//       {
//         path: "/Pay Rent/Pay Rent with Credit Cart",
//         component: "Pay Rent with Credit Cart",
//       },
//     ],
//   },
// ];

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Header />
          <Home />
          <Footer />
        </Route>
        <Route exact path="/privacy">
          <Header />
          <Privacy />
          <Footer />
        </Route>
        <Route exact path="/terms">
          <Header />
          <Terms />
          <Footer />
        </Route>
        {/* <Route exact path="*">
          <Header />
          <ErrorPage />
        </Route> */}
        <Route exact path="/about">
          <Header />
          <About />
          <Footer />
        </Route>

        <Route exact path="/contact">
          <Header />
          <Contact />
          <Footer />
        </Route>

        <Route exact path="/propertymanage">
          <Header />
          <Contacts />
          <Footer />
        </Route>

        <Route exact path="/addproperty">
          <Header />
          <Propertymanage />
        </Route>
        <Route exact path="/localitydetails">
          <Header />
          <Locality />
        </Route>
        <Route exact path="/rentaldetails">
          <Header />
          <Rental />
        </Route>
        <Route exact path="/amenitiesdetails">
          <Header />
          <Amenities />
        </Route>
        <Route exact path="/gallery">
          <Header />
          <Gallery />
        </Route>
        <Route exact path="/scheduler">
          <Header />
          <Schedule />
        </Route>
        <Route exact path="/search">
          <Header />
          <Search />
        </Route>
        <Route
          exact
          path="/propertydetails/:locality/:city/:id"
          // path="/propertydetails/:id"
        >
          <Header />
          <Propertydetails />
          <Footer />
        </Route>
        <Route exact path="/admin/dashboard">
          <Adminlayout childern={<Admindashboard />} />
        </Route>
        <Route exact path="/admin/user">
          <Adminlayout childern={<User />} />
        </Route>
        <Route exact path="/admin/property">
          <Adminlayout childern={<Property />} />
        </Route>
        <Route exact path="/admin/addcity">
          <Adminlayout childern={<Addcity />} />
        </Route>
        <Route exact path="/profile">
          <Header />
          <Profile />
          <Footer />
        </Route>
        <Route exact path="/admin/signin">
          <Header />
          <Adminsignin />
          <Footer />
        </Route>
        <Route exact path="/change_pwd">
          <Header />
          <Password />
          <Footer />
        </Route>
        <Route exact path="/myproperties">
          <Header />
          <Myproperty />
          <Footer />
        </Route>
        <Route exact path="/saved properties">
          <Header />
          <Myfavorite />
          <Footer />
        </Route>
        <Route exact path="/resale_propertymanage">
          <Header />
          <Resalepropertymanage />
        </Route>
        <Route exact path="/resale_locality">
          <Header />
          <Resalelocality />
        </Route>
        <Route exact path="/resale_rental">
          <Header />
          <Resalerental />
        </Route>
        <Route exact path="/resale_amenities">
          <Header />
          <Resaleamenities />
        </Route>
        <Route exact path="/resale_gallery">
          <Header />
          <Resalegallery />
        </Route>
        <Route exact path="/resale_additional_info">
          <Header />
          <Resaleadditional />
        </Route>
        <Route exact path="/resale_schedule">
          <Header />
          <Resaleschedule />
        </Route>
        <Route exact path="/pg_roomselect">
          <Header />
          <Pgroomdetail />
        </Route>
        <Route exact path="/pg_locality">
          <Header />
          <Pglocality />
        </Route>
        <Route exact path="/pg_details">
          <Header />
          <Pgdetails />
        </Route>
        <Route exact path="/pg_amenities">
          <Header />
          <Pgamenities />
        </Route>
        <Route exact path="/pg_gallery">
          <Header />
          <Pggallery />
        </Route>
        <Route exact path="/pg_schedule">
          <Header />
          <Pgschedule />
        </Route>
        <Route exact path="/flatmate_property">
          <Header />
          <Flatpropertmanage />
        </Route>
        <Route exact path="/flatmate_locality">
          <Header />
          <Flatlocality />
        </Route>
        <Route exact path="/flatmate_rental">
          <Header />
          <Flatrental />
        </Route>
        <Route exact path="/flatmate_amenities">
          <Header />
          <Flatamenities />
        </Route>
        <Route exact path="/flatmate_gallery">
          <Header />
          <Flatgallery />
        </Route>
        <Route exact path="/flatmate_schedule">
          <Header />
          <Flatschedule />
        </Route>
        <Route exact path="/commercial_rent_property">
          <Header />
          <Com_rent_property />
        </Route>
        <Route exact path="/commercial_rent_locality">
          <Header />
          <Com_rent_locality />
        </Route>
        <Route exact path="/commercial_rent_amenities">
          <Header />
          <Com_rent_amenities />
        </Route>
        <Route exact path="/commercial_rent_gallery">
          <Header />
          <Com_rent_gallery />
        </Route>
        <Route exact path="/commercial_rent_rental">
          <Header />
          <Com_rent_rental />
        </Route>
        <Route exact path="/commercial_rent_additional">
          <Header />
          <Com_rent_additional />
        </Route>
        <Route exact path="/commercial_sale_property">
          <Header />
          <Com_sale_property />
        </Route>
        <Route exact path="/commercial_sale_location">
          <Header />
          <Com_sale_locality />
        </Route>
        <Route exact path="/commercial_sale_rental">
          <Header />
          <Com_sale_rental />
        </Route>
        <Route exact path="/commercial_sale_amenities">
          <Header />
          <Com_sale_amenities />
        </Route>
        <Route exact path="/commercial_sale_gallery">
          <Header />
          <Com_sale_gallery />
        </Route>
        <Route exact path="/commercial_sale_additional">
          <Header />
          <Com_sale_additional />
        </Route>
        <Route exact path="/commercial_plot_details">
          <Header />
          <Com_plot_details />
        </Route>
        <Route exact path="/commercial_plot_locality">
          <Header />
          <Com_plot_locality />
        </Route>
        <Route exact path="/commercial_plot_gallery">
          <Header />
          <Com_plot_gallery />
        </Route>
        <Route exact path="/commercial_plot_schedule">
          <Header />
          <Com_plot_schedule />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
