import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal, Table, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import moment from "moment";

function Property() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);
  const [show5, setshow5] = useState(false);
  const [show6, setshow6] = useState(false);
  const [show7, setshow7] = useState(false);
  const [show8, setshow8] = useState(false);
  const [show9, setshow9] = useState(false);
  const [showex1, setshowex1] = useState(false);
  const [selected, setselected] = useState({});
  const [selectedimg, setselectedimg] = useState([]);
  const [rowdata, setrowdata] = useState({});
  const [description, setdescription] = useState("");

  useEffect(() => {
    getpatient();
  }, []);

  const getpatient = () => {
    try {
      axios
        .get(`https://houseliv.in/api/propertyForOwner/getallPropertyDetails`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.propertyDetail);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const deleteuserproperty = async (propertydetails) => {
    let propertyId = propertydetails._id;
    let config = {
      url: "/propertyForOwner/deleteUserProperty",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        propertyId: propertyId,
      },
    };
    let res = await axios(config);

    if (res.status == 200) {
      alert("Property Deleted");
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  const activateproperty = async (selected) => {
    let config = {
      url: "/propertyForOwner/activateproperty",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        id: selected._id,
        expire_status: "false",
        expire_at: moment().add(3, "month").calendar(),
      },
    };
    let res = await axios(config);
    if (res.status == 200) {
      window.location.reload();
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const editdata = async () => {
    let config = {
      url: "/propertyForOwner/editdiscription",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        propertyId: rowdata._id,
        description: description
          ? description
          : Object.keys(rowdata).length > 0
          ? rowdata.propertytype === "PG/Hostel" &&
            rowdata.property === "Residential"
            ? rowdata.pgdetails[0].description
            : (rowdata.property === "Commercial" &&
                rowdata.propertytype === "Sale") ||
              (rowdata.property === "Commercial" &&
                rowdata.propertytype === "Rent")
            ? rowdata.propertydetails[0].propertyDescription
            : rowdata.propertytype === "Plots" &&
              rowdata.property === "Residential"
            ? rowdata.plotdetails[0].description
            : rowdata.rentaldetails[0].description
          : "",
        type: rowdata.property,
        propertytype: rowdata.propertytype,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      // alert("Your property is successfully posted");
      window.location.reload();
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  console.log("data", data);

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`https://houseliv.in/property/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }
  var i = 1;
  const columns = [
    {
      text: "S.No",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <div>{rowIndex + 1}</div>;
      },
    },
    {
      dataField: "user[0].fullName",
      text: "Name",
      sort: true,
    },
    {
      dataField: "user[0].emailId",
      text: "Email",
      sort: true,
    },
    {
      dataField: "user[0].mobilePhone",
      text: "Mobile No",
    },
    {
      dataField: "property",
      text: "Property",
      sort: true,
    },
    {
      dataField: "propertytype",
      text: "Property Type",
      sort: true,
    },
    {
      dataField: "",
      text: "Property Details",
      sort: true,
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn active1"
              onClick={() => {
                setshow(true);
                setselected(
                  row.propertydetails.length > 0 ? row.propertydetails[0] : {}
                );
                setrowdata(row.propertydetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={row.propertytype === "Plots"}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "PG Details",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn active1"
              onClick={() => {
                setshow6(true);
                setselected(row.pgdetails.length > 0 ? row.pgdetails[0] : {});
                setrowdata(row.pgdetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={row.propertytype !== "PG/Hostel"}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Locality",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {" "}
            <button
              className="btn active1"
              onClick={() => {
                setshow1(true);
                setselected(
                  row.localitydetails.length > 0 ? row.localitydetails[0] : {}
                );
                setrowdata(row.localitydetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Plot Details",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {" "}
            <button
              className="btn active1"
              onClick={() => {
                setshow2(true);
                setselected(
                  row.plotdetails.length > 0 ? row.plotdetails[0] : {}
                );
                setrowdata(row.plotdetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={row.propertytype !== "Plots"}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Sale Details",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn active1"
              onClick={() => {
                setshow3(true);
                setselected(
                  row.rentaldetails.length > 0 ? row.rentaldetails[0] : {}
                );
                setrowdata(row.rentaldetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={
                row.propertytype === "Plots" ||
                row.propertytype === "PG/Hostel" ||
                row.propertytype === "Sale"
              }
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "New sale ",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn active1"
              onClick={() => {
                setshow9(true);
                setrowdata(row?.resaledetails.length > 0 ? row : {});
                setselected(
                  row?.resaledetails.length > 0 ? row?.resaledetails[0] : {}
                );
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={
                row.propertytype === "Plots" ||
                row.propertytype === "PG/Hostel" ||
                row.propertytype === "Rent" ||
                row.propertytype === "New sale" ||
                row.propertytype === "Flatmates"
              }
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Amenities",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn active1"
              onClick={() => {
                setshow4(true);
                setselected(
                  row.amenitiesdetails.length > 0 ? row.amenitiesdetails[0] : {}
                );
                setrowdata(row.amenitiesdetails.length > 0 ? row : {});
              }}
              disabled={row.propertytype === "Plots"}
              style={{ background: "#1b2435", color: "white" }}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "propertydetails[0].propertyimage[0]",
      text: "Gallery",
      // formatter: imageFormatter,
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn active1"
              onClick={() => {
                setshow7(true);
                setselectedimg(row.propertydetails[0].propertyimage);
                setrowdata(row);
              }}
              // disabled={row.propertytype === "Plots"}
              style={{ background: "#1b2435", color: "white" }}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Schedule",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {" "}
            <button
              className="btn active1"
              onClick={() => {
                setshow5(true);
                setselected(row);
                setrowdata(row);
              }}
              style={{ background: "#1b2435", color: "white" }}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Status",
      formatter: (cell, row) => {
        console.log(row);
        return row.approved === "created" ? (
          <div>
            <Row>
              <Col>
                {" "}
                <button
                  className="btn active1"
                  onClick={() => {
                    // setshow8(true);
                    setselected(row);
                    postdata("approved", row);
                  }}
                  style={{ background: "#1b2435", color: "white" }}
                >
                  Approve
                </button>
              </Col>
              <Col>
                <button
                  className="btn active1"
                  onClick={() => {
                    // setshow7(true);
                    setselected(row);
                    postdata("rejected", row);
                  }}
                  style={{
                    background: "#1b2435",
                    color: "white",
                    marginTop: "2px",
                  }}
                >
                  Reject
                </button>
              </Col>
            </Row>
          </div>
        ) : row.approved === "approved" ? (
          <>
            <h6 class="approval">Approved</h6>
            <button
              className="btn btn-success"
              onClick={() => {
                setshow8(true);
                // setselected(row);
                setrowdata(row);
              }}
            >
              Edit
            </button>

            <button
              className="btn"
              onClick={() => {
                deleteuserproperty(row);
              }}
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                borderColor: "#dc3545",
              }}
            >
              Delete
            </button>
          </>
        ) : (
          <>
            <h6 class="reject">Rejected</h6>
            <button
              className="btn"
              onClick={() => {
                deleteuserproperty(row);
              }}
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                borderColor: "#dc3545",
              }}
            >
              Delete
            </button>
          </>
        );
      },
    },
    {
      dataField: "",
      text: "Expire-Status",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {row.expire_status === "true" ? (
              <button
                className="btn active1"
                onClick={() => {
                  setshowex1(true);
                  setselected(row);
                  setrowdata(row);
                }}
                style={{ background: "#dc3545", color: "white" }}
              >
                Expired
              </button>
            ) : (
              <button
                className="btn active1"
                style={{ background: "#1b2435", color: "white" }}
              >
                Active
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "propertytype",
      order: "desc",
    },
  ];

  console.log(selected);
  const postdata = async (approved, propertydetails) => {
    let propertyId = propertydetails._id;
    let config = {
      url: "/propertyForOwner/updateApprovalStatus",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        propertyId: propertyId,
        approvalstatus: approved,
      },
    };
    console.log(propertydetails);
    console.log(approved);
    console.log(propertyId);

    let res = await axios(config);

    if (res.status == 200) {
      alert(approved + " Successfully");
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h4 className="pt-4 pb-3" style={{ color: "white" }}>
          PROPERTY
        </h4>
        <div className="container">
          <div className="card card-body">
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              search
              exportCSV={{
                fileName: "properties.csv",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div>
                  <span className="pr-5 mr-auto">
                    <ExportCSVButton
                      className="btn-outline-success"
                      {...props.csvProps}
                    >
                      Export to CSV
                    </ExportCSVButton>
                  </span>
                  {"     "}
                  <span className="ps-5 ml-5 mt-3">
                    <SearchBar
                      className="form-control "
                      {...props.searchProps}
                    />
                  </span>
                  {"      "}
                  <ClearSearchButton
                    className="btn-outline-info ms-5 "
                    {...props.searchProps}
                  />
                  <hr />
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="patient"
                      responsive
                      hover
                      columns={columns}
                      data={data}
                      pagination={paginationFactory(options)}
                      {...props.baseProps}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
            <Modal
              size="sm"
              show={show}
              onHide={() => setshow(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Property Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  {selected?.singleRoom == "true" ||
                  selected?.threeRoom == "true" ||
                  selected?.fourRoom == "true" ||
                  selected?.doubleRoom == "true" ? (
                    <>
                      {selected?.singleRoom == "true" ? (
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Expected Rent for single room :{" "}
                          {selected.expectedRentsingleroom}
                        </div>
                      ) : (
                        ""
                      )}
                      {selected?.singleRoom == "true" ? (
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Expected Deposit for single room :{" "}
                          {selected.expectedDepositsingleroom}
                        </div>
                      ) : (
                        ""
                      )}
                      {selected?.doubleRoom == "true" ? (
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Expected Rent for Double room :{" "}
                          {selected.expectedRentdoubleroom}
                        </div>
                      ) : (
                        ""
                      )}
                      {selected?.doubleRoom == "true" ? (
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Expected Deposit for Double room :{" "}
                          {selected.expectedDepositdoubleroom}
                        </div>
                      ) : (
                        ""
                      )}
                      {selected?.threeRoom == "true" ? (
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Expected Rent for Three room :{" "}
                          {selected.expectedRentthreeroom}
                        </div>
                      ) : (
                        ""
                      )}
                      {selected?.threeRoom == "true" ? (
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Expected Deposit for Three room :{" "}
                          {selected.expectedDepositthreeroom}
                        </div>
                      ) : (
                        ""
                      )}
                      {selected?.fourRoom == "true" ? (
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Expected Rent for Four room :{" "}
                          {selected.expectedRentfourroom}
                        </div>
                      ) : (
                        ""
                      )}
                      {selected?.fourRoom == "true" ? (
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Expected Deposit for Four room :{" "}
                          {selected.expectedDepositfourroom}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "Rent" ? (
                        <>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Apartment Type:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.apartmentType : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            ApartmentName:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.apartmentName : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            BHK Type:{" "}
                          </div>
                          <p>{selected !== {} ? selected.bhkType : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Facing:{" "}
                          </div>
                          <p>{selected !== {} ? selected.facing : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Floor:{" "}
                          </div>
                          <p>{selected !== {} ? selected.floor : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            PropertyAge:{" "}
                          </div>
                          <p>{selected !== {} ? selected.propertyAge : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Property Size:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.propertySize : ""}sq.ft
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Total Floor:{" "}
                          </div>
                          <p>{selected !== {} ? selected.totalFloor : ""} </p>
                        </>
                      ) : (
                        ""
                      )}
                      {/* resale property details */}
                      {rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "New sale" ? (
                        <>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Apartment Type:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.apartmentType : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            ApartmentName:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.apartmentName : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            BHK Type:{" "}
                          </div>
                          <p>{selected !== {} ? selected.bhkType : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Builtup Area:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.builtUpArea : ""} sq.ft
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Carpet Area:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.carpetArea : ""} sq.ft
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Facing:{" "}
                          </div>
                          <p>{selected !== {} ? selected.facing : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Floor:{" "}
                          </div>
                          <p>{selected !== {} ? selected.floor : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Total Floor:{" "}
                          </div>
                          <p>{selected !== {} ? selected.totalFloor : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Floor Type:{" "}
                          </div>
                          <p>{selected !== {} ? selected.floorType : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            PropertyAge:{" "}
                          </div>
                          <p>{selected !== {} ? selected.propertyAge : ""} </p>
                          <div
                            className="mt-3"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            {" "}
                            Additional Information
                          </div>
                          <div
                            className="mt-1"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Completion Certificate:{" "}
                          </div>
                          <p>
                            {selected !== {}
                              ? selected.completionCertificate
                              : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Occupancy Certificate:{" "}
                          </div>
                          <p>
                            {selected !== {}
                              ? selected.occupancyCertificate
                              : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Property Tax:{" "}
                          </div>
                          <p>{selected !== {} ? selected.propertyTax : ""} </p>
                        </>
                      ) : (
                        ""
                      )}

                      {/* flatmates property details */}
                      {rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "Flatmates" ? (
                        <>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Apartment Type:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.apartmentType : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Apartment Name:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.apartmentName : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            BHK Type:{" "}
                          </div>
                          <p>{selected !== {} ? selected.bhkType : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Builtup Area:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.builtUpArea : ""} sq.ft
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            PropertyAge:{" "}
                          </div>
                          <p>{selected !== {} ? selected.propertyAge : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Facing:{" "}
                          </div>
                          <p>{selected !== {} ? selected.facing : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Floor:{" "}
                          </div>
                          <p>{selected !== {} ? selected.floor : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Total Floor:{" "}
                          </div>
                          <p>{selected !== {} ? selected.totalFloor : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Room Type:{" "}
                          </div>
                          <p>{selected !== {} ? selected.roomType : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Tenant Type:{" "}
                          </div>
                          <p>{selected !== {} ? selected.tenantType : ""} </p>
                        </>
                      ) : (
                        ""
                      )}

                      {(rowdata?.property === "Commercial" &&
                        rowdata?.propertytype === "Rent") ||
                      (rowdata?.property === "Commercial" &&
                        rowdata?.propertytype === "Sale") ? (
                        <>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Property Type:{" "}
                          </div>
                          <p>{selected !== {} ? selected.propertyType : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Building Type:{" "}
                          </div>
                          <p>{selected !== {} ? selected.buildingType : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Floor:{" "}
                          </div>
                          <p>{selected !== {} ? selected.bhkType : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Total Floor:{" "}
                          </div>
                          <p>{selected !== {} ? selected.totalFloor : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Builtup Area:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.builtUpArea : ""} sq.ft
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Carpet Area:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.carpetArea : ""} sq.ft
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            PropertyAge:{" "}
                          </div>
                          <p>{selected !== {} ? selected.propertyAge : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Furnishing:{" "}
                          </div>
                          <p>{selected !== {} ? selected.furnishing : ""} </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Property Show:{" "}
                          </div>
                          <p>{selected !== {} ? selected.propertyShow : ""} </p>

                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Previous Occupancy:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.previousOccupancy : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Property Cleaned:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.propertyCleaned : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Property Painted:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.propertyPainted : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Secondary Number:{" "}
                          </div>
                          <p>
                            {selected !== {} ? selected.secondaryNumber : ""}{" "}
                          </p>
                          <div
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Description:{" "}
                          </div>
                          <p>
                            {selected !== {}
                              ? selected.propertyDescription
                              : ""}{" "}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              show={show1}
              onHide={() => setshow1(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Locality
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  City:{" "}
                </div>
                <p>{selected?.city} </p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Locality:{" "}
                </div>
                <p>{selected?.locality} </p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Street Area:{" "}
                </div>
                <p>{selected?.streetArea} </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow1(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              show={show6}
              onHide={() => setshow6(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  PG Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Gender Type :{" "}
                </div>
                <p>{selected.genderType}</p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Preferred Guests :{" "}
                </div>
                <p>{selected.preferredGuests}</p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Avaliable From:{" "}
                </div>
                <p>{selected?.avaliableFrom}</p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Food Inculded:{" "}
                </div>
                <p>{selected?.foodInculded}</p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Description:{" "}
                </div>
                <p>{selected?.description} </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow6(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              show={show2}
              onHide={() => setshow2(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Plot Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Plot Size:{" "}
                </div>
                <p>{selected?.totalPlotSize} sq.ft </p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Sales Type:{" "}
                </div>
                <p>{selected?.salesType} </p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Plot Price:{" "}
                </div>
                <p>₹ {selected?.plotPrice} </p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Age of Property:{" "}
                </div>
                <p>{selected?.propertyAge} </p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Description:{" "}
                </div>
                <p>{selected?.description} </p>
              </Modal.Body>
            </Modal>

            <Modal
              size="sm"
              show={show3}
              onHide={() => setshow3(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Rental
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/*resi... rent  */}
                {rowdata?.property === "Residential" &&
                rowdata?.propertytype === "Rent" ? (
                  <>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Rent:{" "}
                    </div>
                    <p>₹ {selected?.expectedRent} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Deposit:{" "}
                    </div>
                    <p>₹ {selected?.expectedDeposit} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Monthly Maintenance:{" "}
                    </div>
                    <p>{selected?.monthlyMaintenance} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Maintenance Amount:{" "}
                    </div>
                    <p>₹ {selected?.maintenanceAmount} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Peferred Tenants:{" "}
                    </div>
                    <p>{selected?.preferredTenants} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Parking:{" "}
                    </div>
                    <p>{selected?.parking} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Furnishing:{" "}
                    </div>
                    <p>{selected?.furnishing} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Description:{" "}
                    </div>
                    <p>{selected?.description} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Avaliable From:{" "}
                    </div>
                    <p>{selected?.avaliableFrom} </p>
                  </>
                ) : (
                  ""
                )}
                {/* resi... resale  */}
                {rowdata?.property === "Residential" &&
                rowdata?.propertytype === "New sale" ? (
                  <>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Rent:{" "}
                    </div>
                    <p>₹ {selected?.expectedRent} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Negotiable:{" "}
                    </div>
                    <p>{selected?.negotiable} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Parking:{" "}
                    </div>
                    <p>{selected?.parking} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Furnishing:{" "}
                    </div>
                    <p>{selected?.furnishing} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Description:{" "}
                    </div>
                    <p>{selected?.description} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Avaliable From:{" "}
                    </div>
                    <p>{selected?.avaliableFrom} </p>
                  </>
                ) : (
                  ""
                )}
                {/* resi... resale  */}
                {rowdata?.property === "Residential" &&
                rowdata?.propertytype === "Flatmates" ? (
                  <>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Rent:{" "}
                    </div>
                    <p>₹ {selected?.expectedRent} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Deposit:{" "}
                    </div>
                    <p>₹ {selected?.expectedDeposit} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Monthly Maintenance:{" "}
                    </div>
                    <p>{selected?.monthlyMaintenance} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Maintenance Amount:{" "}
                    </div>
                    <p>₹ {selected?.maintenanceAmount} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Parking:{" "}
                    </div>
                    <p>{selected?.parking} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Furnishing:{" "}
                    </div>
                    <p>{selected?.furnishing} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Description:{" "}
                    </div>
                    <p>{selected?.description} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Avaliable From:{" "}
                    </div>
                    <p> {selected?.avaliableFrom} </p>
                  </>
                ) : (
                  ""
                )}
                {/* comm... rent  */}
                {rowdata?.property === "Commercial" &&
                rowdata?.propertytype === "Rent" ? (
                  <>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Rent:
                    </div>
                    <p> ₹ {selected?.expectedRent} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Deposit:{" "}
                    </div>
                    <p> ₹ {selected?.expectedDeposit} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Monthly Maintenance:{" "}
                    </div>
                    <p>{selected?.monthlyMaintenance} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Lease Duration:{" "}
                    </div>
                    <p>{selected?.leaseDuration} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Lockin Period:{" "}
                    </div>
                    <p>{selected?.lockinPeriod} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Avaliable From:{" "}
                    </div>
                    <p>{selected?.avaliableFrom} </p>
                  </>
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              show={show9}
              onHide={() => setshow9(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  New sale
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {rowdata?.property === "Commercial" &&
                rowdata?.propertytype === "Sale" ? (
                  <>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Rent:{" "}
                    </div>
                    <p>₹ {selected?.expectedPrice} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Negotiable:{" "}
                    </div>
                    <p>{selected?.negotiable} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      OwnerShip Type:{" "}
                    </div>
                    <p>{selected?.ownerShipType} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Avaliable From:{" "}
                    </div>
                    <p>{selected?.avaliableFrom} </p>
                  </>
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow9(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              show={show4}
              onHide={() => setshow4(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Amenities
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* resi... flatmate   */}
                {(rowdata?.property === "Residential" &&
                  rowdata?.propertytype === "Flatmates") ||
                (rowdata?.property === "Residential" &&
                  rowdata?.propertytype === "Rent") ||
                (rowdata?.property === "Residential" &&
                  rowdata?.propertytype === "New sale") ? (
                  <>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Balcony:{" "}
                    </div>
                    <p>{selected?.balcony} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Bathrooms:{" "}
                    </div>
                    <p>{selected?.bathRooms} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Gated Security:{" "}
                    </div>
                    <p>{selected?.gatedSecurity} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Gym:{" "}
                    </div>
                    <p>{selected?.gym} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      NonVeg Allowed:{" "}
                    </div>
                    <p>{selected?.nonVegAllowed} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Water Supply:{" "}
                    </div>
                    <p>{selected?.parking} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Secondary Number:{" "}
                    </div>
                    <p>{selected?.secondaryNumber} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Sowing House:{" "}
                    </div>
                    <p>{selected?.showingHouse} </p>
                    <div
                      className="mt-3"
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Available Amenities
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Air Conditioner:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.airconditioner === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Club House:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.clubhouse === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Firesafety:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.firesafety === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Gas Pipeline:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.gaspipeline === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      House Keeping:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.housekeeping === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Intercom:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.intercom === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Internet Services:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.internetservices === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Lift:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.lift === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Park:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.park === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Power Backup:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.powerbackup === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Rainwater Harvesting:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.rainwaterharvesting ===
                      "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Servant Room:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.servantroom === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Sewagetreatment Plant:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.sewagetreatmentplant ===
                      "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Swimmingpool:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.swimmingpool === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Visitor Parking:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.visitorparking === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                  </>
                ) : (
                  ""
                )}

                {/* resi... pg   */}
                {rowdata?.property === "Residential" &&
                rowdata?.propertytype === "PG/Hostel" ? (
                  <>
                    <div
                      className="mt-3"
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Available Amenities
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Common Tv:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.commonTv === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Cooking Allowed:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.cookingAllowed === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Mess:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.mess === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Power Backup:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.powerbackup === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Refrigerator:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.refrigerator === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Internet Services:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.wifi === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Lift:{" "}
                    </div>
                    <p>
                      {selected?.availableAmenities?.lift === "true"
                        ? "Yes"
                        : "No"}{" "}
                    </p>

                    <div
                      className="mt-3"
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Available Service
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Laundry:{" "}
                    </div>
                    <p>{selected?.availableServices?.laundry} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Room Cleaning:{" "}
                    </div>
                    <p>{selected?.availableServices?.roomCleaning} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Warden Facility:{" "}
                    </div>
                    <p>{selected?.availableServices?.wardenFacility} </p>
                  </>
                ) : (
                  ""
                )}

                {/* comm... rent   */}
                {(rowdata?.property === "Commercial" &&
                  rowdata?.propertytype === "Rent") ||
                (rowdata?.property === "Commercial" &&
                  rowdata?.propertytype === "Sale") ? (
                  <>
                    <div
                      className="mt-3"
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Available Amenities
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Bathroom:{" "}
                    </div>
                    <p>{selected?.bathRooms} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Gated Security:{" "}
                    </div>
                    <p>{selected?.gatedSecurity} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Lift:{" "}
                    </div>
                    <p>{selected?.lift} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Parking:{" "}
                    </div>
                    <p>{selected?.parking} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Reserverd Slot:{" "}
                    </div>
                    <p>{selected?.reserved} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      PowerBackUp:{" "}
                    </div>
                    <p>{selected?.powerBackUp} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      waterSupply:{" "}
                    </div>
                    <p>{selected?.waterSupply} </p>
                    <div
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontFamily: "system-ui",
                        fontWeight: "600",
                      }}
                    >
                      Wifi:{" "}
                    </div>
                    <p>{selected?.wifi}</p>
                  </>
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow4(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              show={show5}
              onHide={() => setshow5(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Schedule
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Avaliability:{" "}
                </div>
                <p>{selected?.avaliability}</p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Start Time:{" "}
                </div>
                <p>{selected?.startTime}</p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  End Time:{" "}
                </div>
                <p>{selected?.endTime}</p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Posted By:{" "}
                </div>
                <p>{selected?.propertyowner}</p>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Youtube Link:{" "}
                </div>
                <p> {selected?.youtubeLink}</p>
                {/* <div>Description: {selected?.description} </div> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow5(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="md"
              show={show8}
              onHide={() => setshow8(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Edit description
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    color: "black",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    fontWeight: "600",
                  }}
                >
                  Description
                </div>
                <textarea
                  style={{ width: "90%" }}
                  onChange={(e) => setdescription(e.target.value)}
                  required
                  placeholder={
                    Object.keys(rowdata).length > 0
                      ? rowdata?.propertytype === "PG/Hostel" &&
                        rowdata?.property === "Residential"
                        ? rowdata?.pgdetails[0]?.description
                        : (rowdata?.property === "Commercial" &&
                            rowdata?.propertytype === "Sale") ||
                          (rowdata?.property === "Commercial" &&
                            rowdata?.propertytype === "Rent")
                        ? rowdata?.propertydetails[0].propertyDescription
                        : rowdata?.propertytype === "Plots" &&
                          rowdata?.property === "Residential"
                        ? rowdata?.plotdetails[0]?.description
                        : rowdata?.rentaldetails[0]?.description
                      : "Provide any specific description"
                  }
                ></textarea>
                {/* <div>Description: {selected?.description} </div> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow8(false)}>
                  Close
                </Button>
                <Button variant="secondary" onClick={() => editdata()}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="md"
              show={show7}
              onHide={() => setshow7(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Image
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {selectedimg.length > 0
                    ? selectedimg.map((i) => (
                        <div className="col-3 mb-2">
                          <img
                            src={
                              !i
                                ? "/img/noimgae.jpeg"
                                : "https://houseliv.in/property/" + i
                            }
                            width="75px"
                            height="75px"
                            onClick={() =>
                              window.open(
                                !i
                                  ? "/img/noimgae.jpeg"
                                  : "https://houseliv.in/property/" + i
                              )
                            }
                          />
                        </div>
                      ))
                    : ""}
                </div>
                {/* <div>Description: {selected?.description} </div> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow7(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              show={showex1}
              onHide={() => setshowex1(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Activate Property
                </Modal.Title>
              </Modal.Header>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => activateproperty(selected)}
                >
                  Activate
                </Button>
                <Button variant="dark" onClick={() => setshowex1(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Property;
