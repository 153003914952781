import React, { useEffect } from "react";

import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Tab,
  TabContainer,
} from "react-bootstrap";
import { DropzoneArea } from "material-ui-dropzone";
import { faItalic } from "@fortawesome/free-solid-svg-icons";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Compress from "react-image-file-resizer";

function Pggallery() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [image, setimage] = React.useState([]);
  let arr = [];
  const formdata = new FormData();

  useEffect(() => {
    if(image.length > 0){
      image.map((file, i) => compressimage(file, i))
    }
    }, [image])
  
    const compressimage = (file, i) =>{
      //  arr = [...image1]    
      Compress.imageFileResizer(
        file, // the file from input
        360, // width
        360, // height
        "PNG", // compress format WEBP, JPEG, PNG
        70, // quality
        0, // rotation
        (uri) => {
          console.log(uri);
          console.log(arr)
          arr[i] = uri;
          console.log(arr);
          // setimage1(arr);
          // return uri;
          // You upload logic goes here
        },
        "file" // blob or base64 default base64
      );
    }

  // const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    if(arr.length === image.length){
      formdata.append("propertyId", propertyId);
      // formdata.append("propertyimage", image);
      arr.forEach((item) => formdata.append("propertyimage[]", item));
    // if(image.length<5){
    //   alert("Please select 5 Image")
    // }else if(image.length>10){
    //   alert("select image between 5 to 10")
    // }
    // else{
    let config = {
      url: "/propertyForOwner/uploadproperty",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: formdata,
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/pg_schedule");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  }else{
    alert("Please Sumbit Again")
  }
  // }
  };

  console.log(image);

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Five">
              {/* <Row>
              <Col sm={3}> */}
              <Nav
                variant="pills"
                className="flex-column"
                // style={{ color: "#009587" }}
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Room Details</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">PG Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 mb-5"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3 "
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "18px",
              }}
            >
              Upload Photo
            </Card>{" "}
            <DropzoneArea filesLimit={10} maxFileSize={30000000000} onChange={(file) => setimage(file)} />
            <Row className="mt-5">
              <Col md={5}>
                <hr />
              </Col>
              <Col md={2} style={{ textAlign: "center", color: "graytext" }}>
                <i>Or</i>
              </Col>
              <Col md={5}>
                <hr />
              </Col>
            </Row>
            <Row>
              {" "}
              <Col
                className="mt-2"
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  color: "ThreeDDarkShadow",
                }}
              >
                {" "}
                We can upload photos on your behalf
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={2}></Col>
              <Col md={4}>
                <Card className="ms-1" style={{ height: "100px" }}>
                  <div className="d-flex ms-2">
                    <img
                      src="icon/whatsapp.png"
                      className="mt-2 ms-2"
                      style={{ width: "25px", height: "25px" }}
                    ></img>
                    <p className="ms-2 mt-2"> Whatsapp us on</p>
                  </div>
                  <hr className="mt-0 ms-5" style={{ width: "50%" }} />
                  <div
                    onClick={() => window.open("https://wa.me/+919066555546")}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Row className="ms-4" style={{ fontWeight: "600" }}>
                      +91-9066555546
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="ms-1" style={{ height: "100px" }}>
                  <div className="d-flex ms-2">
                    <img
                      src="icon/email.png"
                      className="mt-2 ms-2"
                      style={{ width: "25px", height: "25px" }}
                    ></img>
                    <p className="ms-3 mt-2"> Email to</p>
                  </div>
                  <hr className="mt-0 ms-5" style={{ width: "50%" }} />
                  <a
                    href={"mailto:${info@houseliv.com}"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Row className="ms-3" style={{ fontWeight: "600" }}>
                      info@houseliv.com
                    </Row>
                  </a>
                </Card>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Card>{" "}
        </Col>
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Pggallery;
