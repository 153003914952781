import { Link } from "@material-ui/core";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";

function Resaleadditional() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [saleDeedCertificate, setsaleDeedCertificate] = React.useState("");
  const [propertyTax, setpropertyTax] = React.useState("");
  const [completionCertificate, setcompletionCertificate] = React.useState("");
  const [occupancyCertificate, setoccupancyCertificate] = React.useState("");
  // const [khataCertificate, setkhataCertificate] = useState("");
  // console.log(khataCertificate);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/updatePropertyAdditionalInformation",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        saleDeedCertificate: saleDeedCertificate,
        propertyTax: propertyTax,
        occupancyCertificate: occupancyCertificate,
        completionCertificate: completionCertificate,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/resale_schedule");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  // console.log(certificate);
  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Six">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Property Details</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">New sale Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Additional Information</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="Seven">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 mb-3"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Additional Information
            </Card>{" "}
            <Row>
              <Col className="mt-4 ms-4">
                <Form validated className="mt-4 ms-4">
                  <Form.Label style={{ fontWeight: "600" }}>
                    Have you paid Property Tax?<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setpropertyTax(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Don't Know">Don't Know</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>

              <Col className="mt-4 ms-4">
                <Form validated className="mt-4 ms-4">
                  <Form.Label style={{ fontWeight: "600" }}>
                    Do You have Occupancy Certificate?<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setoccupancyCertificate(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Don't Know">Don't Know</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 ms-4">
                <Form validated className="mt-4 ms-4">
                  <Form.Label style={{ fontWeight: "600" }}>
                    Do You have Sale Deed Certificate?<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setsaleDeedCertificate(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Don't Know">Don't Know</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              {occupancyCertificate == "No" ||
              occupancyCertificate == "Don't Know" ? (
                <Col className="mt-4 ms-4">
                  <Form validated className="mt-4 ms-4">
                    <Form.Label style={{ fontWeight: "600" }}>
                      Do You have Completion Certificate?<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "250px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) =>
                          setcompletionCertificate(e.target.value)
                        }
                        required
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Don't Know">Don't Know</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Card>{" "}
        </Col>{" "}
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Resaleadditional;
