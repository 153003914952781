import moment from "moment";
import React from "react";

function Adminfooter() {
  return (
    <footer class="main-footer d-flex p-2 px-3 bg-white border-top">
      <div class="copyright ml-auto my-auto mx-auto ">
        <div>Designed & Developed By Parnets </div>
        <div class="ml-5"> Copyright © {moment().format("YYYY")} </div>
      </div>
    </footer>
  );
}

export default Adminfooter;
