import { Link } from "@material-ui/core";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import DatePicker from "react-multi-date-picker";

function Pgdetails() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [genderType, setgenderType] = React.useState(false);
  const [preferredGuests, setpreferredGuests] = React.useState("");
  const [avaliableFrom, setavaliableFrom] = React.useState("");
  const [foodInculded, setfoodInculded] = React.useState(false);
  const [pgAndHostelRules, setpgAndHostelRules] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [apartmentName, setapartmentName] = React.useState("");
  const [gateClosingTime, setgateClosingTime] = React.useState("");

  const [male, setmale] = React.useState(false);
  console.log(male);

  const [female, setfemale] = React.useState(false);
  console.log(female);

  const [yes, setyes] = React.useState(false);
  console.log(yes);

  const [no, setno] = React.useState(false);
  console.log(no);

  const [noSmoking, setnoSmoking] = React.useState(false);
  console.log(noSmoking);

  const [nogirlsEntry, setnogirlsEntry] = React.useState(false);
  console.log(nogirlsEntry);

  const [noboysEntry, setnoboysEntry] = React.useState(false);
  console.log(noboysEntry);

  const [noDrinking, setnoDrinking] = React.useState(false);
  console.log(noDrinking);

  const [noguardiansStay, setnoguardiansStay] = React.useState(false);
  console.log(noguardiansStay);

  const [noNonveg, setnoNonveg] = React.useState(false);
  console.log(noNonveg);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");
  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createPGDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: propertyId,
        genderType: male ? "Male" : female ? "Female"  : "",
        preferredGuests: preferredGuests,
        avaliableFrom: avaliableFrom,
        apartmentName: apartmentName,
        foodInculded: yes ? "Yes" : no ? "No" : "",
        pgAndHostelRules: {
          noNonveg: noNonveg,
          noSmoking: noSmoking,
          noguardiansStay: noguardiansStay,
          noDrinking: noDrinking,
          noboysEntry: noboysEntry,
          nogirlsEntry: nogirlsEntry,
        },
        description: description,
        gateClosingTime: gateClosingTime,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/pg_amenities");
    } else {
      console.log("error");
    }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="Third">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Room Details</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">Locality Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Third">PG Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Five">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Six">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 mb-5"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Provide details about your place
            </Card>{" "}
            <Row className="mt-3 ms-3" style={{ fontWeight: "600" }}>
              <Col>
              <div >
                Available For<span style={{ color: "#dc3545" }}>*</span>
              </div>
              <div className="mt-2">
              {["radio"].map((type) => (
                  <div
                    key={`inline-${type}`}
                    className="mb-3"
                    // style={{ color: "#1b2435" }}
                  >
                    <Form.Check
                      inline
                      label="Male"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      value={male}
                      onChange={() => {
                        setmale(!male);
                        setfemale(false);
                      }}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      name="group1"
                      type={type}
                      id={`inline-${type}-2`}
                      value={female}
                      onChange={() => {
                        setfemale(!female);
                        setmale(false);
                      }}
                    />
                    {/* <Form.Check
                      inline
                      label="Both"
                      name="group1"
                      type={type}
                      id={`inline-${type}-3`}
                      value={others}
                      onChange={() => {
                        setothers(!others);
                        setmale(false);
                        setfemale(false);
                      }}
                    /> */}
                  </div>
                ))}
              </div>
              </Col>
              <Col>
              <div>
              PG/Hostel Name<span style={{ color: "#dc3545" }}>*</span>
              </div>
              <div>
              <Form.Label style={{ fontWeight: "600" }}>                        
                      </Form.Label>
                      <Form.Group as={Col}>
                        <Form.Control
                          type="text"
                          placeholder="Pg/hostel name....."
                          style={{
                            width: "250px",
                            color: "GrayText",
                          }}
                          onChange={(e)=>setapartmentName(e.target.value)}
                          required
                         />
                      </Form.Group>
              </div>
              </Col>
            </Row>
              <Row className="mt-3 ms-3">
                <Col>
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Preferred Guests<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group controlId="formGridState">
                      <select
                        className="form-control "
                        style={{
                          width: "250px",
                          color: "GrayText",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setpreferredGuests(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Working Professional">
                          Working Professional
                        </option>
                        <option value="Student">Student</option>
                        <option value="Both">Both</option>
                      </select>
                    </Form.Group>
                  </Form>
                </Col>
                <Col>
                  <Form validated>
                    <Form.Label style={{ fontWeight: "600" }}>
                      Available From<span style={{ color: "#dc3545" }}>*</span>
                    </Form.Label>
                    <Form.Group>
                      <DatePicker
                        placeholder="yyyy/mm/dd"
                        format="YYYY/MM/DD"
                        style={{
                          width: "250px",
                          height: "38px",
                          // cursor: "pointer",
                        }}
                        onChange={(date) =>
                          setavaliableFrom(date.format("YYYY/MM/DD"))
                        }
                      />
                    </Form.Group>
                  </Form>
                </Col>
             
              <Row
                className="mt-4 "
                style={{ fontWeight: "600" }}
                onChange={(e) => setfoodInculded(e.target.value)}
              >
                <Col>Food Included<span style={{ color: "#dc3545" }}>*</span></Col>
              </Row>
              <Row className="mt-2">
                <Col md={6}>
                  {["radio"].map((type) => (
                    <div
                      key={`inline-${type}`}
                      className="mb-3"
                      style={{ color: "#1b2435" }}
                    >
                      <Form.Check
                        inline
                        label="Yes"
                        name="group2"
                        type={type}
                        id={`inline-${type}-1`}
                        value={yes}
                        onChange={() => setyes(!yes)}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="group2"
                        type={type}
                        id={`inline-${type}-2`}
                        value={no}
                        onChange={() => setno(!no)}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
              <Row className="mt-5 " style={{ fontWeight: "600" }}>
                <Col onChange={(e) => setpgAndHostelRules(e.target.value)}>
                  PG/Hostel Rules
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="mt-2 mb-2">
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="No Smoking"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={noSmoking}
                        onChange={() => setnoSmoking(!noSmoking)}
                      />
                    </div>
                  ))}{" "}
                </Col>

                <Col className="mt-2 mb-1">
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label=" No Guardians Stay"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={noguardiansStay}
                        onChange={() => setnoguardiansStay(!noguardiansStay)}
                      />
                    </div>
                  ))}{" "}
                </Col>
                {male ? (
                  <Col className="mt-2 mb-1">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label=" No Girl's Entry"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={nogirlsEntry}
                          onChange={() => setnogirlsEntry(!nogirlsEntry)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                ) : ("")}
                {female ? (
                  <Col className="mt-2 mb-1">
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label=" No Boy's Entry"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          style={{ backgroundcolor: "#009587" }}
                          value={noboysEntry}
                          onChange={() => setnoboysEntry(!noboysEntry)}
                        />
                      </div>
                    ))}{" "}
                  </Col>
                ) : (
                  ""
                )}
                <Col className="mt-2 mb-1">
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="No Drinking"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={noDrinking}
                        onChange={() => setnoDrinking(!noDrinking)}
                      />
                    </div>
                  ))}{" "}
                </Col>
                <Col className="mt-2 mb-1">
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="No Non-Veg"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        style={{ backgroundcolor: "#009587" }}
                        value={noNonveg}
                        onChange={() => setnoNonveg(!noNonveg)}
                      />
                    </div>
                  ))}{" "}
                </Col>
              </Row>
              <Row className="mt-4" style={{ fontWeight: "600" }}>
                <Col>Gate Closing Time</Col>
              </Row>
              <Row>
                <Form validated>
                <Form.Group controlId="formGridState">
                  <select
                    className="form-control "
                    style={{
                      width: "250px",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                    onChange={(e) => setgateClosingTime(e.target.value)}
                    required
                  >
                    <option value="">Select</option>
                    <option value="09:00 PM">09:00 PM</option>
                    <option value="10:00 PM">10:00 PM</option>
                    <option value="11:00 PM">11:00 PM</option>
                    <option value="12:00 AM">12:00 AM</option>
                  </select>
                </Form.Group>
                </Form>
              </Row>
              <Row className="mt-5 ms-2">
                <Form.Label style={{ fontWeight: "600" }}>
                  Description<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>

                <textarea
                  style={{ width: "90%" }}
                  onChange={(e) => setdescription(e.target.value)}
                  required
                  placeholder="Write a few lines about your property somethings which is special and makes your property stand out.Please do no mention your contact details in any format."
                ></textarea>
              </Row>
            </Row>
          </Card>{" "}
        </Col>{" "}
      </Row>
      <Row>
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      </Row>
    </div>
  );
}

export default Pgdetails;
