import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";

import { Modal, Table, Button, Form, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { EditText, EditTextarea } from "react-edit-text";
import DatePicker from "react-multi-date-picker";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";

function Myproperty() {
  const [data, setdata] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);
  const [show5, setshow5] = useState(false);
  const [show6, setshow6] = useState(false);
  const [show7, setshow7] = useState(false);
  const [show8, setshow8] = useState(false);
  const [show9, setshow9] = useState(false);
  const [show10, setshow10] = useState(false);
  const [edit, setedit] = useState(false);
  const [rowdata, setrowdata] = useState({});
  const [apartmentType, setapartmentType] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [selected, setselected] = useState({});
  const [selectedimg, setselectedimg] = useState([])

  const [startTime, setstartTime] = React.useState("");
  const [endTime, setendTime] = React.useState("");
  const [avaliability, setavaliability] = React.useState("");
  const [youTube, setyouTube] = React.useState("");
  const [propertyowner, setpropertyowner] = React.useState("");
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    getpatient();
  }, []);

  const getpatient = () => {
    try {
      axios
        .get(
          `https://houseliv.in/api/propertyForOwner/getuserPropertyDetails/${user._id}`
        )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.propertyDetail);
            return;
          } else {
            alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  console.log(data);
  // schedule

  const updateschedule = async () => {
    let config = {
      url: "/propertyForOwner/updatePropertySchedule",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: selected._id,
        startTime: startTime,
        endTime: endTime,
        avaliability: avaliability,
        youTube: youTube,
        propertyowner: propertyowner,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
    }
  };
  // locality
  const [city, setcity] = React.useState("");
  const [locality, setlocality] = React.useState("");
  const [streetArea, setstreetArea] = React.useState("");
  const [landmark, setlandmark] = React.useState("");
  const [getcitydata, setgetcitydata] = React.useState([]);

  const updatelocality = async () => {
    let config = {
      url: "/propertyForOwner/updateLocalityDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: selected?.propertyId,
        city: city,
        locality: locality.label,
        streetArea: streetArea,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  console.log(locality);

  useEffect(() => {
    getcity();
  }, []);

  const getcity = () => {
    try {
      axios
        .get("https://houseliv.in/api/propertyForOwner/getAddCity")
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            setgetcitydata(response.data.cityList1);
            return;
          } else {
            alert("Can't able to fetch ");
            return;
          }
        });
    } catch (error) {
      console.log(error.message.data);
      alert("Can't able  to fetch");
      return;
    }
  };
  // pg/hostel

  const [genderType, setgenderType] = React.useState(false);
  const [preferredGuests, setpreferredGuests] = React.useState("");
  const [avaliableFrom, setavaliableFrom] = React.useState("");
  const [foodInculded, setfoodInculded] = React.useState(false);
  const [pgAndHostelRules, setpgAndHostelRules] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [apartmentName, setapartmentName] = React.useState("");
  const [gateClosingTime, setgateClosingTime] = React.useState("");
  const [availableAmenities, setavailableAmenities] = React.useState("");

  const [male, setmale] = React.useState(false);
  console.log(male);

  const [female, setfemale] = React.useState(false);
  console.log(female);

  const [others, setothers] = React.useState(false);
  console.log(others);

  const [yes, setyes] = React.useState(false);
  console.log(yes);

  const [no, setno] = React.useState(false);
  console.log(no);

  const [noSmoking, setnoSmoking] = React.useState(false);
  console.log(noSmoking);

  const [nogirlsEntry, setnogirlsEntry] = React.useState(false);
  console.log(nogirlsEntry);

  const [noboysEntry, setnoboysEntry] = React.useState(false);
  console.log(noboysEntry);

  const [noDrinking, setnoDrinking] = React.useState(false);
  console.log(noDrinking);

  const [noguardiansStay, setnoguardiansStay] = React.useState(false);
  console.log(noguardiansStay);

  const [noNonveg, setnoNonveg] = React.useState(false);
  console.log(noNonveg);

  const [refrigerator, setrefrigerator] = React.useState(false);
  console.log(refrigerator);

  const [powerbackup, setpowerbackup] = React.useState(false);
  console.log(powerbackup);

  const [mess, setmess] = React.useState(false);
  console.log(mess);
  const [cookingAllowed, setcookingAllowed] = React.useState(false);
  console.log(cookingAllowed);
  const [commonTv, setcommonTv] = React.useState(false);
  console.log(commonTv);

  const updatePGdetails = async () => {
    let config = {
      url: "/propertyForOwner/updatePGDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: selected.propertyId,
        genderType: male ? "Male" : female ? "Female" : "",
        preferredGuests: preferredGuests,
        avaliableFrom: avaliableFrom,
        apartmentName: apartmentName,
        foodInculded: yes ? "Yes" : no ? "No" : "",
        pgAndHostelRules: {
          noNonveg: noNonveg,
          noSmoking: noSmoking,
          noguardiansStay: noguardiansStay,
          noDrinking: noDrinking,
          noboysEntry: noboysEntry,
          nogirlsEntry: nogirlsEntry,
        },
        description: description,
        gateClosingTime: gateClosingTime,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  // commecial plot
  const [plotPrice, setplotPrice] = React.useState("");
  const [salesType, setsalesType] = React.useState("");
  const [propertyAge, setpropertyAge] = React.useState("");
  const [totalPlotSize, settotalPlotSize] = React.useState("");
  const [facing, setfacing] = React.useState("");

  const [onmainRoad, setonmainRoad] = React.useState(false);
  console.log(onmainRoad);

  const [cornerProperty, setcornerProperty] = React.useState(false);
  console.log(cornerProperty);
  // plot details

  const updateplot = async () => {
    let config = {
      url: "/propertyForOwner/updateCommericalPlotDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user.id,
        propertyId: selected.propertyId,
        plotPrice: plotPrice,
        salesType: salesType,
        propertyAge: propertyAge,
        totalPlotSize: totalPlotSize,
        description: description,
        facing: facing,
        avaliableFrom: avaliableFrom,
        onmainRoad: onmainRoad,
        cornerProperty: cornerProperty,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  // commercial sale
  const [expectedRent, setexpectedRent] = React.useState("");
  const [ownerShipType, setownerShipType] = React.useState("");
  const [negotiable, setnegotiable] = React.useState(false);

  const [bank, setbank] = React.useState(false);
  console.log(bank);

  const [serviceCenter, setserviceCenter] = React.useState(false);
  console.log(serviceCenter);

  const [showRoom, setshowRoom] = React.useState(false);
  console.log(showRoom);

  const [atm, setatm] = React.useState(false);
  console.log(atm);

  const [retail, setretail] = React.useState(false);
  console.log(retail);

  const updateComsale = async () => {
    let config = {
      url: "/propertyForOwner/updateCommericalResaleDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: selected.propertyId,

        expectedPrice: expectedRent,
        ownerShipType: ownerShipType,
        negotiable: negotiable,
        avaliableFrom: avaliableFrom,
      },
      ideal: {
        bank: bank,
        serviceCenter: serviceCenter,
        showRoom: showRoom,
        atm: atm,
        retail: retail,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  // resi rental for

  const [expectedDeposit, setexpectedDeposit] = React.useState("");
  const [monthlyMaintenance, setmonthlyMaintenance] = React.useState("");
  const [maintenanceAmount, setmaintenanceAmount] = React.useState("");
  const [preferredTenants, setpreferredTenants] = React.useState("");
  const [furnishing, setfurnishing] = React.useState("");
  const [parking, setparking] = React.useState("");

  const [onlyrent, setonlyrent] = React.useState(false);
  console.log(onlyrent);

  const [onlylease, setonlylease] = React.useState(false);
  console.log(onlylease);

  const [onlynegotiable, setonlynegotiable] = React.useState(false);
  console.log(negotiable);

  const updateResirental = async () => {
    let config = {
      url: "/propertyForOwner/updateRentalDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: selected.propertyId,
        expectedRent: expectedRent,
        expectedDeposit: expectedDeposit,
        negotiable: negotiable,
        monthlyMaintenance: monthlyMaintenance,
        maintenanceAmount: maintenanceAmount,
        avaliableFrom: avaliableFrom,
        preferredTenants: preferredTenants,
        furnishing: furnishing,
        parking: parking,
        description: description,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  //  commercial rent rental

  const [leaseDuration, setleaseDuration] = React.useState("");
  const [lockinPeriod, setlockinPeriod] = React.useState("");

  const updateComrentRental = async () => {
    let config = {
      url: "/propertyForOwner/updateCommercialRentalDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: selected.propertyId,
        // propertyType: propertyType,
        expectedRent: expectedRent,
        expectedDeposit: expectedDeposit,
        negotiable: negotiable,

        maintenanceAmount: maintenanceAmount,
        avaliableFrom: avaliableFrom,
        leaseDuration: leaseDuration,
        lockinPeriod: lockinPeriod,
      },
      ideal: {
        bank: bank,
        serviceCenter: serviceCenter,
        showRoom: showRoom,
        atm: atm,
        retail: retail,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  // amenities

  const [bathRooms, setbathRooms] = React.useState("");
  const [balcony, setbalcony] = React.useState("");
  const [waterSupply, setwaterSupply] = React.useState("");
  const [gym, setgym] = React.useState("");
  const [nonVegAllowed, setnonVegAllowed] = React.useState("");
  const [gatedSecurity, setgatedSecurity] = React.useState("");
  const [showingHouse, setshowingHouse] = React.useState("");
  const [secondaryNumber, setsecondaryNumber] = React.useState("");
  const [lift, setlift] = React.useState(false);
  console.log(lift);

  const [internetservices, setinternetservices] = React.useState(false);
  console.log(internetservices);

  const [airconditioner, setairconditioner] = React.useState(false);
  console.log(airconditioner);

  const [clubhouse, setclubhouse] = React.useState(false);
  console.log(clubhouse);

  const [intercom, setintercom] = React.useState(false);
  console.log(intercom);

  const [swimmingpool, setswimmingpool] = React.useState(false);
  console.log(swimmingpool);

  const [childrenplayarea, setchildrenplayarea] = React.useState(false);
  console.log(childrenplayarea);

  const [firesafety, setfiresafety] = React.useState(false);
  console.log(firesafety);

  const [servantroom, setservantroom] = React.useState(false);
  console.log(servantroom);

  const [shoppingcenter, setshoppingcenter] = React.useState(false);
  console.log(shoppingcenter);

  const [gaspipeline, setgaspipeline] = React.useState(false);
  console.log(gaspipeline);

  const [park, setpark] = React.useState(false);
  console.log(park);

  const [rainwaterharvesting, setrainwaterharvesting] = React.useState(false);
  console.log(rainwaterharvesting);

  const [sewagetreatmentplant, setsewagetreatmentplant] = React.useState(false);
  console.log(sewagetreatmentplant);

  const [housekeeping, sethousekeeping] = React.useState(false);
  console.log(housekeeping);

  const [wifi, setwifi] = React.useState(false);
  console.log(wifi);

  const [visitorparking, setvisitorparking] = React.useState(false);
  console.log(visitorparking);

  const updateamenities = async () => {
    let config = {
      url: "/propertyForOwner/updateAmenitiesDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: selected.propertyId,
        bathRooms: bathRooms,
        balcony: balcony,
        waterSupply: waterSupply,
        gym: gym,
        nonVegAllowed: nonVegAllowed,
        gatedSecurity: gatedSecurity,
        showingHouse: showingHouse,
        secondaryNumber: secondaryNumber,
        availableAmenities: {
          lift: lift,
          internetservices: internetservices,
          airconditioner: airconditioner,
          clubhouse: clubhouse,
          intercom: intercom,
          wifi: wifi,
          mess: mess,
          commonTv: commonTv,
          refrigerator: refrigerator,
          cookingAllowed: cookingAllowed,
          swimmingpool: swimmingpool,
        },
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  // commercial rent sale amenities
  const [powerBackUp, setpowerBackUp] = React.useState("");
  // const [lift, setlift] = React.useState("");
  // const [parking, setparking] = React.useState("");
  // const [bathRooms, setbathRooms] = React.useState("");
  const [reserved, setreserved] = React.useState("");
  const [security, setsecurity] = React.useState("");
  // const [wifi, setwifi] = React.useState("");
  // const [waterSupply, setwaterSupply] = React.useState("");

  const updateCommAmenities = async () => {
    let config = {
      url: "/propertyForOwner/updateCommericalAmenitiesDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user._id,
        propertyId: selected.propertyId,
        powerBackUp: powerBackUp,
        lift: lift,
        parking: parking,
        bathRooms: bathRooms,
        reserved: reserved,
        security: security,
        wifi: wifi,
        waterSupply: waterSupply,
      },
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  //   useEffect(() => {getuserproperty();}, []);

  //   const getuserproperty =  () => {
  //     try{
  //       axios
  //       .get('https://houseliv.in/api/propertyForOwner/getallPropertyDetails').then(function (response) {
  //         if (response.status === 200) {
  //           console.log(response.data);
  //           setgetuserpropertydata(response.data.cityList1);
  //           return;
  //         } else {
  //           alert("Can't able to fetch ");
  //           return;
  //         }
  //       })
  //     }
  //     catch(error) {
  //     console.log(error.message.data);
  //     alert("Can't able  to fetch");
  //     return ;
  //     };
  // };

  const deleteuserproperty = async (propertydetails) => {
    let propertyId = propertydetails._id;
    let config = {
      url: "/propertyForOwner/deleteUserProperty",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        propertyId: propertyId,
      },
    };
    let res = await axios(config);

    if (res.status == 200) {
      alert("Property Deleted");
      window.location.reload();
    } else {
      console.log("error");
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ],
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`https://houseliv.in/property/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }

  const columns = [
    {
      dataField: "user[0].fullName",
      text: "Name",
      sort: true,
    },
    {
      dataField: "user[0].emailId",
      text: "Email",
      sort: true,
    },
    {
      dataField: "user[0].mobilePhone",
      text: "Mobile No",
    },
    {
      dataField: "property",
      text: "Property",
    },
    {
      dataField: "propertytype",
      text: "Property Type",
    },
    {
      dataField: "",
      text: "Property Details",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn"
              onClick={() => {
                setshow(true);
                setselected(row.propertydetails.length > 0 ? row.propertydetails[0] : {});
                setrowdata(row.propertydetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={row.propertytype === "Plots"}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "PG Details",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn"
              onClick={() => {
                setshow6(true);
                setselected(row.pgdetails.length > 0 ? row.pgdetails[0] : {});
                setrowdata(row.pgdetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={row.propertytype !== "PG/Hostel"}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Locality",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {" "}
            <button
              className="btn"
              onClick={() => {
                setshow1(true);
                setselected(row.localitydetails.length > 0 ? row.localitydetails[0] : {});
                setrowdata(row.localitydetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Plot Details",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {" "}
            <button
              className="btn"
              onClick={() => {
                setshow2(true);
                setselected(row.plotdetails.length > 0 ? row.plotdetails[0] : {});
                setrowdata(row.plotdetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={row.propertytype !== "Plots"}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Sale Details",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn"
              onClick={() => {
                setshow3(true);
                setselected(row.rentaldetails.length > 0 ? row.rentaldetails[0] : {});
                setrowdata(row.rentaldetails.length > 0 ? row : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={
                row.propertytype === "Plots" ||
                row.propertytype === "PG/Hostel" ||
                row.propertytype === "Sale"
              }
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "New Sale",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn"
              onClick={() => {
                setshow9(true);
                setrowdata(row?.resaledetails.length > 0 ? row : {});
                setselected(row?.resaledetails.length > 0 ? row?.resaledetails[0] : {});
              }}
              style={{ background: "#1b2435", color: "white" }}
              disabled={
                row.propertytype === "Plots" ||
                row.propertytype === "PG/Hostel" ||
                row.propertytype === "Rent" ||
                row.propertytype === "Resale" ||
                row.propertytype === "Flatmates"
              }
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Amenities",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn"
              onClick={() => {
                setshow4(true);
                setselected(row.amenitiesdetails.length > 0 ? row.amenitiesdetails[0] : {});
                setrowdata(row.amenitiesdetails.length > 0 ? row : {});
              }}
              disabled={row.propertytype === "Plots"}
              style={{ background: "#1b2435", color: "white" }}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "propertydetails[0].propertyimage[0]",
      text: "Gallery",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn active1"
              onClick={() => {
                setshow7(true);
                setselectedimg(row.propertydetails[0].propertyimage);
                setrowdata(row);
              }}
              // disabled={row.propertytype === "Plots"}
              style={{ background: "#1b2435", color: "white" }}
            >
              View
            </button>
          </div>
        )},
    },

    {
      dataField: "",
      text: "Schedule",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {" "}
            <button
              className="btn"
              onClick={() => {
                setshow5(true);
                setrowdata(row);
                setselected(row);
              }}
              style={{ background: "#1b2435", color: "white" }}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      dataField: "approved",
      text: "Status",
    },

    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {" "}
            <button
              className="btn"
              onClick={() => {
                setshow10(true);
                deleteuserproperty(row);
                setselected(row);
              }}
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                borderColor: "#dc3545",
              }}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  console.log(selected);

  return (
    <div style={{ background: "#f3f3f3" }}>
      <Row>
        <Col md={3}>
          <ProSidebar>
            <Menu iconShape="square">
              <MenuItem>
                My Account
                <Link to="/profile" />
              </MenuItem>
              <MenuItem
                active
                style={{
                  backgroundColor: "#dc3545",
                  fontSize: "18px",
                  color: "white",
                }}
              >
                Posted Properties
                <Link to="/myproperties" />
              </MenuItem>
              <MenuItem>
                Change Password
                <Link to="/change_pwd" />
              </MenuItem>
            </Menu>
          </ProSidebar>
        </Col>
        <Col md={8}>
          <div className="">
            <h4 className="pt-3 pb-3" style={{ color: "#1b2435" }}>
              My Posted Property
            </h4>
            <div className="container mb-3">
              <div className="card card-body">
                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={columns}
                  search
                  exportCSV={{
                    fileName: "properties.csv",
                    blobType: "text/csv;charset=ansi",
                  }}
                >
                  {(props) => (
                    <div>
                      <span className="ps-5 ml-5 mt-3">
                        <SearchBar
                          className="form-control "
                          {...props.searchProps}
                        />
                      </span>
                      {"      "}
                      <ClearSearchButton
                        className="btn-outline-info ms-5 "
                        {...props.searchProps}
                      />
                      <hr />
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField="patient"
                          responsive
                          hover
                          columns={columns}
                          data={data}
                          pagination={paginationFactory(options)}
                          {...props.baseProps}
                        />
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
                <Modal
                  size="sm"
                  show={show}
                  onHide={() => setshow(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                  // backdrop="static"
                  // keyboard={false}
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Property Details
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <>
                      {selected?.singleRoom == "true" ||
                      selected?.threeRoom == "true" ||
                      selected?.fourRoom == "true" ||
                      selected?.doubleRoom == "true" ? (
                        <>
                          {selected?.singleRoom == "true" ? (
                            <div>
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                  fontWeight: "600",
                                }}
                              >
                                Rent for single room :{" "}
                              </span>{" "}
                              {edit ? (
                                <Form style={{ width: "50%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      selected.expectedRentsingleroom
                                    }
                                  ></Form.Control>
                                </Form>
                              ) : (
                                selected.expectedRentsingleroom
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {selected?.singleRoom == "true" ? (
                            <div>
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                  fontWeight: "600",
                                }}
                              >
                                Deposit for single room :{" "}
                              </span>{" "}
                              {edit ? (
                                <Form style={{ width: "50%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      selected.expectedDepositsingleroom
                                    }
                                  ></Form.Control>
                                </Form>
                              ) : (
                                selected.expectedDepositsingleroom
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {selected?.doubleRoom == "true" ? (
                            <div>
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                  fontWeight: "600",
                                }}
                              >
                                Rent for Double room :{" "}
                              </span>{" "}
                              {edit ? (
                                <Form style={{ width: "50%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      selected.expectedRentdoubleroom
                                    }
                                  ></Form.Control>
                                </Form>
                              ) : (
                                selected.expectedRentdoubleroom
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {selected?.doubleRoom == "true" ? (
                            <div>
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                  fontWeight: "600",
                                }}
                              >
                                Deposit for Double room :{" "}
                              </span>{" "}
                              {edit ? (
                                <Form style={{ width: "50%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      selected.expectedDepositdoubleroom
                                    }
                                  ></Form.Control>
                                </Form>
                              ) : (
                                selected.expectedDepositdoubleroom
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {selected?.threeRoom == "true" ? (
                            <div>
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                  fontWeight: "600",
                                }}
                              >
                                Rent for Three room :{" "}
                              </span>{" "}
                              {edit ? (
                                <Form style={{ width: "50%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={selected.expectedRentthreeroom}
                                  ></Form.Control>
                                </Form>
                              ) : (
                                selected.expectedRentthreeroom
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {selected?.threeRoom == "true" ? (
                            <div>
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                  fontWeight: "600",
                                }}
                              >
                                Deposit for Three room :{" "}
                              </span>{" "}
                              {edit ? (
                                <Form style={{ width: "50%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      selected.expectedDepositthreeroom
                                    }
                                  ></Form.Control>
                                </Form>
                              ) : (
                                selected.expectedDepositthreeroom
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {selected?.fourRoom == "true" ? (
                            <div>
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                  fontWeight: "600",
                                }}
                              >
                                Rent for Four room :{" "}
                              </span>{" "}
                              {edit ? (
                                <Form style={{ width: "50%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={selected.expectedRentfourroom}
                                  ></Form.Control>
                                </Form>
                              ) : (
                                selected.expectedRentfourroom
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {selected?.fourRoom == "true" ? (
                            <div>
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontFamily: "system-ui",
                                  fontWeight: "600",
                                }}
                              >
                                Deposit for Four room :{" "}
                              </span>{" "}
                              {edit ? (
                                <Form style={{ width: "50%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      selected.expectedDepositfourroom
                                    }
                                  ></Form.Control>
                                </Form>
                              ) : (
                                selected.expectedDepositfourroom
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : ("")}
                      {
                        (rowdata?.property === "Residential" &&
                        rowdata?.propertytype === "Flatmates") ||
                      (rowdata?.property === "Residential" &&
                        rowdata?.propertytype === "Rent") ||
                      (rowdata?.property === "Residential" &&
                        rowdata?.propertytype === "Resale") ? (
                          <>
                          <div>
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Apartment Type :{" "}
                            </span>
                            {edit ? (
                              <select
                                className="form-control "
                                style={{
                                  width: "150px",
                                  color: "GrayText",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Apartment">Apartment</option>
                                <option value="Independent House/Villa">
                                  Independent House/Villa
                                </option>
                                <option value="Gated Community Villa">
                                  Gated Community Villa
                                </option>
                              </select>
                            ) : selected != {} ? (
                              selected.apartmentType
                            ) : (
                              ""
                            )}{" "}
                          </div>

                          <div>
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              ApartmentName
                            </span>{" "}
                            :{" "}
                            {edit ? (
                              <Form style={{ width: "50%" }}>
                                <Form.Control
                                  type="text"
                                  placeholder={selected.apartmentName}
                                ></Form.Control>
                              </Form>
                            ) : selected != {} ? (
                              selected.apartmentName
                            ) : (
                              ""
                            )}{" "}
                          </div>

                          <div>
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              BHK Type :{" "}
                            </span>{" "}
                            {edit ? (
                              <select
                                className="form-control "
                                style={{
                                  width: "150px",
                                  color: "GrayText",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="1 RK">1 RK</option>
                                <option value="1 BHK">1 BHK</option>
                                <option value="2 BHK">2 BHK</option>
                                <option value="3 BHK">3 BHK</option>
                                <option value="4 BHK">4 BHK</option>
                                <option value="4 BHK+">4 BHK+</option>
                              </select>
                            ) : selected != {} ? (
                              selected.bhkType
                            ) : (
                              ""
                            )}{" "}
                          </div>

                          <div>
                            {" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Facing :{" "}
                            </span>{" "}
                            {edit ? (
                              <select
                                className="form-control "
                                style={{
                                  width: "150px",
                                  color: "GrayText",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="North">North</option>
                                <option value="South">South </option>
                                <option value="East">East</option>
                                <option value="West">West</option>
                                <option value="North-East">North-East</option>
                                <option value="South-East">South-East</option>
                                <option value="North-West">North-West</option>
                                <option value="South-West">South-West</option>
                                <option value="Don't Know">Don't Know</option>
                              </select>
                            ) : selected != {} ? (
                              selected.facing
                            ) : (
                              ""
                            )}{" "}
                          </div>

                          <div>
                            {" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Floor :{" "}
                            </span>{" "}
                            {edit ? (
                              <select
                                className="form-control "
                                style={{
                                  width: "150px",
                                  color: "GrayText",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Lower Basement">
                                  Lower Basement
                                </option>
                                <option value="Upper Basement">
                                  Upper Basement{" "}
                                </option>
                                <option value="Ground">Ground </option>
                                <option value="1">1 </option>
                                <option value="2">2 </option>
                                <option value="3">3 </option>
                                <option value="4">4 </option>
                                <option value="5">5 </option>
                                <option value="Full Building">
                                  Full Building{" "}
                                </option>
                              </select>
                            ) : selected != {} ? (
                              selected.floor
                            ) : (
                              ""
                            )}{" "}
                          </div>
                          <div>
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              PropertyAge :{" "}
                            </span>{" "}
                            {edit ? (
                              <select
                                className="form-control "
                                style={{
                                  width: "150px",
                                  color: "GrayText",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Under Construction">
                                  Under Construction
                                </option>
                                <option value="Less than a year">
                                  Less than a year
                                </option>
                                <option value="1 to 3 years">
                                  1 to 3 years
                                </option>
                                <option value="3 to 5 years">
                                  3 to 5 years
                                </option>
                                <option value="5 to 10 years">
                                  5 to 10 years
                                </option>
                                <option value="More than 10 years"></option>
                              </select>
                            ) : selected != {} ? (
                              selected.propertyAge
                            ) : (
                              ""
                            )}{" "}
                          </div>
                          <div>
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Property Size :{" "}
                            </span>{" "}
                            {edit ? (
                              <Form style={{ width: "50%" }}>
                                <Form.Control
                                  type="text"
                                  placeholder={selected.propertySize}
                                ></Form.Control>
                              </Form>
                            ) : selected != {} ? (
                              selected.propertySize
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Total Floor :{" "}
                            </span>{" "}
                            {edit ? (
                              <select
                                className="form-control "
                                style={{
                                  width: "150px",
                                  color: "GrayText",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Lower Basement">
                                  Lower Basement
                                </option>
                                <option value="Upper Basement">
                                  Upper Basement{" "}
                                </option>
                                <option value="Ground">Ground </option>
                                <option value="1">1 </option>
                                <option value="2">2 </option>
                                <option value="3">3 </option>
                                <option value="4">4 </option>
                                <option value="5">5 </option>
                                <option value="Full Building">
                                  Full Building{" "}
                                </option>
                              </select>
                            ) : selected != {} ? (
                              selected.totalFloor
                            ) : (
                              ""
                            )}{" "}
                          </div>
                        </>
                        ): ("")}  
                        {
                          (rowdata?.property === "Commercial" &&
                          rowdata?.propertytype === "Rent") ||
                        (rowdata?.property === "Commercial" &&
                          rowdata?.propertytype === "Sale") ? (
                            <>
                             <div>
                            {" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Property Type: {" "}
                            </span>{" "}
                            {edit ? (
                             <select
                             className="form-control "
                             style={{
                               width: "250px",
                               color: "GrayText",
                               cursor: "pointer",
                             }}                             
                           >
                             <option value="">Select</option>
                             <option value="Showroom">Showroom</option>
                             <option value="Office Space">Office Space</option>
                             <option value="Co-Working">Co-Working</option>
                             <option value="Shop">Shop</option>
                             <option value="Godown/Warehouse">Godown/Warehouse</option>
                             <option value="Industrial Shed">Industrial Shed</option>
                             <option value="Industrial Building">
                               Industrial Building
                             </option>
                             <option value="Other Business">Other Business</option>
                             <option value="Restaurant">Restaurant</option>
                           </select>
                            ) : selected != {} ? (
                              
                              selected.propertyType
                            ) : (
                              ""
                            )}{" "}
                          </div>
                          <div>
                            {" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Building Type: {" "}
                            </span>{" "}
                            {edit ? (
                             <select
                             className="form-control "
                             style={{
                               width: "250px",
                               color: "GrayText",
                               cursor: "pointer",
                             }}
                           >
                             <option value="">Select</option>
                             <option value="Independent House">Independent House</option>
                             <option value="Bussiness Park">Business Park</option>
                             <option value="Mall">Mall</option>
                             <option value="Independent Shop">Independent Shop</option>
                             <option value="Standalone Building">
                               Standalone Building
                             </option>
                           </select>
                            ) : selected != {} ? (
                              
                              selected.buildingType
                            ) : (
                              ""
                            )}{" "}
                          </div>
                          <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Age of Property :{" "}
                      </span>{" "}
                      {edit ? (
                        <select
                          className="form-control "
                          style={{
                            width: "150px",
                            color: "GrayText",
                          }}
                          onChange={(e) => setpropertyAge(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="Less than a year">
                            Less than a year
                          </option>
                          <option value="1 to 5 years">1 to 5 years</option>
                          <option value="5 to 10 years">5 to 10 years</option>
                          <option value="More than 10 years">
                            More than 10 years{" "}
                          </option>
                        </select>
                      ) : (
                        selected?.propertyAge
                      )}{" "}
                    </div>
                    <div>
                            {" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Floor :{" "}
                            </span>{" "}
                            {edit ? (
                              <select
                                className="form-control "
                                style={{
                                  width: "150px",
                                  color: "GrayText",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Lower Basement">
                                  Lower Basement
                                </option>
                                <option value="Upper Basement">
                                  Upper Basement{" "}
                                </option>
                                <option value="Ground">Ground </option>
                                <option value="1">1 </option>
                                <option value="2">2 </option>
                                <option value="3">3 </option>
                                <option value="4">4 </option>
                                <option value="5">5 </option>
                                <option value="Full Building">
                                  Full Building{" "}
                                </option>
                              </select>
                            ) : selected != {} ? (
                              selected.floor
                            ) : (
                              ""
                            )}{" "}
                          </div>
                          <div>
                            {" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                             Total Floor :{" "}
                            </span>{" "}
                            {edit ? (
                              <select
                                className="form-control "
                                style={{
                                  width: "150px",
                                  color: "GrayText",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Lower Basement">
                                  Lower Basement
                                </option>
                                <option value="Upper Basement">
                                  Upper Basement{" "}
                                </option>
                                <option value="Ground">Ground </option>
                                <option value="1">1 </option>
                                <option value="2">2 </option>
                                <option value="3">3 </option>
                                <option value="4">4 </option>
                                <option value="5">5 </option>
                                <option value="Full Building">
                                  Full Building{" "}
                                </option>
                              </select>
                            ) : selected != {} ? (
                              selected.totalFloor
                            ) : (
                              ""
                            )}{" "}
                          </div>
                          <div>
                            <span
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontFamily: "system-ui",
                                fontWeight: "600",
                              }}
                            >
                              Builtup Area :{" "}
                            </span>{" "}
                            {edit ? (
                              <Form style={{ width: "50%" }}>
                                <Form.Control
                                  type="text"
                                  placeholder={selected.propertySize}
                                ></Form.Control>
                              </Form>
                            ) : selected != {} ? (
                              selected.builtUpArea 
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Furnishing :{" "}
                          </span>{" "}
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setfurnishing(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Fully-furnishing">
                                Fully-furnishing
                              </option>
                              <option value="Semi-furnishing">
                                Semi-furnishing
                              </option>
                              <option value="Unfurnishing">Unfurnishing</option>
                            </select>
                          ) : (
                            selected?.furnishing
                          )}{" "}
                        </div>
                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Who will show the Property? :{" "}
                          </span>{" "}
                          {edit ? (
                            <select
                            className="form-control "
                            style={{
                              width: "150px",
                              color: "GrayText",
                              cursor: "pointer",
                            }}
                            // onChange={(e) => setpropertyShow(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="Need Help">Need Help</option>
                            <option value="I will show">I will show</option>
                            <option value="Neighbours">Neighbours</option>
                            <option value="Friends/Relatives">
                              Friends/Relatives
                            </option>
                            <option value="Security">Security</option>
                            <option value="Tenants">Tenants</option>
                          </select>
                          ) : (
                            selected?.propertyShow
                          )}{" "}
                        </div>
                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                          Property  Description :{" "}
                          </span>{" "}
                          {edit ? (
                            <textarea
                              // onChange={(e) => setdescription(e.target.value)}
                            ></textarea>
                          ) : (
                            selected?.propertyDescription
                          )}{" "}
                        </div>                       
                            </>
                          ):("")
                        }                    
                      
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setshow(false);
                        setedit(false);
                      }}
                    >
                      Close
                    </Button>
                    {edit ? (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => {
                          setshow(false);
                          setedit(false);
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => setedit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="sm"
                  show={show1}
                  onHide={() => setshow1(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Locality
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        City :{" "}
                      </span>{" "}
                      {edit ? (
                        <Form style={{ width: "50%" }}>
                          <select
                            className="form-control "
                            style={{
                              width: "250px",
                              color: "GrayText",
                              cursor: "pointer",
                            }}
                            onChange={(e) => setcity(e.target.value)}
                            // value={selected.city}
                          >
                            {selected ? (
                              <option value={selected.city}>
                                {selected.city}
                              </option>
                            ) : (
                              <option value={selected.city}>Select</option>
                            )}
                            <option value={selected.city}>Select</option>
                            {getcitydata.map((data) => (
                              <option value={data.city}>{data.city}</option>
                            ))}
                          </select>
                        </Form>
                      ) : (
                        selected?.city
                      )}{" "}
                    </div>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Locality :{" "}
                      </span>{" "}
                      {edit ? (
                        <Form style={{ width: "100%" }}>
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyBSSh2iC9t7FJExi9-EwGXfcoq0LmTRp7M"
                            selectProps={{
                              // onChange={(e) => setlocality(e.target.value)}
                              isClearable: true,
                              value: locality,
                              onChange: (val) => {
                                setlocality(val);
                              },
                            }}
                          />
                        </Form>
                      ) : (
                        selected?.locality
                      )}{" "}
                    </div>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Street Area :{" "}
                      </span>{" "}
                      {edit ? (
                        <Form style={{ width: "50%" }}>
                          <Form.Control
                            type="text"
                            placeholder={selected.streetArea}
                            style={{
                              width: "250px",
                              color: "GrayText",
                            }}
                            onChange={(e) => setstreetArea(e.target.value)}
                          ></Form.Control>
                        </Form>
                      ) : (
                        selected?.streetArea
                      )}{" "}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setshow1(false);
                        setedit(false);
                      }}
                    >
                      Close
                    </Button>
                    {edit ? (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => {
                          setshow1(false);
                          setedit(false);
                          updatelocality();
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => setedit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="sm"
                  show={show6}
                  onHide={() => setshow6(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      PG Details
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Gender Type :{" "}
                      </span>{" "}
                      {edit ? (
                        <div className="mb-3" style={{ color: "#1b2435" }}>
                           <Form style={{ width: "50%", fontWeight: "600" }}>
                          {["radio"].map((type) => (
                            <div
                              key={`inline-${type}`}
                              className="mb-3"
                              style={{ color: "#1b2435" }}
                            >
                              <Form.Check
                                inline
                                label="Male"
                                name="group2"
                                type={type}
                                id={`inline-${type}-1`}
                                value={male}
                                onChange={() => {
                                  setmale(!male);
                                  setfemale(false);
                                  setothers(false);
                                }}
                              />
                              <Form.Check
                                inline
                                label="Female"
                                name="group2"
                                type={type}
                                id={`inline-${type}-2`}
                                value={female}
                                onChange={() => {
                                  setfemale(!female);
                                  setmale(false);
                                  setothers(false);
                                }}
                              />
                               <Form.Check
                                inline
                                label="Both"
                                name="group2"
                                type={type}
                                id={`inline-${type}-3`}
                                value={others}
                            onChange={() => {
                              setothers(!others);
                              setmale(false);
                              setfemale(false);
                            }}
                              />
                            </div>
                          ))}
                        </Form>                          
                        </div>
                      ) : (
                        selected.genderType
                      )}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Preferred Guests :{" "}
                      </span>{" "}
                      {edit ? (
                        <select
                          className="form-control "
                          style={{
                            width: "150px",
                            color: "GrayText",
                          }}
                          onChange={(e) => setpreferredGuests(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="Working Professional">
                            Working Professional
                          </option>
                          <option value="Student">Student</option>
                          <option value="Both">Both</option>
                        </select>
                      ) : (
                        selected.preferredGuests
                      )}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Avaliable From :{" "}
                      </span>{" "}
                      {edit ? (
                        <Form style={{ width: "50%", fontWeight: "600" }}>
                          <Form.Group>
                            <DatePicker
                              placeholder={selected.avaliableFrom}
                              format="YYYY/MM/DD"
                              style={{
                                width: "250px",
                                height: "38px",
                              }}
                              onChange={(date) =>
                                setavaliableFrom(date.format("YYYY/MM/DD"))
                              }
                            />
                          </Form.Group>
                        </Form>
                      ) : (
                        selected?.avaliableFrom
                      )}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Food Inculded :{" "}
                      </span>{" "}
                      {edit ? (
                        <Form style={{ width: "50%", fontWeight: "600" }}>
                          {["radio"].map((type) => (
                            <div
                              key={`inline-${type}`}
                              className="mb-3"
                              style={{ color: "#1b2435" }}
                            >
                              <Form.Check
                                inline
                                label="Yes"
                                name="group2"
                                type={type}
                                id={`inline-${type}-1`}
                                value={yes}
                                onChange={() => setyes(!yes)}
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="group2"
                                type={type}
                                id={`inline-${type}-2`}
                                value={no}
                                onChange={() => setno(!no)}
                              />
                            </div>
                          ))}
                        </Form>
                      ) : (
                        selected?.foodInculded
                      )}
                    </div>

                    <div style={{ fontWeight: "600" }}>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Description
                      </span>
                    </div>
                    {edit ? (
                      <textarea
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    ) : (
                      selected?.description
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setshow6(false);
                        setedit(false);
                      }}
                    >
                      Close
                    </Button>
                    {edit ? (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => {
                          setshow6(false);
                          setedit(false);
                          updatePGdetails();
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => setedit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="sm"
                  show={show2}
                  onHide={() => setshow2(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Plot Details
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Plot Size :{" "}
                      </span>{" "}
                      {edit ? (
                        <Form style={{ width: "50%" }}>
                          <Form.Control
                            type="text"
                            placeholder={selected.totalPlotSize}
                            // placeholder="sq.ft"
                            onChange={(e) => settotalPlotSize(e.target.value)}
                          ></Form.Control>
                        </Form>
                      ) : (
                        selected?.totalPlotSize
                      )}{" "}
                      Sq.ft
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Sales Type :{" "}
                      </span>{" "}
                      {edit ? (
                        <select
                          className="form-control "
                          style={{
                            width: "150px",
                            color: "GrayText",
                          }}
                          onChange={(e) => setsalesType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="New Property">New Property</option>
                          <option value="Resale">Resale</option>
                        </select>
                      ) : (
                        selected?.salesType
                      )}{" "}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Plot Price :{" "}
                      </span>{" "}
                      ₹{" "}
                      {edit ? (
                        <Form style={{ width: "50%" }}>
                          <Form.Control
                            type="text"
                            placeholder={selected.plotPrice}
                            onChange={(e) => setplotPrice(e.target.value)}
                          ></Form.Control>
                        </Form>
                      ) : (
                        selected?.plotPrice
                      )}{" "}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Age of Property :{" "}
                      </span>{" "}
                      {edit ? (
                        <select
                          className="form-control "
                          style={{
                            width: "150px",
                            color: "GrayText",
                          }}
                          onChange={(e) => setpropertyAge(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="Less than a year">
                            Less than a year
                          </option>
                          <option value="1 to 5 years">1 to 5 years</option>
                          <option value="5 to 10 years">5 to 10 years</option>
                          <option value="More than 10 years">
                            More than 10 years{" "}
                          </option>
                        </select>
                      ) : (
                        selected?.propertyAge
                      )}{" "}
                    </div>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Facing :{" "}
                      </span>{" "}
                      {edit ? (
                        <select
                          className="form-control "
                          style={{
                            width: "150px",
                            color: "GrayText",
                          }}
                          onChange={(e) => setfacing(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="North">North</option>
                          <option value="South">South </option>
                          <option value="East">East</option>
                          <option value="West">West</option>
                          <option value="North-East">North-East</option>
                          <option value="South-East">South-East</option>
                          <option value="North-West">North-West</option>
                          <option value="South-West">South-West</option>
                          <option value="Don't Know">Don't Know</option>
                        </select>
                      ) : (
                        selected?.facing
                      )}{" "}
                    </div>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Avaliable From :{" "}
                      </span>{" "}
                      {edit ? (
                        <Form style={{ width: "50%", fontWeight: "600" }}>
                          <Form.Group>
                            <DatePicker
                              placeholder={selected.avaliableFrom}
                              format="YYYY/MM/DD"
                              style={{
                                width: "250px",
                                height: "38px",
                              }}
                              onChange={(date) =>
                                setavaliableFrom(date.format("YYYY/MM/DD"))
                              }
                            />
                          </Form.Group>
                        </Form>
                      ) : (
                        selected?.avaliableFrom
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setshow2(false);
                        setedit(false);
                      }}
                    >
                      Close
                    </Button>
                    {edit ? (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => {
                          setshow2(false);
                          setedit(false);
                          updateplot();
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => setedit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="sm"
                  show={show3}
                  onHide={() => setshow3(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Rental
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {(rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "Rent") ||
                    (rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "Resale") ||
                    (rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "Flatmates") ? (
                      <>
                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Rent :{" "}
                          </span>{" "}
                          ₹{" "}
                          {edit ? (
                            <Form style={{ width: "50%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.expectedRent}
                                onChange={(e) =>
                                  setexpectedRent(e.target.value)
                                }
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.expectedRent
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Deposit :{" "}
                          </span>{" "}
                          ₹{" "}
                          {edit ? (
                            <Form style={{ width: "50%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.expectedDeposit}
                                onChange={(e) =>
                                  setexpectedDeposit(e.target.value)
                                }
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.expectedDeposit
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Monthly Maintenance :{" "}
                          </span>{" "}
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) =>
                                setmonthlyMaintenance(e.target.value)
                              }
                            >
                              <option value="">Select</option>
                              <option value="Maintenance Included">
                                Maintenance Included
                              </option>
                              <option value="Maintenance Extra">
                                Maintenance Extra
                              </option>
                            </select>
                          ) : (
                            selected?.monthlyMaintenance
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Maintenance Amount :{" "}
                          </span>{" "}
                          ₹{" "}
                          {edit ? (
                            <Form style={{ width: "50%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.maintenanceAmount}
                                onChange={(e) =>
                                  setmaintenanceAmount(e.target.value)
                                }
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.maintenanceAmount
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Peferred Tenants :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) =>
                                setpreferredTenants(e.target.value)
                              }
                            >
                              <option value="">Select</option>
                              <option value="Doesn't Matter">
                                Doesn't Matter
                              </option>
                              <option value="Family">Family </option>
                              <option value="Bachelors">Bachelors</option>
                              <option value="Company">Company</option>
                            </select>
                          ) : (
                            selected?.preferredTenants
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Parking :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setparking(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Bike">Bike</option>
                              <option value="Car">Car</option>
                              <option value="Both">Both</option>
                              <option value="None">None</option>
                            </select>
                          ) : (
                            selected?.parking
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Furnishing :{" "}
                          </span>{" "}
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setfurnishing(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Fully-furnishing">
                                Fully-furnishing
                              </option>
                              <option value="Semi-furnishing">
                                Semi-furnishing
                              </option>
                              <option value="Unfurnishing">Unfurnishing</option>
                            </select>
                          ) : (
                            selected?.furnishing
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Description :{" "}
                          </span>{" "}
                          {edit ? (
                            <textarea
                              onChange={(e) => setdescription(e.target.value)}
                            ></textarea>
                          ) : (
                            selected?.description
                          )}{" "}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {rowdata?.propertytype === "Rent" &&
                    rowdata?.property === "Commercial" ? (
                      <>
                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Expected Amount :{" "}
                          </span>{" "}
                          ₹{" "}
                          {edit ? (
                            <Form style={{ width: "50%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.expectedRent}
                                onChange={(e) =>
                                  setexpectedRent(e.target.value)
                                }
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.expectedRent
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Expected Deposit :{" "}
                          </span>{" "}
                          ₹{" "}
                          {edit ? (
                            <Form style={{ width: "50%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.expectedDeposit}
                                onChange={(e) =>
                                  setexpectedDeposit(e.target.value)
                                }
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.expectedDeposit
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Maintenance Amount :{" "}
                          </span>{" "}
                          ₹{" "}
                          {edit ? (
                            <Form style={{ width: "50%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.maintenanceAmount}
                                onChange={(e) =>
                                  setmaintenanceAmount(e.target.value)
                                }
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.maintenanceAmount
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Avaliable From :{" "}
                          </span>
                          {edit ? (
                            <Form style={{ width: "50%", fontWeight: "600" }}>
                              <Form.Group>
                                <DatePicker
                                  placeholder={selected.avaliableFrom}
                                  format="YYYY/MM/DD"
                                  style={{
                                    width: "250px",
                                    height: "38px",
                                  }}
                                  onChange={(date) =>
                                    setavaliableFrom(date.format("YYYY/MM/DD"))
                                  }
                                />
                              </Form.Group>
                            </Form>
                          ) : (
                            selected?.avaliableFrom
                          )}
                        </div>
                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Lease Duration (Years) :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setleaseDuration(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          ) : (
                            selected?.leaseDuration
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Lockin Period (Years) :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setlockinPeriod(e.target.value)}
                            >
                               <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          ) : (
                            selected?.lockinPeriod
                          )}{" "}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setshow3(false);
                        setedit(false);
                      }}
                    >
                      Close
                    </Button>
                    {edit ? (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => {
                          setshow3(false);
                          setedit(false);
                          updateComrentRental();
                          updateResirental();
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => setedit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="sm"
                  show={show9}
                  onHide={() => setshow9(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Resale
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Expected Amount :{" "}
                      </span>{" "}
                      ₹{" "}
                      {edit ? (
                        <Form style={{ width: "50%" }}>
                          <Form.Control
                            type="text"
                            placeholder={selected.expectedPrice}
                            onChange={(e) => setexpectedRent(e.target.value)}
                          ></Form.Control>
                        </Form>
                      ) : (
                        selected?.expectedPrice
                      )}{" "}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Ownership Type :{" "}
                      </span>
                      {edit ? (
                        <select
                          className="form-control "
                          style={{
                            width: "200px",
                            color: "GrayText",
                            cursor: "pointer",
                          }}
                          onChange={(e) => setownerShipType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="On Lease">On Lease </option>
                          <option value="Self Owned">Self Owned </option>
                        </select>
                      ) : (
                        selected?.ownerShipType
                      )}{" "}
                    </div>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Avaliable From :{" "}
                      </span>
                      {edit ? (
                        <Form style={{ width: "50%", fontWeight: "600" }}>
                          <Form.Group>
                            <DatePicker
                              placeholder={selected.avaliableFrom}
                              format="YYYY/MM/DD"
                              style={{
                                width: "250px",
                                height: "38px",
                                // cursor: "pointer",
                              }}
                              onChange={(date) =>
                                setavaliableFrom(date.format("YYYY/MM/DD"))
                              }
                            />
                          </Form.Group>
                        </Form>
                      ) : (
                        selected?.avaliableFrom
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setshow3(false);
                        setedit(false);
                      }}
                    >
                      Close
                    </Button>
                    {edit ? (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => {
                          setshow3(false);
                          setedit(false);
                          updateComsale();
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => setedit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="sm"
                  show={show4}
                  onHide={() => setshow4(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Amenities
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {(rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "Flatmates") ||
                    (rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "Rent") ||
                    (rowdata?.property === "Residential" &&
                      rowdata?.propertytype === "Resale") ? (
                      <>
                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Balcony :{" "}
                          </span>
                          {edit ? (
                            <Form style={{ width: "23%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.balcony}
                                onChange={(e) => setbalcony(e.target.value)}
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.balcony
                          )}{" "}
                        </div>
                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Bathrooms :{" "}
                          </span>
                          {edit ? (
                            <Form style={{ width: "23%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.bathRooms}
                                onChange={(e) => setbathRooms(e.target.value)}
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.bathRooms
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Gated Security :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setgatedSecurity(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          ) : (
                            selected?.gatedSecurity
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Gym :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setgym(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          ) : (
                            selected?.gym
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            NonVeg Allowed :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setnonVegAllowed(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          ) : (
                            selected?.nonVegAllowed
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Water Supply :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setwaterSupply(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Corporation">Corporation</option>
                              <option value="Borewell">Borewell</option>
                              <option value="Both">Both</option>
                            </select>
                          ) : (
                            selected?.waterSupply
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Showing House :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setshowingHouse(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Need Help">Need Help</option>
                              <option value="I will show">I will show</option>
                              <option value="Neighbours">Neighbours</option>
                              <option value="Friends/Relatives">
                                Friends/Relatives
                              </option>
                              <option value="Security">Security</option>
                              <option value="Tenants">Tenants</option>
                            </select>
                          ) : (
                            selected?.showingHouse
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Secondary Number :{" "}
                          </span>
                          {edit ? (
                            <Form style={{ width: "50%" }}>
                              <Form.Control
                                type="text"
                                placeholder={selected.secondaryNumber}
                                onChange={(e) =>
                                  setsecondaryNumber(e.target.value)
                                }
                              ></Form.Control>
                            </Form>
                          ) : (
                            selected?.secondaryNumber
                          )}{" "}
                        </div>

                        <div
                          className="mt-3"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Available Amenities
                        </div>
                        <Form.Check
                          label="Air Conditioner"
                          checked={
                            selected?.availableAmenities?.airconditioner ===
                            "true"
                              ? true
                              : false
                          }
                          onChange={() => setairconditioner(!airconditioner)}
                        />
                        <Form.Check
                          label="Club House"
                          checked={
                            selected?.availableAmenities?.clubhouse === "true"
                              ? true
                              : false
                          }
                          onChange={() => setclubhouse(!clubhouse)}
                        />
                        <Form.Check
                          label="Firesafety"
                          checked={
                            selected?.availableAmenities?.firesafety === "true"
                              ? true
                              : false
                          }
                          onChange={() => setfiresafety(!firesafety)}
                        />
                        <Form.Check
                          label="Gas Pipeline"
                          checked={
                            selected?.availableAmenities?.gaspipeline === "true"
                              ? true
                              : false
                          }
                          onChange={() => setgaspipeline(!gaspipeline)}
                        />
                        <Form.Check
                          label="House Keeping"
                          checked={
                            selected?.availableAmenities?.housekeeping ===
                            "true"
                              ? true
                              : false
                          }
                          onChange={() => sethousekeeping(!housekeeping)}
                        />
                        <Form.Check
                          label="Intercom"
                          checked={
                            selected?.availableAmenities?.intercom === "true"
                              ? true
                              : false
                          }
                          onChange={() => setintercom(!intercom)}
                        />
                        <Form.Check
                          label="Internet Services"
                          checked={
                            selected?.availableAmenities?.internetservices ===
                            "true"
                              ? true
                              : false
                          }
                          onChange={() =>
                            setinternetservices(!internetservices)
                          }
                        />
                        <Form.Check
                          label="Lift"
                          checked={
                            selected?.availableAmenities?.lift === "true"
                              ? true
                              : false
                          }
                          onChange={() => setlift(!lift)}
                        />
                        <Form.Check
                          label="Park"
                          checked={
                            selected?.availableAmenities?.park === "true"
                              ? true
                              : false
                          }
                          onChange={() => setpark(!park)}
                        />
                        <Form.Check
                          label="Power Backup"
                          checked={
                            selected?.availableAmenities?.powerbackup === "true"
                              ? true
                              : false
                          }
                          onChange={() => setpowerbackup(!powerbackup)}
                        />
                        <Form.Check
                          label="Rainwater Harvesting"
                          checked={
                            selected?.availableAmenities
                              ?.rainwaterharvesting === "true"
                              ? true
                              : false
                          }
                          onChange={() =>
                            setrainwaterharvesting(!rainwaterharvesting)
                          }
                        />
                        <Form.Check
                          label="Servant Room"
                          checked={
                            selected?.availableAmenities?.servantroom === "true"
                              ? true
                              : false
                          }
                          onChange={() => setservantroom(!servantroom)}
                        />
                        <Form.Check
                          label="Sewagetreatment Plant"
                          checked={
                            selected?.availableAmenities
                              ?.sewagetreatmentplant === "true"
                              ? true
                              : false
                          }
                          onChange={() =>
                            setsewagetreatmentplant(!sewagetreatmentplant)
                          }
                        />
                        <Form.Check
                          label="Swimmingpool"
                          checked={
                            selected?.availableAmenities?.swimmingpool ===
                            "true"
                              ? true
                              : false
                          }
                          onChange={() => setswimmingpool(!swimmingpool)}
                        />
                        <Form.Check
                          label="Visitor Parking"
                          checked={
                            selected?.availableAmenities?.visitorparking ===
                            "true"
                              ? true
                              : false
                          }
                          onChange={() => setvisitorparking(!visitorparking)}
                        />

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Parking :{" "}
                          </span>
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setparking(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Bike">Bike</option>
                              <option value="Car">Car</option>
                              <option value="Both">Both</option>
                              <option value="None">None</option>
                            </select>
                          ) : (
                            selected?.parking
                          )}{" "}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {rowdata?.property === "Residential" &&
                    rowdata?.propertytype === "PG/Hostel" ? (
                      <>
                        <div
                          className="mt-3"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontFamily: "system-ui",
                            fontWeight: "600",
                          }}
                        >
                          Available Amenities
                        </div>
                        <Form.Check
                          label="Common Tv"
                          checked={
                            selected?.availableAmenities?.commonTv === "true"
                              ? true
                              : false
                          }
                          onChange={() => setcommonTv(!commonTv)}
                        />
                        <Form.Check
                          label="Cooking Allowed"
                          checked={
                            selected?.availableAmenities?.cookingAllowed ===
                            "true"
                              ? true
                              : false
                          }
                          onChange={() => setcookingAllowed(!cookingAllowed)}
                        />
                        <Form.Check
                          label="Mess"
                          checked={
                            selected?.availableAmenities?.mess === "true"
                              ? true
                              : false
                          }
                          onChange={() => setmess(!mess)}
                        />
                        <Form.Check
                          label="Power Backup"
                          checked={
                            selected?.availableAmenities?.powerbackup === "true"
                              ? true
                              : false
                          }
                          onChange={() => setpowerbackup(!powerbackup)}
                        />
                        <Form.Check
                          label="Refrigerator"
                          checked={
                            selected?.availableAmenities?.refrigerator ===
                            "true"
                              ? true
                              : false
                          }
                          onChange={() => setrefrigerator(!refrigerator)}
                        />
                        <Form.Check
                          label="Internet Services"
                          checked={
                            selected?.availableAmenities?.wifi === "true"
                              ? true
                              : false
                          }
                          onChange={() => setwifi(!wifi)}
                        />
                        <Form.Check
                          label="Lift"
                          checked={
                            selected?.availableAmenities?.lift === "true"
                              ? true
                              : false
                          }
                          onChange={() => setlift(!lift)}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {(rowdata?.property === "Commercial" &&
                      rowdata?.propertytype === "Rent") ||
                    (rowdata?.property === "Commercial" &&
                      rowdata?.propertytype === "Sale") ? (
                      <>
                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Parking :{" "}
                          </span>{" "}
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setparking(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="public And Reserved">
                                public And Reserved
                              </option>
                              <option value="Reserved">Reserved</option>
                              <option value="Public">Public</option>
                            </select>
                          ) : (
                            selected?.parking
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Washroom(s) :{" "}
                          </span>{" "}
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setbathRooms(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Shared">Shared</option>
                              <option value="No washroom">No washroom</option>
                              <option value="Private">Private</option>
                            </select>
                          ) : (
                            selected?.bathRooms
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Water Storage Facility :{" "}
                          </span>{" "}
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setwaterSupply(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          ) : (
                            selected?.waterSupply
                          )}{" "}
                        </div>

                        <div>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontFamily: "system-ui",
                              fontWeight: "600",
                            }}
                          >
                            Wifi :{" "}
                          </span>{" "}
                          {edit ? (
                            <select
                              className="form-control "
                              style={{
                                width: "150px",
                                color: "GrayText",
                              }}
                              onChange={(e) => setwifi(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          ) : (
                            selected?.wifi
                          )}{" "}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setshow4(false);
                        setedit(false);
                      }}
                    >
                      Close
                    </Button>
                    {edit ? (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => {
                          setshow4(false);
                          setedit(false);
                          updateamenities();
                          updateCommAmenities();
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => setedit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="sm"
                  show={show5}
                  onHide={() => setshow5(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                      Schedule
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        Avaliability :{" "}
                      </span>{" "}
                      {edit ? (
                        <select
                          className="form-control ms-3"
                          style={{
                            width: "170px",
                            height: "35px",
                            color: "GrayText",
                            cursor: "pointer",
                          }}
                          onChange={(e) => setavaliability(e.target.value)}
                        >
                          <option value="">Select available days</option>
                          <option value="Everyday">Everyday</option>
                          <option value="Weekday">Weekday</option>
                          <option value="Weekend">Weekend</option>
                        </select>
                      ) : (
                        selected?.avaliability
                      )}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Start Time :{" "}
                      </span>
                      {edit ? (
                        <select
                          className="form-control ms-3"
                          style={{
                            width: "170px",
                            height: "35px",
                            color: "GrayText",
                            cursor: "pointer",
                          }}
                          onChange={(e) => setstartTime(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="09:00 AM">09:00 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="01:00 PM">01:00 PM</option>
                          <option value="02:00 PM">02:00 PM</option>
                          <option value="03:00 PM">03:00 PM</option>
                          <option value="04:00 PM">04:00 PM</option>
                          <option value="05:00 PM">05:00 PM</option>
                          <option value="06:00 PM">06:00 PM</option>
                          <option value="07:00 PM">07:00 PM</option>
                          <option value="08:00 PM">08:00 PM</option>
                          <option value="09:00 PM">09:00 PM</option>
                        </select>
                      ) : (
                        selected?.startTime
                      )}{" "}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        End Time :{" "}
                      </span>
                      {edit ? (
                        <select
                          className="form-control ms-3"
                          style={{
                            width: "170px",
                            height: "35px",
                            color: "GrayText",
                            cursor: "pointer",
                          }}
                          onChange={(e) => setendTime(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="09:00 AM">09:00 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="01:00 PM">01:00 PM</option>
                          <option value="02:00 PM">02:00 PM</option>
                          <option value="03:00 PM">03:00 PM</option>
                          <option value="04:00 PM">04:00 PM</option>
                          <option value="05:00 PM">05:00 PM</option>
                          <option value="06:00 PM">06:00 PM</option>
                          <option value="07:00 PM">07:00 PM</option>
                          <option value="08:00 PM">08:00 PM</option>
                          <option value="09:00 PM">09:00 PM</option>
                        </select>
                      ) : (
                        selected?.endTime
                      )}{" "}
                    </div>

                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        Posted By :{" "}
                      </span>{" "}
                      {edit ? (
                        <select
                          className="form-control ms-3"
                          style={{
                            width: "170px",
                            height: "35px",
                            color: "GrayText",
                            cursor: "pointer",
                          }}
                          onChange={(e) => setpropertyowner(e.target.value)}
                        >
                          <option value="">Select Property Owner</option>
                          <option value="Owner">Owner</option>
                          <option value="Agent">Agent</option>
                          <option value="Developer">Developer</option>
                        </select>
                      ) : (
                        selected?.propertyowner
                      )}
                    </div>
                    <div>
                      <span
                        style={{
                          color: "black",
                          fontSize: "17px",
                          fontFamily: "system-ui",
                          fontWeight: "600",
                        }}
                      >
                        Youtube Link :{" "}
                      </span>
                      {edit ? (
                        <Form
                          className="mt-3"
                          style={{ marginLeft: "10px", width: "70%" }}
                        >
                          <Form.Text className="text-muted">
                            If you have a youtube link about this property paste
                            here
                          </Form.Text>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Control
                              type="text"
                              placeholder="Optional Only!"
                              onChange={(e) => setyouTube(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      ) : (
                        selected?.youtubeLink
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setshow5(false);
                        setedit(false);
                      }}
                    >
                      Close
                    </Button>
                    {edit ? (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => {
                          setshow5(false);
                          setedit(false);
                          updateschedule();
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#008989" }}
                        onClick={() => setedit(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>
                <Modal
              size="md"
              show={show7}
              onHide={() => setshow7(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Image 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {selectedimg.length > 0 ? 
                  selectedimg.map((i)=>(
                    <div className="col-3 mb-2">
                      <img src= { !i ? "/img/noimgae.jpeg" :"https://houseliv.in/property/" + i} width="75px" height="75px" onClick={()=>window.open(!i ? "/img/noimgae.jpeg" :"https://houseliv.in/property/" + i)}/>
                    </div>
                  ))
                   :""}
                </div>
                {/* <div>Description: {selected?.description} </div> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshow7(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Myproperty;
