import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import DatePicker from "react-multi-date-picker";

function Com_plot_details() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [plotPrice, setplotPrice] = React.useState("");
  const [salesType, setsalesType] = React.useState("");
  const [propertyAge, setpropertyAge] = React.useState("");
  const [totalPlotSize, settotalPlotSize] = React.useState("");
  const [facing, setfacing] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [avaliableFrom, setavaliableFrom] = React.useState("");
 
  const [onmainRoad, setonmainRoad] = React.useState(false);
  console.log(onmainRoad);

  const [cornerProperty, setcornerProperty] = React.useState(false);
  console.log(cornerProperty);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const propertyId = sessionStorage.getItem("propertyId");

  const postdata = async () => {
    let config = {
      url: "/propertyForOwner/createCommericalPlotDetails",
      method: "post",
      baseURL: "https://houseliv.in/api",
      data: {
        userId: user.id,
        propertyId: propertyId,
        plotPrice: plotPrice,
        salesType: salesType,
        propertyAge: propertyAge,
        totalPlotSize: totalPlotSize,
        description: description,
        facing: facing,
        avaliableFrom: avaliableFrom,
        onmainRoad:onmainRoad,
        cornerProperty:cornerProperty,
      }, 
    };

    let res = await axios(config);

    if (res.status == 200) {
      window.location.assign("/commercial_plot_locality");
    } else {
      console.log("error");
      // alert("Incorrect mail id/password");
    }
  };

  return (
    <div className="mb-5">
      <Row className="me-0">
        <Col md={3} className="mob-view">
          {" "}
          <Card
            className="mt-5 ms-5"
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#1b2435",
            }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Plot Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Second">Location Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Three">Gallery</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Four">Schedule</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="mt-5 mb-5"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Card
              className="pt-3 ps-3 pb-3"
              style={{
                fontWeight: "600",
                color: "#1b2435",
                fontSize: "20px",
              }}
            >
              Plot Details
            </Card>{" "}
            <Row>
              <Col className="mt-4 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Total Plot Size<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col}>
                    <Form.Control
                      type="text"
                      placeholder="sq.ft"
                      style={{
                        width: "250px",
                        color: "GrayText",
                      }}
                      onChange={(e) => settotalPlotSize(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-4 ms-4">
              <Form validated>
                <Form.Label style={{ fontWeight: "600" }}>
                  Sales Type<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>
                <Form.Group as={Col} controlId="formGridState">
                  <select
                    className="form-control "
                    style={{
                      width: "250px",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                    onChange={(e) => setsalesType(e.target.value)}
                    required
                  >
                    <option value="">Select</option>
                    <option value="New Property">Ready for registration</option>
                    <option value="Resale">Under development</option>
                  </select>
                </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 ms-4">
                {" "}
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Age of Property<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setpropertyAge(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Less than a year">Less than a year</option>
                      <option value="1 to 5 years">1 to 5 years</option>
                      <option value="5 to 10 years">5 to 10 years</option>
                      <option value="More than 10 years">
                        More than 10 years
                      </option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-4 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Plot Price<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col}>
                    <Form.Control
                      type="text"
                      placeholder="Total price"
                      style={{
                        width: "250px",
                        color: "GrayText",
                      }}
                      onChange={(e) => setplotPrice(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form>
              </Col>
          </Row>
          <Row>
          <Col className="mt-4 ms-4">
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Facing<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col} controlId="formGridState">
                    <select
                      className="form-control "
                      style={{
                        width: "250px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onChange={(e) => setfacing(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="North">North</option>
                      <option value="South">South </option>
                      <option value="East">East</option>
                      <option value="West">West</option>
                      <option value="North-East">North-East</option>
                      <option value="South-East">South-East</option>
                      <option value="North-West">North-West</option>
                      <option value="South-West">South-West</option>
                      <option value="Don't Know">Don't Know</option>
                    </select>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="mt-4 ms-4">
                {" "}
                <Form validated>
                  <Form.Label style={{ fontWeight: "600" }}>
                    Available From<span style={{ color: "#dc3545" }}>*</span>
                  </Form.Label>
                  <Form.Group as={Col}>
                    <DatePicker
                      placeholder="YYYY/MM/DD"
                      format="YYYY/MM/DD"
                      style={{
                        width: "250px",
                        height: "38px",
                        // cursor: "pointer",
                      }}
                      onChange={(date) =>
                        setavaliableFrom(date.format("YYYY/MM/DD"))
                      }
                    />
                  </Form.Group>
                </Form>
              </Col>
          <Row> 
          <Col className="ms-4 mt-3">
          <Form.Label style={{ fontWeight: "600" }}>
          Other Features
                </Form.Label>
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="On Main Road"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={onmainRoad}
                      onChange={() => setonmainRoad(!onmainRoad)}
                    />
                  </div>
                ))}{" "}
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Corner Property"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                      style={{ backgroundcolor: "#009587" }}
                      value={cornerProperty}
                      onChange={() => setcornerProperty(!cornerProperty)}
                    />
                  </div>
                ))}{" "}
              </Col>
            </Row>
          </Row>
          <Row className="mt-3 ms-4">
                <Form.Label style={{ fontWeight: "600" }}>
                  Description<span style={{ color: "#dc3545" }}>*</span>
                </Form.Label>

                <textarea
                  style={{ width: "90%" }}
                  onChange={(e) => setdescription(e.target.value)}
                  placeholder="Provide any specific description you want to add about your property like furnishing and other amenities....."
                ></textarea>
              </Row>
          </Card>{" "}
        </Col>
      </Row>
     
    
             
        <Navbar
          className="mt-3"
          fixed="bottom"
          style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}
        >
          <Col md={4}></Col>
          <Col md={4}>
            <Navbar.Brand>
              <Button variant="danger" onClick={postdata}>
                Save & Continue
              </Button>
            </Navbar.Brand>{" "}
          </Col>
          <Col md={4}></Col>{" "}
        </Navbar>
      
    </div>
  );
}

export default Com_plot_details;
